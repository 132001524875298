import { axios } from 'shared/services/axios'

export function createSession() {
  return axios.post("/live_board/v1/sessions")
  .then(response => response.data)
}

export function validateSession() {
  return axios.post("/live_board/v1/session_validations")
  .then(response => response.data)
}