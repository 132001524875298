import { axios } from 'shared/services/axios'

export function fetchInvitationWrapper(token) {
  return axios.get(`/live_board/v2/invitation_wrappers/${token}`)
    .then(response => response.data)
}

export function updateInvitationUsed(token) {
  return axios.put(`/live_board/v2/invitation_wrappers/${token}`)
}
