import React from 'react'
import propTypes from 'prop-types'

import './style.css.scss'

class PrivacyWarningInjector extends React.Component {
  componentDidMount() {
    this.props.scriptFiles.map(file => {
      const script = document.createElement("script");

      script.src = file
      document.body.appendChild(script)
    })
  }

  render() {
    return (this.props.markup != '' &&
      <div className="privacy-message-injector" style={this.props.componentStyle}>
        {
          this.props.cssFiles.map((file, idx) => {
            return  <link key={idx} href={file} rel="stylesheet"></link>
          })
        }
        <div dangerouslySetInnerHTML={{ __html: this.props.markup }} />
      </div>
    )
  }
}

export default PrivacyWarningInjector