import { I18n, TRANSLATION_VALUE_KEYS, TRANSLATION_KEYS } from 'shared/services/I18n'
import { ITEM_TYPE_CONFIG_BASE, getItemType } from 'shared/Components/ItemContent/config'

const TRANSLATION_KEY = TRANSLATION_KEYS.itemTypeConfig
const i18n = I18n(FollozeState.initialState.locale)

const fileConfig = (item, blockDownload) => {
  let icon; let isVideo = false
  let label = i18n.translate(TRANSLATION_KEY, TRANSLATION_VALUE_KEYS.file)
  switch (item.item_type) {
    case 'doc':
    case 'docx':
      icon = 'icon-docx-file'
      break
    case 'xlsx':
    case 'xls':
      icon = 'icon-xlsx-file'
      break
    case 'pptx':
    case 'ppt':
    case 'odp':
      icon = 'icon-ppt-file'
      break
    case '3gp':
    case 'avi':
    case 'm2v':
    case 'm2ts':
    case 'mov':
    case 'mp4':
    case 'mpeg':
    case 'mpg':
    case 'wmv':
      icon = 'icon-play-button'
      isVideo = true
      break
    case 'bmp':
    case 'gif':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'ps':
    case 'psd':
    case 'svg':
    case 'tif':
    case 'tiff':
    case 'dcm':
    case 'dicm':
    case 'dicom':
    case 'svs':
      icon = 'icon-image'
      break
    case 'pdf':
      icon = 'icon-pdf-file'
      label = 'pdf'
      break
    default:
      icon = 'icon-document'
      break
  }

  return {
    icon,
    label,
    isVideo,
    canDownload: !blockDownload
  }
}

const ITEM_TYPE_CONFIG = {
  linkedin_post: () => {
    return {
      icon: 'icon-linkedin',
      label: 'Linkedin',
      canDownload: false
    }
  },
  facebook_video: () => {
    return {
      icon: 'icon-facebook',
      label: 'Facebook',
      isVideo: true,
      canDownload: false
    }
  },
  prezi: () => {
    return {
      icon: 'icon-prezi',
      label: 'Prezi',
      canDownload: false
    }
  },
  youtube: () => {
    return {
      icon: 'icon-youtube',
      label: 'Youtube',
      isVideo: true,
      canDownload: false
    }
  },
  oembed: (item) => {
    switch (true) {
      case /slideshare\.net/.test(item.link_url):
        return {
          icon: 'icon-slideshare',
          label: 'Slideshare',
          canDownload: false
        }
      case /vimeo\.com/.test(item.link_url):
        return {
          icon: 'icon-vimeo',
          label: 'Vimeo',
          canDownload: false,
          isLinkAsVideo: true
        }
      default:
        return {}
    }
  },
  wistia: () => {
    return {
      icon: 'icon-wistia',
      label: 'Wistia',
      isVideo: true,
      canDownload: false
    }
  },
  google: () => {
    return {
      icon: 'icon-google-plus',
      label: 'Google',
      canDownload: false
    }
  },
  link: (item) => {
    if (/vimeopro\.com/.test(item.link_url)) {
      return {
        icon: 'icon-vimeo',
        label: 'Vimeo Pro',
        canDownload: false,
        isLinkAsVideo: true
      }
    }

    return {
      icon: 'icon-link-web',
      label: i18n.translate(TRANSLATION_KEY, TRANSLATION_VALUE_KEYS.link),
      canDownload: false
    }
  },
  pdf: () => {
    return {
      icon: 'icon-pdf-file',
      label: 'pdf',
      canDownload: false
    }
  },
  snapshot: () => {
    return {
      icon: 'icon-link-web',
      label: i18n.translate(TRANSLATION_KEY, TRANSLATION_VALUE_KEYS.link),
      canDownload: false
    }
  },
  video: () => {
    return {
      icon: 'icon-play-button',
      label: i18n.translate(TRANSLATION_KEY, TRANSLATION_VALUE_KEYS.video),
      isVideo: true,
      canDownload: false
    }
  },
  vidyard: () => {
    return {
      icon: 'icon-vidyard',
      label: 'Vidyard',
      isVideo: true,
      canDownload: false
    }
  },
  image: () => {
    return {
      icon: 'icon-image',
      label: i18n.translate(TRANSLATION_KEY, TRANSLATION_VALUE_KEYS.image),
      canDownload: false
    }
  },
  brighttalk: () => {
    return {
      icon: 'icon-brighttalk',
      label: 'BrightTalk',
      isVideo: true,
      canDownload: false
    }
  },
  brightcove: () => {
    return {
      icon: 'icon-play-button',
      label: 'Video',
      isVideo: true,
      canDownload: false
    }
  },
  file: fileConfig,
  box: fileConfig,
  article: () => {
    return {
      icon: 'icon-article',
      label: i18n.translate(TRANSLATION_KEY, TRANSLATION_VALUE_KEYS.article),
      canDownload: false
    }
  }
}

export const getItemTypeConfig = (item, blockDownload = false) => {
  const configEntry = getItemType(item)
  return Object.assign(ITEM_TYPE_CONFIG_BASE[configEntry](item), ITEM_TYPE_CONFIG[configEntry](item, blockDownload))
}
