import React from 'react'
import PropTypes from 'prop-types'

import './style.css.scss'

const TileTransition = (props) => {
  return (
    <div className="tile-loader-transition">
      <div
        className='loader-tile'
        style={{
          backgroundColor: props.themeColor,
          borderBottom: `solid 1px ${props.themeColor}`
        }}/>
      { props.showLoaderText &&
        <div className="loader-text">
          {props.children}
        </div>
      }
    </div>
  )
}

TileTransition.propTypes = {
  themeColor: PropTypes.string,
  successMessage: PropTypes.string,
  showLoaderText: PropTypes.bool
}

TileTransition.defaultProps = {
  showLoaderText: false
}

export default TileTransition