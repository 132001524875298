import * as leadsApi from 'LiveBoard/api/leads'
import { SUBMIT_CTA_SUCCESS } from './cta'

import isEmpty from 'lodash/isEmpty'

const FETCH_LEAD_SUCCESS = 'lead/FETCH_LEAD_SUCCESS'
const UPDATE_LEAD = 'lead/UPDATE_LEAD'
const STOP_TRACKING_CURRENT_LEAD = 'leads/STOP_TRACKING_CURRENT_LEAD'

export function updateLead(lead) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LEAD,
      lead
    })
  }
}

export function fetchLead() {
  return function(dispatch, getState) {
    const currentLead = getState().lead

    if (isEmpty(currentLead)) {
      return leadsApi.fetchCurrentLead()
        .then((lead) => {
          return dispatch({
            type: FETCH_LEAD_SUCCESS,
            lead
          })
        })
    }
    else {
      return Promise.resolve(currentLead)
    }
  }
}

export function stopTrackingForSession(values) {
  return dispatch => {
    return leadsApi.stopTrackingForSession().then((lead) => {
      updateLead(lead)(dispatch)
      dispatch({type: STOP_TRACKING_CURRENT_LEAD})
    })
  }
}


const initialState = {
  trackCurrentLead: true
}

export function prepareInitialState({ lead }) {
  if (isEmpty(lead)) {
    return initialState
  }

  return {
    ...initialState,
    ...lead
  }
}

export default function lead(state = initialState, action) {
  switch(action.type) {
    case UPDATE_LEAD:
    case FETCH_LEAD_SUCCESS:
    case SUBMIT_CTA_SUCCESS:
      return {
        ...action.lead,
        trackCurrentLead: state.trackCurrentLead
      }
    case STOP_TRACKING_CURRENT_LEAD:
      return {
        ...state,
        trackCurrentLead: false
      }
    default:
      return state
  }
}
