import { axios } from 'shared/services/axios'

export function submitMessage(boardId, values) {
  return (
    notifyIdentity(boardId, values)
      .then(() => {
        return axios.post(
        `${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v1/boards/${boardId}/campaign/message`, { ...values }
        )
      })
      .then(response => response.data)
  )
}

export function submitVisitorEmail(boardId, values) {
  return (
    notifyIdentity(boardId, values)
      .then(() => {
        return axios.post(
        `${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v1/boards/${boardId}/campaign/contact`, { ...values }
        )
      })
      .then(response => response.data)
  )
}

export function submitForm(boardId, values) {
  return (
    notifyIdentity(boardId, values)
      .then(() => {
        return axios.post(
        `${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v1/boards/${boardId}/campaign/form`, { ...values }
        )
      })
      .then(response => response.data)
  )
}

export function submitLinkCta(boardId, values) {
  return (
    notifyIdentity(boardId, values)
      .then(() => {
        return axios.post(
        `${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v1/boards/${boardId}/campaign/link`, { ...values }
        )
      })
      .then(response => response.data)
  )
}

export function submitLinkClick(boardId, values) {
  return axios.post(
    `${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v1/boards/${boardId}/campaign/link_click`, { ...values }
  ).then(response => response.data)
}

export function submitShareCta(boardId, values) {
  return (
    notifyIdentity(boardId, values)
      .then(() => {
        return axios.post(
        `${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v1/boards/${boardId}/campaign/share`, { ...values }
        )
      })
      .then(response => response.data)
  )
}

export function shareByEmail(boardId, email, options) {
  return axios.post(`${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v1/boards/${boardId}/shares`, {
    email, invitation_id: options.invitationId
  }).then(response => response.data)
}

function notifyIdentity(boardId, values) {
  return axios.put(
    '/live_board/v2/leads/identity', { ...values, board_id: boardId }
  )
}
