import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedZhTW = shared['zh-TW']

const zhTW = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: '發送',
    [TRANSLATION_VALUE_KEYS.createdBy]: '創作者:'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: '您好 %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: ' 請按 "發送"，我們將很快回復您。',
    [TRANSLATION_VALUE_KEYS.notLeadName]: '不是 %{leadName}嗎？'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: '發送',
    [TRANSLATION_VALUE_KEYS.done]: '完成',
    [TRANSLATION_VALUE_KEYS.sending]: '發送中'
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: '您的電子郵件',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedZhTW.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: '*請填寫必填的內容',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedZhTW.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: '*請填寫必填的內容',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedZhTW.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedZhTW.email
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedZhTW.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: '在此輸入您的訊息',
    [TRANSLATION_VALUE_KEYS.yourMessage]: '您的訊息',
    [TRANSLATION_VALUE_KEYS.yourEmail]: '您的電子郵件'
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: '使用以下方法分享此頁面：'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: '您想和誰分享呢？'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedZhTW.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedZhTW.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: '給',
    [TRANSLATION_VALUE_KEYS.email]: sharedZhTW.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedZhTW.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedZhTW.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedZhTW.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: '*分享@email.here',
    [TRANSLATION_VALUE_KEYS.senderDetails]: '寄件人資料'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedZhTW.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: '瀏覽次數最多的'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: '檔案',
    [TRANSLATION_VALUE_KEYS.image]: '圖案',
    [TRANSLATION_VALUE_KEYS.link]: '連結',
    [TRANSLATION_VALUE_KEYS.video]: '影片',
    [TRANSLATION_VALUE_KEYS.article]: '文章'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: '搜尋…'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: '首頁'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: '請輸入密碼',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: '*密碼輸入錯誤。請再試一次',
    [TRANSLATION_VALUE_KEYS.go]: '開始',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: '在此輸入密碼…',
    [TRANSLATION_VALUE_KEYS.viewContent]: '查看內容'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedZhTW.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedZhTW.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: '在新視窗中打開',
    [TRANSLATION_VALUE_KEYS.like]: '按讚',
    [TRANSLATION_VALUE_KEYS.download]: '下載'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: '內容'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedZhTW.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedZhTW.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: sharedZhTW.email,
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedZhTW.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedZhTW.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedZhTW.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: '您的訊息',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedZhTW.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedZhTW.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedZhTW.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedZhTW.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedZhTW.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedZhTW.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: '在此輸入您的訊息'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: '更多',
    [TRANSLATION_VALUE_KEYS.less]: '更少'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: '謝謝您'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: '此頁面需要驗證您的電子郵件地址',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: '輸入您的電子郵件以請求確認碼',
    [TRANSLATION_VALUE_KEYS.enterEmail]: '輸入您的電子郵件地址',
    [TRANSLATION_VALUE_KEYS.continueToPage]: '繼續前往頁面',
    [TRANSLATION_VALUE_KEYS.sendCode]: '發送新的代碼',
    [TRANSLATION_VALUE_KEYS.codeSent]: '已發送新的代碼',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: '已向您發送新的確認碼。請由',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: '檢查您的電子郵件，該郵件可能會在您的促銷、更新或垃圾郵件檔案夾內',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: '此電子郵件地址未被授權查看此頁面',
    [TRANSLATION_VALUE_KEYS.accessPageError]: '很抱歉您無法存取此頁面',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: '請與您的公司代表聯繫以獲得存取權限',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: '輸入您的確認代碼',
    [TRANSLATION_VALUE_KEYS.typeCode]: '在這裡鍵入代碼',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: '代碼不正確',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: '發生錯誤',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedZhTW.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedZhTW.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedZhTW.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedZhTW.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: '此頁面受到密碼保護',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: '請在下方輸入密碼',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: '在此輸入密碼',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: '驗證密碼',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: '密碼錯誤'
  }
}

export default zhTW
