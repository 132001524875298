import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { withRouter } from 'shared/hocs'

const withCustomPreviewNavigation = (WrappedComponent) => {
  const Wrapper = (props) => {

    // if path is from folloze app prevent navigation
    if (window.location.origin.includes(FollozeState.envConfig.ROOT_URL)) {
      const navigate = () => { return }
      const location = useLocation()
      const params = useParams()

      return (
        <WrappedComponent
          navigate={navigate}
          location={location}
          params={params}
          {...props} />
      )
    }
    else {
      return withRouter(WrappedComponent)(props)
    }
  }

  return Wrapper

}

export default withCustomPreviewNavigation
