import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedFrFR = shared['fr-FR']

const frFR = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedFrFR.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Créé par'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Bonjour %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: 'C Cliquez sur « ENVOYER » puis nous vous recontacterons sous peu.',
    [TRANSLATION_VALUE_KEYS.notLeadName]: "Vous n'êtes pas %{leadName}?"
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedFrFR.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedFrFR.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedFrFR.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedFrFR.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedFrFR.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Veuillez remplir les champs obligatoires',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedFrFR.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Veuillez remplir les champs obligatoires',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedFrFR.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedFrFR.email
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedFrFR.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedFrFR.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Votre message',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedFrFR.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Partager cette page via'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Avec qui voulez-vous la partager?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedFrFR.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedFrFR.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedFrFR.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedFrFR.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedFrFR.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedFrFR.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedFrFR.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'adresseemail@dudestinataire.ici',
    [TRANSLATION_VALUE_KEYS.senderDetails]: "Détails de l'expéditeur"
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedFrFR.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Les plus vus'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Dossier',
    [TRANSLATION_VALUE_KEYS.image]: 'Image',
    [TRANSLATION_VALUE_KEYS.link]: 'Lien',
    [TRANSLATION_VALUE_KEYS.video]: 'Vidéo',
    [TRANSLATION_VALUE_KEYS.article]: 'Article'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Recherche'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Accueil'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Veuillez entrer votre mot de passe',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'Mot de passe incorrect. Veuillez réessayer.',
    [TRANSLATION_VALUE_KEYS.go]: "C'est parti",
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Entrez votre mot de passe ici',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Afficher le contenu'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedFrFR.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedFrFR.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Ouvrir dans un nouvel onglet',
    [TRANSLATION_VALUE_KEYS.like]: 'Aimer',
    [TRANSLATION_VALUE_KEYS.download]: 'Télécharger'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Contenu'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedFrFR.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedFrFR.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Adresse email',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedFrFR.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedFrFR.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedFrFR.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Message',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedFrFR.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedFrFR.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedFrFR.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedFrFR.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedFrFR.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedFrFR.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Tapez votre message ici...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Plus',
    [TRANSLATION_VALUE_KEYS.less]: 'Moins'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Merci'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Cette page requiert la vérification de votre adresse e-mail',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Saisissez votre adresse e-mail pour demander un code de confirmation',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Saisissez votre adresse e-mail',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Continuer vers la page',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Envoyer un nouveau code',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Nouveau code envoyé',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Le code de confirmation vous a été envoyé. Veuillez vérifier si vous avez reçu un e-mail de la part de',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Remarque : l’e-mail peut se trouver dans votre dossier de promotions, de mises à jour ou de spams',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: "Cette adresse e-mail n'est pas autorisée et ne donne pas accès à cette page",
    [TRANSLATION_VALUE_KEYS.accessPageError]: "Désolés de constater que vous n'arrivez pas à accéder à cette page",
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Veuillez contacter le représentant de votre entreprise pour obtenir l’accès',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Saisissez votre code de confirmation',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Saisissez le code ici',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Code incorrect',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: "Une erreur s'est produite",
    [TRANSLATION_VALUE_KEYS.emailField]: sharedFrFR.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedFrFR.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedFrFR.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedFrFR.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Cette page est protégée par un mot de passe',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Veuillez saisir le mot de passe ci-dessous',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Saisir le mot de passe ici',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Vérifier le mot de passe',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Mot de passe incorrect'
  }
}

export default frFR
