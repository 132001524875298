import { default as leadReducer, prepareInitialState as leadPrepareInitialState } from './lead'
import { default as boardReducer, prepareInitialState as boardPrepareInitialState } from './board'
import { default as campaignReducer, prepareInitialState as campaignPrepareInitialState } from './campaign'
import { default as presenterReducer, prepareInitialState as presenterPrepareInitialState } from './presenter'
import { default as itemsViewReducer, prepareInitialState as itemsViewPrepareInitialState } from './itemsView'
import { default as itemViewerReducer, prepareInitialState as itemViewerPrepareInitialState } from './itemViewer'
import { default as categoriesReducer, prepareInitialState as categoriesPrepareInitialState } from './categories'
import { default as itemsReducer, prepareInitialState as itemsPrepareInitialState } from './items'
import { default as ctaReducer, prepareInitialState as ctaPrepareInitialState } from './cta'
import { default as greetingReducer, prepareInitialState as greetingPrepareInitialState } from './greeting'
import { default as cookiesConsentReducer, prepareInitialState as cookiesConsentPrepareInitialState } from './cookiesConsent'
import { default as sessionReducer, prepareInitialState as sessionPrepareInitialState } from './session'
import { default as organizationSettingsReducer, prepareInitialState as organizationSettingsPrepareInitialState } from './organizationSettings'
import { default as liveEventReducer, prepareInitialState as liveEventPrepareInitialState } from './liveEvent'
import { default as gatingFormReducer, prepareInitialState as gatingFormPrepareInitialState } from './gatingForm'
import { default as campaignElementsReducer, prepareInitialState as campaignElementsPrepareInitialState } from './campaignElements'

const reducers = {
  lead: leadReducer,
  board: boardReducer,
  campaign: campaignReducer,
  presenter: presenterReducer,
  itemsView: itemsViewReducer,
  itemViewer: itemViewerReducer,
  categories: categoriesReducer,
  items: itemsReducer,
  cta: ctaReducer,
  greeting: greetingReducer,
  cookiesConsent: cookiesConsentReducer,
  organizationSettings: organizationSettingsReducer,
  liveEvent: liveEventReducer,
  session: sessionReducer,
  gatingForm: gatingFormReducer,
  campaignElements: campaignElementsReducer
}

const preloaders = {
  lead: leadPrepareInitialState,
  board: boardPrepareInitialState,
  campaign: campaignPrepareInitialState,
  presenter: presenterPrepareInitialState,
  itemsView: itemsViewPrepareInitialState,
  itemViewer: itemViewerPrepareInitialState,
  categories: categoriesPrepareInitialState,
  items: itemsPrepareInitialState,
  cta: ctaPrepareInitialState,
  greeting: greetingPrepareInitialState,
  cookiesConsent: cookiesConsentPrepareInitialState,
  organizationSettings: organizationSettingsPrepareInitialState,
  liveEvent: liveEventPrepareInitialState,
  session: sessionPrepareInitialState,
  gatingForm: gatingFormPrepareInitialState,
  campaignElements: campaignElementsPrepareInitialState
}

export { reducers as default, preloaders }
