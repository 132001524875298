import RoutesService from 'LiveBoard/services/routes'
import { HOME_ROUTE_CATEGORY_ID } from 'LiveBoard/config/constants'

export const getItemUrl = ({item, category, queryString, board}) => {
  category = category || { slug: HOME_ROUTE_CATEGORY_ID }
  const itemRoute = getItemRoute(item, category, board)
  return `${itemRoute}${queryString}`
}

export const getItemRoute = (item, category, board) => {
  return RoutesService.getItemRoute({item, category, board})
}

