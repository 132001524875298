import enUS from './translations/enUS'
import deDE from './translations/deDE'
import enAU from './translations/enAU'
import enCA from './translations/enCA'
import enGB from './translations/enGB'
import frCA from './translations/frCA'
import nlNL from './translations/nlNL'
import ptBR from './translations/ptBR'
import frFR from './translations/frFR'
import itIT from './translations/itIT'
import trTR from './translations/trTR'
import nbNO from './translations/nbNO'
import fiFI from './translations/fiFI'
import svSE from './translations/svSE'
import daDK from './translations/daDK'
import jaJA from './translations/jaJA'
import esES from './translations/esES'
import koKR from './translations/koKR'
import zhCN from './translations/zhCN'
import zhTW from './translations/zhTW'

export const LOCALE_MAPPING = {
  'en-US': enUS,
  'de-DE': deDE,
  'fr-CA': frCA,
  'nl-NL': nlNL,
  'pt-BR': ptBR,
  'fr-FR': frFR,
  'fr': frFR,
  'en-AU': enAU,
  'en-CA': enCA,
  'en-GB': enGB,
  'it-IT': itIT,
  'tr': trTR,
  'no': nbNO,
  'fi': fiFI,
  'sv': svSE,
  'da': daDK,
  'ja': jaJA,
  'es-ES': esES,
  'ko-KR': koKR,
  'zh-CN': zhCN,
  'zh-TW': zhTW
}

export const DEFAULT_TRANSLATION = LOCALE_MAPPING['en-US']
