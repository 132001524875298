import { matchPath } from 'react-router-dom'
import Base from './Base'
import { HOME_ROUTE_CATEGORY_ID } from 'LiveBoard/config/constants'

const BOARD_PATH = ":boardSlug"
const CATEGORY_PATH = ":categorySlug"
const ITEM_PATH = ":itemSlug"
const HOME_PATH = "home"

class VanityUrls extends Base {
  constructor() {
    super()

    this.boardPath = BOARD_PATH
    this.categoryPath = CATEGORY_PATH
    this.itemPath = ITEM_PATH
    this.homePath = HOME_PATH
    this.oldItemPath = null
  }

  getBoardRoute(board, options={addHomeSuffix: true}) {
    const suffix = options.addHomeSuffix ? HOME_ROUTE_CATEGORY_ID : ""
    return `/${board.slug}/${suffix}`
  }

  getCategoryRoute(category, board) {
    return `/${board.slug}/${category.slug}`
  }

  getItemRoute({ item, category, board }) {
    const categoryRoute = `${!!category ? category.slug : HOME_ROUTE_CATEGORY_ID}`
    return `/${board.slug}/${categoryRoute}/${item.slug}`
  }

  matchItemPath(path) {
    return matchPath(`${this.boardPath}/${this.categoryPath}/${this.itemPath}`, path)
  }

  matchCategoryPath(path) {
    return matchPath(`${this.boardPath}/${this.categoryPath}`, path)
  }

  matchBoardPath(path) {
    return matchPath(`${this.boardPath}`, path)
  }
}

export default VanityUrls