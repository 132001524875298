import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedZhCn = shared['zh-CN']

const zhCN = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: '发送',
    [TRANSLATION_VALUE_KEYS.createdBy]: '创建者:'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: '%{leadName} 您好',
    [TRANSLATION_VALUE_KEYS.clickSend]: ' 只需点击“发送”，我们将会很快回复您。',
    [TRANSLATION_VALUE_KEYS.notLeadName]: '不是%{leadName}吗？'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: '发送',
    [TRANSLATION_VALUE_KEYS.done]: '已完成',
    [TRANSLATION_VALUE_KEYS.sending]: '正在发送'
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: '您的电子邮箱',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedZhCn.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: '*请填写必填项',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedZhCn.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: '*请填写必填项'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedZhCn.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: '在这里键入您的消息',
    [TRANSLATION_VALUE_KEYS.yourMessage]: '您的消息',
    [TRANSLATION_VALUE_KEYS.yourEmail]: '您的电子邮箱'
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: '使用以下方式分享此页：'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: '想和谁分享？'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedZhCn.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedZhCn.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: '致',
    [TRANSLATION_VALUE_KEYS.email]: sharedZhCn.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedZhCn.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedZhCn.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedZhCn.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: '*share@email.here',
    [TRANSLATION_VALUE_KEYS.senderDetails]: '发件人详细信息'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedZhCn.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: '浏览次数最多'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: '文件',
    [TRANSLATION_VALUE_KEYS.image]: '图像',
    [TRANSLATION_VALUE_KEYS.link]: '链接',
    [TRANSLATION_VALUE_KEYS.video]: '视频',
    [TRANSLATION_VALUE_KEYS.article]: '文章'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: '搜索...'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: '主页'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: '请输入密码',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: '*密码不正确。请再试一次',
    [TRANSLATION_VALUE_KEYS.go]: '开始',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: '在这里输入密码…',
    [TRANSLATION_VALUE_KEYS.viewContent]: '查看内容'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedZhCn.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedZhCn.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: '在新标签中打开',
    [TRANSLATION_VALUE_KEYS.like]: '点赞',
    [TRANSLATION_VALUE_KEYS.download]: '下载'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: '目录'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedZhCn.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedZhCn.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: sharedZhCn.email,
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedZhCn.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedZhCn.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedZhCn.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: '您的消息',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedZhCn.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedZhCn.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedZhCn.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedZhCn.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedZhCn.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedZhCn.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: '在这里键入您的消息'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: '更多',
    [TRANSLATION_VALUE_KEYS.less]: '较少'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: '谢谢'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: '此页面要求验证您的电子邮箱地址',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: '输入电子邮箱地址以获取确认码',
    [TRANSLATION_VALUE_KEYS.enterEmail]: '输入电子邮箱地址',
    [TRANSLATION_VALUE_KEYS.continueToPage]: '继续浏览页面',
    [TRANSLATION_VALUE_KEYS.sendCode]: '发送新代码',
    [TRANSLATION_VALUE_KEYS.codeSent]: '新代码已发送',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: '确认码已发送给您。请查看来自',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: '的电子邮件，此邮件可能位于推广邮件、最新邮件或垃圾邮件文件夹中。',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: '此电子邮箱地址无权查看此页面',
    [TRANSLATION_VALUE_KEYS.accessPageError]: '抱歉，您无法访问此页面',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: '请联系您的公司代表以获取访问权限',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: '输入您的确认码',
    [TRANSLATION_VALUE_KEYS.typeCode]: '在此输入代码',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: '代码错误',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: '出错了',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedZhCn.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedZhCn.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedZhCn.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedZhCn.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: '此页面受密码保护',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: '请在下方输入密码',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: '在此输入密码',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: '验证密码',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: '密码错误'
  }
}

export default zhCN
