import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedEnUS = shared['en-US']

const enUS = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedEnUS.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Created by'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Hi %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: "Just click 'SEND' and we will get back to you shortly.",
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Not %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedEnUS.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedEnUS.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedEnUS.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedEnUS.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedEnUS.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Please fill mandatory fields',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedEnUS.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Please fill mandatory fields',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedEnUS.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedEnUS.email
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedEnUS.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedEnUS.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Your message',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedEnUS.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Share this page using'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Who would you like to share this with?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedEnUS.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedEnUS.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedEnUS.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedEnUS.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedEnUS.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedEnUS.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedEnUS.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'share@email.here',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Sender details'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedEnUS.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Most viewed'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'File',
    [TRANSLATION_VALUE_KEYS.image]: 'Image',
    [TRANSLATION_VALUE_KEYS.link]: 'Link',
    [TRANSLATION_VALUE_KEYS.video]: 'Video',
    [TRANSLATION_VALUE_KEYS.article]: 'Article'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Search'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Home'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Please enter password',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'The password is incorrect. Please try again',
    [TRANSLATION_VALUE_KEYS.go]: 'Go',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Enter password here',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'View content'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedEnUS.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedEnUS.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Open in new tab',
    [TRANSLATION_VALUE_KEYS.like]: 'Like',
    [TRANSLATION_VALUE_KEYS.download]: 'Download'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Contents'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedEnUS.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedEnUS.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'E-mail',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedEnUS.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedEnUS.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedEnUS.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Message',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedEnUS.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedEnUS.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedEnUS.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedEnUS.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedEnUS.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedEnUS.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: `${sharedEnUS.yourMessageHere}...`
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'More',
    [TRANSLATION_VALUE_KEYS.less]: 'Less'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: sharedEnUS.thankYou
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: sharedEnUS.twoFAPageTitle,
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: sharedEnUS.twoFAPageDescription,
    [TRANSLATION_VALUE_KEYS.enterEmail]: sharedEnUS.enterEmail,
    [TRANSLATION_VALUE_KEYS.continueToPage]: sharedEnUS.continueToPage,
    [TRANSLATION_VALUE_KEYS.sendCode]: sharedEnUS.sendCode,
    [TRANSLATION_VALUE_KEYS.codeSent]: sharedEnUS.codeSent,
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: sharedEnUS.codeSentConfirm,
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: sharedEnUS.codeSentConfirmNote,
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: sharedEnUS.emailNotAuthorized,
    [TRANSLATION_VALUE_KEYS.accessPageError]: sharedEnUS.accessPageError,
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: sharedEnUS.checkAccessNote,
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: sharedEnUS.enterConfirmationCode,
    [TRANSLATION_VALUE_KEYS.typeCode]: sharedEnUS.typeCode,
    [TRANSLATION_VALUE_KEYS.incorrectCode]: sharedEnUS.incorrectCode,
    [TRANSLATION_VALUE_KEYS.errorOccurred]: sharedEnUS.errorOccurred,
    [TRANSLATION_VALUE_KEYS.emailField]: sharedEnUS.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedEnUS.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedEnUS.email
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: sharedEnUS.passwordPageTitle,
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: sharedEnUS.passwordPageDescription,
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: sharedEnUS.enterPasswordPlaceholder,
    [TRANSLATION_VALUE_KEYS.verifyPassword]: sharedEnUS.verifyPassword,
    [TRANSLATION_VALUE_KEYS.wrongPassword]: sharedEnUS.wrongPassword
  }
}

export default enUS
