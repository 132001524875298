import { COOKIE_CONSENT_TYPES } from '../services/cookieConsentService/constants'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'

export const CLOSE_PRIVACY_WARNING = 'privacyWarning/CLOSE_PRIVACY_WARNING'
export const SET_HAS_CONSENT = 'privacyWarning/SET_HAS_CONSENT'
export const FETCH_HAS_CONSENT = 'privacyWarning/FETCH_HAS_CONSENT'
export const SET_COOKIES_CONSENT = 'privacyWarning/SET_COOKIES_CONSENT'

const publicEventsDispatcher = PublicEventsDispatcher()
let cookieConsentProvider

function api(options = {}) {
  return !!options.getApi ? options.getApi() : cookieConsentProvider
}

export function closePrivacyWarning() {
  return (dispatch, getState, options) => {
    const { board } = getState()

    api(options).setConsentCookie()

    dispatch({
      type: CLOSE_PRIVACY_WARNING,
      trackingParams: {
        board_id: board.id,
        organization_id: board.organization_id
      }
    })
  }
}

export function fetchCookiesConsent() {
  return (dispatch) => {
    dispatch({
      type: FETCH_HAS_CONSENT
    })
  }
}

export function setCookiesConsent(consentOrigin) {
  return (dispatch, getState, options) => {

    api(options).setConsentCookie()
    setLeadCookie(consentOrigin)(dispatch, getState, options)

    dispatch({
      type: SET_HAS_CONSENT,
      hasConsent: true
    })

    // dispatch to any marketing script that might be listening
    publicEventsDispatcher.dispatch(publicEventsDispatcher.EventTypes.onConsent)
  }
}

export function setLeadCookie(consentOrigin) {
  return (dispatch, getState, options) => {
    api(options).createServerSessionCookies(consentOrigin)
      .then(leadId => {
        if (!leadId) {
          return
        }

        api(options).updateCookiesConsent(
          leadId,
          {
            consentOrigin,
            isoCode: api(options).getIsoCode()
          }
        )
      })
  }
}

export function initializeCookiesConsent(board, campaign, organizationSettings, privacyMessage) {
  if (isEmpty(board) || isEmpty(campaign) || isEmpty(organizationSettings)) {
    // state not ready
    return prepareInitialState()
  }

  const CookieConsentProvider = COOKIE_CONSENT_TYPES[get(organizationSettings,'privacy.privacy_warning_provider','app')]
  cookieConsentProvider = new CookieConsentProvider(board, campaign, privacyMessage)

  return dispatch => {
    cookieConsentProvider.getProviderData()
      .then((providerData) => {
        cookieConsentProvider.providerData = providerData
        cookieConsentProvider.isConsentNeeded()
          .then(isConsentNeeded => {
            const hasConsent = !isConsentNeeded

            if (hasConsent) {
              cookieConsentProvider.createServerSessionCookies()
            }

            dispatch({
              type: SET_COOKIES_CONSENT,
              isPrivacyWarningOpen : isConsentNeeded,
              hasConsent,
              providerData,
              ...cookieConsentProvider.privacyMessageProperties()
            })
          })
        })
  }
}

const initialState = {
  isPrivacyWarningOpen: false,
  hasConsent: false,
  providerData: null,
  privacyMessageComponent: null,
  privacyMessageMetadata: {}
}

export function prepareInitialState() {
  return initialState
}

export default function cookiesConsent(state = initialState, action) {
  switch(action.type) {
    case CLOSE_PRIVACY_WARNING:
      return {
        ...state,
        isPrivacyWarningOpen: false
      }
    case SET_HAS_CONSENT:
      return {
        ...state,
        hasConsent: action.hasConsent,
        isPrivacyWarningOpen: false
      }
    case SET_COOKIES_CONSENT:
      return {
        isPrivacyWarningOpen: action.isPrivacyWarningOpen,
        hasConsent: action.hasConsent,
        providerData: action.providerData,
        privacyMessageComponent: action.privacyMessageComponent,
        privacyMessageMetadata: action.privacyMessageMetadata
      }
    default:
      return state
  }
}
