import * as itemsApi from 'LiveBoard/api/items'
import isEmpty from 'lodash/isEmpty'
import GUID from 'common/helpers/GUID'
import get from 'lodash/get'

const GUID_KEY = GUID.keys.timeSpent
const FETCH_ITEM_SUCCESS = 'items/FETCH_ITEM_SUCCESS'
const FETCH_ITEMS_SUCCESS = 'items/FETCH_ITEMS_SUCCESS'
export const TRACK_VIEWED_ITEM = 'items/TRACK_VIEWED_ITEM'

function api(options = {}) {
  return options.getApi ? options.getApi() : itemsApi
}

export function fetchItem(boardId, itemId) {
  return function(dispatch, getState, options) {
    return api(options).fetchItem({ boardId, itemId })
      .then((item) => {
        dispatch({
          type: FETCH_ITEM_SUCCESS,
          item,
          itemId
        })
        return item
      })
  }
}

export function fetchItems(params) {
  return function(dispatch, getState, options) {
    const boardId = getState().board.id
    return api(options).fetchItems({ boardId, ...params })
      .then((items) => {
        dispatch({
          type: FETCH_ITEMS_SUCCESS,
          items
        })

        return items
      })
  }
}

export function fetchHasItems(leadingItemId) {
  return function(dispatch, getState, options) {
    const boardId = getState().board.id
    return api(options).fetchHasItems({ boardId, leadingItemId })
  }
}

export function trackItemViewed(itemId) {
  return function(dispatch, getState, options) {
    let currentItem = getState().items.data[itemId]
    if (!currentItem) {
      const itemViewer = getState().itemViewer
      currentItem = itemViewer.items[itemViewer.currentItemIndex]
    }
    const guid = GUID.create(GUID_KEY)
    itemsApi.viewItem({
      itemId,
      guid,
      contentItemId: currentItem.content_item_id,
      sourceType: 'item'
    })
    dispatch({
      type: TRACK_VIEWED_ITEM,
      trackingParams: {
        item_id: itemId,
        item_name: get(currentItem, 'name')
      }
    })
  }
}

const initialState = {
  data: {
    // [itemId]: {}...
  }
}

export function prepareInitialState({ items }) {
  if (isEmpty(items)) {
    return initialState
  }

  return {
    data: items.data,
    promotedItemId: +items.promoted_item_id
  }
}

export default function items(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITEM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.itemId]: action.item
        }
      }
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.items.data
        }
      }
    default:
      return state
  }
}
