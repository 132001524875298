import React from 'react'

export const u2BoardDuplicateFeatureModalParams = {
  header: 'Time to level up! 🚀',
  title: 'Duplicating legacy designer boards has been discontinued, but this is a fantastic opportunity to elevate your game with the new BX 3.0 Designer. \n You can either create a new board based on the templates or duplicate a BX 3.0 board.',
  message: <>
    <p>For more details, contact your company admin or visit our Help Center:</p>
    <div>
      <a className='link' target='_blank' rel='noreferrer' href="https://help.folloze.com/s/article/BuyEx-3-0-Experience-Overview"> BuyEx 3.0 Experience Overview </a>
      <br />
      <a className='link' target='_blank' rel='noreferrer' href="https://help.folloze.com/s/article/How-to-Use-the-BuyEx-3-0-Designer"> How to Use the BuyEx 3.0 Designer </a>
    </div>
  </>,
  confirmationType: 'info',
  cancelLabel: 'Close',
  confirmLabel: '+ Add Board'
}
