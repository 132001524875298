import { useEffect, useState, useRef } from 'react'

export default function useDimensions() {
  const ref = useRef(null)
  const [dimensions, setDimensions] = useState({
    width: null,
    height: null
  })

  useEffect(() => {
    if (!ref.current) return

    const observer = new ResizeObserver(([entry]) => {
      if (entry?.contentRect) {
        setDimensions({
          width: Math.round(entry.contentRect.width),
          height: Math.round(entry.contentRect.height)
        })
      }
    })

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return [ref, dimensions]
}
