import { axios, withPartialContent } from 'shared/services/axios'

export function fetchBoard(boardSlug) {
  return axios.get(`/live_board/v1/boards/${boardSlug}/`)
    .then(response => response.data)
}

export const fetchPresenter = withPartialContent(({ boardId, token }) => {
  return axios.get(`/live_board/v1/boards/${boardId}/presenter`, {
    params: { token }
  })
})

export function trackLeadView(boardId) {
  return axios.post(`${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v1/boards/${boardId}/lead_views`)
}
