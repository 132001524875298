import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { TileTransition } from 'shared/UI/TileTransition'
import { SuccessMessage } from 'LiveBoard/components/shared/SuccessMessage'

import './style.css.scss'

const CampaignTransition = (props) => {
  return (
    <div className="campaign-transition">
      <CSSTransition
          in={props.shouldTransition}
          timeout={0}
          classNames="tile">
        <TileTransition
          themeColor={props.themeColor}
          successMessage={props.successMessage}
          showLoaderText={true}>
          <SuccessMessage successMessage={props.successMessage} />
        </TileTransition>
      </CSSTransition>
    </div>
  )
}

export default CampaignTransition