import { getCookie, setCookie } from 'shared/helper'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const GREETING_COOKIE_NAME = "showGreetingOnce"
const CLOSE_GREETING = 'greetings/CLOSE_GREETING'

export function closeGreeting() {
  return (dispatch, getState) => {
    const { board } = getState()
    const greetingCookie = getCookie(GREETING_COOKIE_NAME) || {}

    greetingCookie[board.id] = true
    setCookie(GREETING_COOKIE_NAME, greetingCookie)

    dispatch({
      type: CLOSE_GREETING
    })
  }
}

const initialState = { isOpen: false }

export const prepareInitialState = ({ campaign }) => {
  if(isEmpty(campaign)) {
    return initialState
  }

  const greetingCookie = getCookie(GREETING_COOKIE_NAME)

  return {
    isOpen: !get(greetingCookie, [campaign.board_id], false) &&
        get(campaign.general, 'greetings.show', false)
  }
}

export default function greeting(state = initialState, action) {
  switch(action.type) {
    case CLOSE_GREETING:
      return initialState
    default:
      return state
  }
}
