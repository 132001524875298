import * as campaignApi from 'LiveBoard/api/campaigns'
import CampaignEditorConfig from 'common/constants/CampaignEditorConfig'
import isEmpty from 'lodash/isEmpty'
import { CAMPAIGN_TYPES } from 'common/constants/campaignTypes'

const FETCH_CAMPAIGN_SUCCESS = 'campaign/FETCH_CAMPAIGN_SUCCESS'
const GATING_SUCCESS = 'campaign/GATING_SUCCESS'

function api(options = {}) {
  return !!options.getApi ? options.getApi() : campaignApi
}

export function fetchCampaign({ boardId, forceFetch = false }) {
  return function(dispatch, getState, options) {
    const currentCampaign = getState().campaign

    if (isEmpty(currentCampaign) || !!forceFetch) {
      return api(options).fetchCampaign({ boardId })
        .then((campaign) => {
          dispatch({
            type: FETCH_CAMPAIGN_SUCCESS,
            campaign
          })

          return campaign
        })
    }
    else {
      return Promise.resolve(currentCampaign)
    }
  }
}

function getCampaignColors(campaign) {
  const themeColor = campaign.general.theme_color
  const themeConfig = CampaignEditorConfig.themes[themeColor]
  const textColors = CampaignEditorConfig.text_color
  const dark = textColors.dark.hex
  const bannerConfig = textColors[campaign.banner.text_color].hex

  const bannerTextColorHex = !campaign.banner.show ? dark : bannerConfig
  const headerTextColorHex = (campaign.header.background.show || !campaign.banner.show)
      ? dark : bannerConfig

  return {
    themeColorHex: themeConfig ? themeConfig.hex : themeColor,
    bannerTextColorHex,
    headerTextColorHex
  }
}

export function onGatingSuccess(successMessage) {
  return {
    type: GATING_SUCCESS,
    successMessage
  }
}

const initialState = {
  isChanged: false,
  successMessage: ''
}

export function prepareInitialState({ campaign }) {
  if (isEmpty(campaign)) {
    return initialState
  }

  return {
    ...campaign,
    ...getCampaignColors(campaign),
    isGating: campaign.campaign_type == CAMPAIGN_TYPES.gating,
    ...initialState
  }
}

export default function campaign(state = initialState, action) {
  switch(action.type) {
    case FETCH_CAMPAIGN_SUCCESS:
      return prepareInitialState({ campaign: action.campaign })
    case GATING_SUCCESS:
      return {
        ...state,
        isChanged: true,
        successMessage: action.successMessage
      }
    default:
      return state
  }
}
