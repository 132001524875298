import React, { useEffect, useRef } from 'react'
import { useSelector, connect } from 'react-redux'
import { withRouter } from 'shared/hocs'
import { useNavigate } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import ItemViewerContainer from 'LiveBoard/containers/ItemViewerContainer'
import withKeyEventHandler, { KEY_HANDLERS } from 'shared/hocs/withKeyEventHandler'
import { withCookieConsent, withOutboundLink } from 'LiveBoard/hocs'
import RoutesService from 'LiveBoard/services/routes'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import ModalHeaderActions from './ModalHeaderActions'
import { getItemTypeConfig } from 'LiveBoard/components/shared/ItemType'
import get from 'lodash/get'
import {
  likeItem, trackItemDownloaded, fetchItemDownloadUrl, setViewerMode
} from 'LiveBoard/modules/itemViewer'
import { setPreviousLocation } from 'LiveBoard/modules/itemsView'
import { getItemRoute } from 'LiveBoard/components/shared/itemUrlHelper'

import './style.css.scss'

const { EventTypes, dispatch: dispatchPublicEvent } = PublicEventsDispatcher()
const ITEM_VIEWER_CONFIG_DEFAULTS = {
  is_classic_theme: true,
  has_navigation: true
}

const ItemViewerModal = (props) => {
  const storedItemViewerConfig = useSelector(({ campaign }) => campaign.items.item_viewer_config)
  const blockDownload = useSelector(({ campaign }) => campaign.items.block_download)
  const themeColorHex = useSelector(({ campaign }) => campaign.themeColorHex)
  const showHeader = useSelector(({ campaign }) => campaign.header.show)
  const showLikes = useSelector(({ campaign }) => campaign.items.show_likes)
  const board = useSelector(({ board }) => board)
  const previousLocation = useSelector(({ itemsView }) => itemsView.previousLocation)
  const currentItem = useSelector(({ itemViewer }) => itemViewer.items[itemViewer.currentItemIndex])

  const navigate = useNavigate()

  const itemViewerConfig = {
    ...ITEM_VIEWER_CONFIG_DEFAULTS,
    ...storedItemViewerConfig || {},
    ...props.modalOptions
  }
  const isClassicTheme = itemViewerConfig.is_classic_theme

  useEffect(() => {
    props.provideKeyHandlers({
      [KEY_HANDLERS.esc]: handleClose
    })

    props.setViewerMode(isClassicTheme ? 'classic' : 'lightbox')
  }, [])

  useEffect(() => {
    if (!props.modalOptions.isLanding) {
      const { pathname, search } = props.location
      props.setPreviousLocation(`${pathname}${search || ''}`)
      const itemRoute = getItemRoute(props.item, props.category, board)
      navigate(`${itemRoute}${props.queryString}`)
    }
  }, [])

  const themeClass = isClassicTheme ? 'classic-theme' : ''
  /* global FollozeState */
  const device = FollozeState.platform
  const deviceClass = `${device}-modal-portal`

  const MODAL_DEFAULT_CLASSES = {
    bodyOpenClassName: 'modal-open',
    overlayClassName: 'modal-overlay item-modal-overlay',
    portalClassName: `modal-portal ${deviceClass} ${themeClass}`,
    className: {
      base: `item-modal-body item-modal-type-${get(currentItem, 'item_type')} ${showHeader ? 'show-header' : ''}`,
      afterOpen: 'item-modal-body--after-open'
    }
  }

  const handleClose = () => {
    props.onCookieConsent()
    const boardRoute = RoutesService.getBoardRoute(board)
    const search = props.location.search
    navigate(
      previousLocation || `${boardRoute}${search || ''}`
    )

    dispatchPublicEvent(EventTypes.closeItemViewer)
  }

  const itemViewerContainerParams = {
    itemId: props.item.id,
    itemSlug: props.item.slug,
    categoryId: props.category.id,
    categorySlug: props.category.slug
  }

  const getMenuItems = () => {
    const itemConfig = !!currentItem && getItemTypeConfig(currentItem, blockDownload)

    return {
      newTab: {
        iconClass: 'icon-new-tab',
        onClick: handleOpenInNewTab,
        shouldDisplay: get(itemConfig, 'canOpenInNewTab', false)
      },
      download: {
        iconClass: 'icon-download',
        onClick: handleDownloadItem,
        shouldDisplay: get(itemConfig, 'canDownload', false)
      },
      like: {
        iconClass: 'icon-like-empty',
        onClick: handleItemLike,
        shouldDisplay: showLikes && !get(currentItem, 'liked_by_user', true)
      },
      likeFull: {
        iconClass: 'icon-like-full',
        shouldDisplay: showLikes && get(currentItem, 'liked_by_user', false)
      }
    }
  }

  const handleOpenInNewTab = () => {
    props.onCookieConsent()
    props.onOpenOutboundLink(currentItem.link_url)
  }

  const handleItemLike = () => {
    props.onCookieConsent()
    props.likeItem(currentItem)
  }

  const downloadLink = useRef()
  const handleDownloadItem = () => {
    props.onCookieConsent()

    return props.fetchItemDownloadUrl(currentItem.content_item_id)
      .then(data => {
        const downloadElement = <a ref={downloadLink}></a>
        ReactDOM.render(downloadElement, document.getElementsByClassName('download-link')[0])
        downloadLink.current.href = data.download_url
        downloadLink.current.click()
        props.trackItemDownloaded(currentItem)
      })
  }

  return (
    <Modal
      {...MODAL_DEFAULT_CLASSES}
      isOpen={true}
      parentSelector={() => document.getElementById('item-viewer-modal-placement')}>
      { currentItem &&
        <div className="item-modal-actions-row">
          <ModalHeaderActions
            isClassicTheme={isClassicTheme}
            isMobile={device === 'mobile'}
            onClose={handleClose}
            themeColorHex={themeColorHex}
            menuActions={getMenuItems()} />
        </div>
      }
      <ItemViewerContainer
        params={itemViewerContainerParams}
        onClose={handleClose}
        configDefaults={ITEM_VIEWER_CONFIG_DEFAULTS}
        onOpenInNewTab={handleOpenInNewTab}
        onLike={handleItemLike}
        onDownload={handleDownloadItem} />
    </Modal>
  )
}

ItemViewerModal.propTypes = {
  provideKeyHandlers: PropTypes.func.isRequired,
  item: PropTypes.object,
  category: PropTypes.object,
  queryString: PropTypes.string,
  modalOptions: PropTypes.object,
  setViewerMode: PropTypes.func,
  location: PropTypes.object,
  setPreviousLocation: PropTypes.func,
  onCookieConsent: PropTypes.func,
  onOpenOutboundLink: PropTypes.func,
  likeItem: PropTypes.func,
  fetchItemDownloadUrl: PropTypes.func,
  trackItemDownloaded: PropTypes.func
}

ItemViewerModal.defaultProps = {
  queryString: '',
  modalOptions: {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    likeItem, trackItemDownloaded, fetchItemDownloadUrl, setViewerMode, setPreviousLocation
  }, dispatch)
}

export default withCookieConsent(
  withOutboundLink(
    withKeyEventHandler(
      connect(null, mapDispatchToProps)(withRouter(ItemViewerModal))
    )
  )
)
