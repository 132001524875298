import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { usePrevious, useActions } from 'shared/customHooks'
import { trackItemViewDuration } from 'LiveBoard/modules/itemViewer'

const EVENT_DURATIONS = [5, 10, 30, 60, 120, 300, 600]
const FIRST_DURATION_INDEX = 0
const SECONDS_FACTOR = 1000

const SphereItemDurationContainer = (props) => {
  const runningTimeout = useRef()
  const nextDuration = useRef(0)

  const itemId = useSelector(state => state.itemViewer.currentItemId)
  const currentItem = useSelector(({ itemViewer }) => itemViewer.items[itemViewer.currentItemIndex])
  const isSessionActive = useSelector(state => state.session.isActive)
  const sessionGuid = useSelector(state => state.session.guid)
  const isPrevSessionActive = usePrevious(isSessionActive)
  const prevItemId = usePrevious(itemId)

  useEffect(() => {
    if (prevItemId) {
      initiateDurationTracker()
    }

    if (itemId) {
      trackDuration()
    }
  }, [itemId])

  useEffect(() => {
    if (isFirstSessionExists()) {
      trackDuration()
    }
  }, [sessionGuid])

  useEffect(() => {
    if (shouldTerminateDurationTracking()) {
      initiateDurationTracker()
    }
  }, [isSessionActive])

  const actions = useActions({
    trackItemViewDuration
  }, [])

  const isFirstSessionExists = () => {
    return isPrevSessionActive != null
  }

  const shouldTerminateDurationTracking = () => {
    return isSessionActive === false
  }

  const trackDuration = () => {
    runningTimeout.current = setTimeout(() => { handleDurationPublisher() }, getNextDuration())
  }

  const initiateDurationTracker = () => {
    clearTimeout(runningTimeout.current)
    nextDuration.current = FIRST_DURATION_INDEX
  }

  const shouldTrackDuration = () => {
    return nextDuration.current < (EVENT_DURATIONS.length - 1)
  }

  const handleDurationPublisher = () => {
    actions.trackItemViewDuration({
      contentItemId: currentItem.content_item_id,
      duration: getDuration()
    })

    if (shouldTrackDuration()) {
      nextDuration.current += 1
      trackDuration()
    } else {
      initiateDurationTracker()
    }
  }

  const getDuration = (index = nextDuration.current) => {
    return EVENT_DURATIONS[index]
  }

  const isFirstDurationEvent = () => {
    return nextDuration.current === FIRST_DURATION_INDEX
  }

  const getNextDuration = () => {
    const duration = isFirstDurationEvent()
      ? getDuration(nextDuration.current)
      : getDuration() - getDuration(nextDuration.current - 1)
    return duration * SECONDS_FACTOR
  }

  return null
}

export default SphereItemDurationContainer
