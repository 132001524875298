function InbyPropagationService() {
  "ngInject";

  function getItemUrl({ inviter, url }) {
    if(inviter && isItemAFollozeCampaign(url)) {
      return attachInbyToUrl(url, inviter)
    }

    return url
  }

  function shouldPropagateInby({ inby, inviter, url }) {
    return (inby || inviter) && isItemAFollozeCampaign(url)
  }

  function attachInbyToUrl(url, inby){
    return `${url}${url.includes('?') ? '&' : '?'}inby=${inby}`
  }

  function isItemAFollozeCampaign(url){
    return !!url && window.location.host == new URL(url).host
  }

  return {
    shouldPropagateInby,
    attachInbyToUrl,
    getItemUrl
  }
}

module.exports = InbyPropagationService;
