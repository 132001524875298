import { LOCALE_MAPPING, DEFAULT_TRANSLATION } from './constants'

import get from 'lodash/get'

const I18n = (locale) => {
  const getTranslation = () => {
    const localeTranslation = LOCALE_MAPPING[locale] || DEFAULT_TRANSLATION

    return localeTranslation
  }

  const replaceParams = (value, params) => {
    let newTranslation = value
    const reg = /(%{.*?})/gi

    Object.keys(params).forEach(key => {
      newTranslation = newTranslation.replace(`%{${key}}`, params[key])
    })

    return newTranslation.replace(reg, '').trim() // remove missing params and trim spaces
  }

  const translate = (key, value, params = {}) => {
    const translation = getTranslation()
    const translationValue = get(translation, `${key}.${value}`, DEFAULT_TRANSLATION[key][value])

    return translationValue ? replaceParams(translationValue, params) : ''
  }

  return {
    translate
  }
}

export default I18n
