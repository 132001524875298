import * as api from 'LiveBoard/api/organizationSettings'
import isEmpty from 'lodash/isEmpty'

const FETCH_ORGANIZATION_SETTINGS_SUCCESS = 'organizationSettings/FETCH_ORGANIZATION_SETTINGS_SUCCESS'

export function fetchOrganizationSettings(boardId) {
  return function(dispatch, getState) {
    const currentSettings = getState().organizationSettings

    if (isEmpty(currentSettings)) {
      return api.fetchOrganizationSettings(boardId)
        .then((organizationSettings) => {
          dispatch({
            type: FETCH_ORGANIZATION_SETTINGS_SUCCESS,
            organizationSettings
          })

          return organizationSettings
        })
    }
    else {
      return Promise.resolve(currentSettings)
    }
  }
}

const initialState = {}

export function prepareInitialState({ organization_settings }) {
  if (isEmpty(organization_settings)) {
    return initialState
  }

  return organization_settings
}

export default function organizationSettings(state = initialState, action) {
  switch(action.type) {
    case FETCH_ORGANIZATION_SETTINGS_SUCCESS:
      return action.organizationSettings
    default:
      return state
  }
}
