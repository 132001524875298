import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import LiveBoardContainer from 'LiveBoard/containers/LiveBoardContainer'
import BoardContentContainer from 'LiveBoard/containers/BoardContentContainer'
import RoutesService from 'LiveBoard/services/routes'

export default function getRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutesService.boardPath} element={<LiveBoardContainer />}>
          <Route path={`${RoutesService.categoryPath}/${RoutesService.itemPath}`} element={<BoardContentContainer />} />
          { RoutesService.oldItemPath && <Route path={RoutesService.oldItemPath} element={<BoardContentContainer />} />}
          <Route path={`${RoutesService.categoryPath}`} element={<BoardContentContainer />} />
          <Route element={<BoardContentContainer />} />
          <Route path="/*" element={<BoardContentContainer />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
