import Base from './Base'
import {
  COOKIE_CONSENT_DATA_TYPES, NO_CONSENT_NEEDED, CONSENT_NEEDED
} from './constants'
import { PrivacyWarningInjector } from 'LiveBoard/components/shared/PrivacyWarning'
import { fetchMsProviderPrivacyData } from 'LiveBoard/api/cookiesConsent'
import get from 'lodash/get'

class MsProvider extends Base {
  constructor(board, campaign) {
    super(board, campaign)
    this.dataType = COOKIE_CONSENT_DATA_TYPES.byLocation
    this.privacyMessageMetadata = {
      markup: '',
      scriptFiles: [],
      cssFiles: []
    }
  }

  privacyMessageProperties = () => {
    return {
      privacyMessageComponent: PrivacyWarningInjector,
      privacyMessageMetadata: { ...this.privacyMessageMetadata }
    }
  }

  isConsentNeeded = () => {
    return new Promise((resolve, reject) => {
      fetchMsProviderPrivacyData({
        locale: FollozeState.initialState.locale,
        country: this.getIsoCode()
      })
        .then(cookieConsentProviderData => {
          this.privacyCookieName = cookieConsentProviderData.CookieName
          const cookieData = this.getConsentCookie()

          // no privacy warning required on board or api returns no consent required
          if (!this.privacyWarningCheck || !cookieConsentProviderData.IsConsentRequired) {
            resolve(NO_CONSENT_NEEDED)
          }
          else {
            // check consent date is not before minimum required consent date
            const minimumConsentDate = new Date(cookieConsentProviderData.MinimumConsentDate).getTime() / 1000

            if (cookieData && cookieData < minimumConsentDate) {
              this.removeCookieConsent()
            }

            this.privacyMessageMetadata = {
              markup: cookieConsentProviderData.Markup,
              scriptFiles: cookieConsentProviderData.Js,
              cssFiles: cookieConsentProviderData.Css
            }

            resolve(CONSENT_NEEDED)
          }
        })
    })
  }

  setConsentCookie = () => {
    (typeof mscc !== 'undefined') && mscc.setConsent()
  }
}

export default MsProvider