import * as api from 'LiveBoard/api/boards'

import isEmpty from 'lodash/isEmpty'

const FETCH_PRESENTER_SUCCESS = 'presenter/FETCH_PRESENTER_SUCCESS'

export function fetchPresenter(boardId, token, forceFetch = false) {
  return function(dispatch, getState) {
    const currentPresenter = getState().presenter

    if (isEmpty(currentPresenter) || forceFetch ) {
      return api.fetchPresenter({ boardId, token })
        .then((presenter) => {
          return dispatch({
            type: FETCH_PRESENTER_SUCCESS,
            presenter
          })
        })
    }
    else {
      return Promise.resolve(currentPresenter)
    }
  }
}

const initialState = {}

export function prepareInitialState({ presenter }) {
  if (isEmpty(presenter)) {
    return initialState
  }

  return presenter
}

export default function presenter(state = initialState, action) {
  switch(action.type) {
    case FETCH_PRESENTER_SUCCESS:
      return action.presenter
    default:
      return state
  }
}
