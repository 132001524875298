import React from 'react'
import Loadable from 'react-loadable'

const Loading = (props) => {
  // eslint-disable-next-line react/prop-types
  if (props.error) {
    // eslint-disable-next-line react/prop-types
    console.log('error', props.error)
    // window.location.reload(); // in order to fetch updated asset
  }
  return null
}

export const load = (path) => {
  // NOTE: disable lazy loading with variable path (./components/mobile/${path}),
  // as webpack creates all possible chunck permutations.
  return Loadable({
    loader: () => {
      if (window.FollozeState.platform === 'mobile') {
        return import(
          /* webpackMode: "lazy-once" */
          /* webpackChunkName: "LiveBoard/[request]" */
          `./components/mobile/${path}`
        )
      } else {
        return import(
          /* webpackMode: "lazy-once" */
          /* webpackChunkName: "LiveBoard/[request]" */
          `./components/desktop/${path}`
        )
      }
    },
    loading: Loading
  })
}
