import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CampaignAnalytics from 'common/services/CampaignAnalytics'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import get from 'lodash/get'
import assign from 'lodash/assign'

class CampaignAnalyticsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.campaignAnalyticsService = CampaignAnalytics()
    this.follozeEventTypes = PublicEventsDispatcher().EventTypes
  }

  componentDidMount() {
    this.registerAnalytics()
    window.addEventListener(this.follozeEventTypes.pageView, this.track)
  }

  componentWillUnmount() {
    window.removeEventListener(this.follozeEventTypes.pageView, this.track)
  }

  registerAnalytics = () => {
    this.campaignAnalyticsService.register(this.props.settings)
  }

  track = (e) => {
    this.trackLocationChange(e.detail.location)
  }

  trackLocationChange = (location) => {
    this.campaignAnalyticsService.track(location)
  }

  render() {
    return null
  }
}

CampaignAnalyticsContainer.propTypes = {
  settings: PropTypes.object
}

function mapStateToProps({ board: {integrations}, lead:{anon_guest} }) {
  return {
    settings: {
      ga: get(integrations, 'ga', {}),
      munchkin: assign(get(integrations, ['marketo', 'munchkin'], {}), {anon_guest}),
      eloqua: get(integrations, 'eloqua', {}),
      pardot: get(integrations, 'pardot', {})
    }
  }
}

export default connect(mapStateToProps, null)(CampaignAnalyticsContainer)
