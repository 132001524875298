import React from 'react'
import PropTypes from 'prop-types'
import LiveEventIndication from './LiveEventIndication/LiveEventIndication'
import map from 'lodash/map'
import { triggerClickOnEnter, ACCESSIBILITY_CLASSES, TAB_INDEXES } from 'shared/helpers/AccessibilityHelper'

import './style.css.scss'

const ItemModalActionButtons = (props) => {
  return !!props.data && (
    <div className="action-buttons-container">
      { !!props.isLiveEventActive &&
        <LiveEventIndication onClose={props.onClose}/> }
      <div className="action-buttons">
        { map(props.data, (action, i) => {
            return action.shouldDisplay && (
              <span
                key={i}
                className={`${action.iconClass} ${ACCESSIBILITY_CLASSES.main}`}
                onClick={action.onClick}
                onKeyDown={triggerClickOnEnter}
                tabIndex={TAB_INDEXES.ItemModalActionButton}
                role="button" />
            )
          }
        )}
        <div className="download-link"></div>
      </div>
    </div>
  )
}

ItemModalActionButtons.propTypes = {
  data: PropTypes.object.isRequired,
  isLiveEventActive: PropTypes.bool,
  onClose: PropTypes.func
}

export default ItemModalActionButtons
