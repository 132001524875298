import React, { useEffect, useState } from 'react'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import InbyPropagationService from 'common/services/InbyPropagationService'
import { useSelector } from 'react-redux'
import { useActions } from 'shared/customHooks'
import { trackItemViewed } from 'LiveBoard/modules/items'
import { withOutboundLink, withCookieConsent } from 'LiveBoard/hocs'
import isEmpty from 'lodash/isEmpty'

import ItemViewerModal from './ItemViewerModal'

const follozeEventTypes = PublicEventsDispatcher().EventTypes
const inbyPropagationService = InbyPropagationService()

const ItemViewerModalContainer = (props) => {

  const presenter = useSelector(({presenter}) => presenter)
  const actions = useActions({ trackItemViewed }, [])
  const [itemViewerData, setItemViewerData] = useState({})

  useEffect(() => {
    window.addEventListener(follozeEventTypes.openItemViewer, handleOpenItemViewerEvent)
    window.addEventListener(follozeEventTypes.closeItemViewer, handleCloseItemViewerEvent)
    return () => {
      window.removeEventListener(follozeEventTypes.openItemViewer, handleOpenItemViewerEvent)
      window.removeEventListener(follozeEventTypes.closeItemViewer, handleCloseItemViewerEvent)
    }
  }, [])

  const handleOpenItemViewerEvent = ({detail}) => {
    const { item, category, queryString, options } = detail

    if (!options || !options.isLanding) {
      props.onCookieConsent()
    }

    if (item.open_in_new_tab) {
      openItemInNewTab(item)
    }
    else {
      setItemViewerData({ item, category, queryString, modalOptions: options })
    }
  }

  const openItemInNewTab = (item) => {
    const linkUrl = inbyPropagationService.getItemUrl({
      inviter: presenter.email,
      url: item.link_url
    })
    actions.trackItemViewed(item.id)
    props.onOpenOutboundLink(linkUrl, '_blank')
  }

  const handleCloseItemViewerEvent = () => setItemViewerData({})

  return (!isEmpty(itemViewerData)) && <ItemViewerModal { ...itemViewerData } />
}

export default withCookieConsent(withOutboundLink(ItemViewerModalContainer))
