import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedDaDK = shared.da

const daDK = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedDaDK.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Lavet af'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Hej %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: "Klik på 'SEND', så vender vi snart tilbage til dig.",
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Ikke %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedDaDK.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedDaDK.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedDaDK.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedDaDK.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedDaDK.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Udfyld påkrævede felter',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedDaDK.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Udfyld påkrævede felter'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedDaDK.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedDaDK.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Din besked',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedDaDK.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Del denne side ved hjælp af'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Hvem vil du gerne dele med?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedDaDK.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedDaDK.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedDaDK.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedDaDK.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedDaDK.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedDaDK.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedDaDK.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'del@e-mail.her',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Afsenderoplysninger'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedDaDK.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Mest sete'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Fil',
    [TRANSLATION_VALUE_KEYS.image]: 'Billede',
    [TRANSLATION_VALUE_KEYS.link]: 'Link',
    [TRANSLATION_VALUE_KEYS.video]: 'Video',
    [TRANSLATION_VALUE_KEYS.article]: 'Artikel'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Søg'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Hjem'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Indtast adgangskode',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'adgangskoden er forkert. Prøv igen',
    [TRANSLATION_VALUE_KEYS.go]: 'BEKRÆFT',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'indtast adgangskode her',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Se indhold'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedDaDK.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedDaDK.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Åbn i ny fane',
    [TRANSLATION_VALUE_KEYS.like]: 'Synes godt om',
    [TRANSLATION_VALUE_KEYS.download]: 'Download'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Indhold'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedDaDK.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedDaDK.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'E-mail',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedDaDK.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedDaDK.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedDaDK.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Din besked',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedDaDK.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedDaDK.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedDaDK.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedDaDK.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedDaDK.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedDaDK.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Skriv din beked her...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Mere',
    [TRANSLATION_VALUE_KEYS.less]: 'Mindre'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Tak skal du have'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Denne side kræver, at du verificerer din e-mailadresse',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Indtast din e-mail for at anmode om en bekræftelseskode',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Indtast din e-mail'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Denne side kræver, at du verificerer din e-mailadresse',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Indtast din e-mail for at anmode om en bekræftelseskode',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Indtast din e-mail',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Fortsæt til siden',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Send en ny kode',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Ny kode sendt',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Bekræftelseskoden er blevet sendt til dig. Tjek for en e-mail fra',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Bemærk, at e-mailen kan være i din reklame-, opdaterings- eller spammappe',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Denne e-mailadresse er ikke autoriseret til at se denne side',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Beklager, at du har problemer med at komme til denne side',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Kontakt din virksomhedsrepræsentant for at få adgang',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Indtast din bekræftelseskode',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Indtast koden her',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Forkert kode',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Der opstod en fejl',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedDaDK.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedDaDK.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedDaDK.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedDaDK.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Denne side er adgangskodebeskyttet',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Indtast adgangskoden nedenfor',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Indtast adgangskoden her',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Verificér adgangskoden',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Forkert adgangskode'
  }
}

export default daDK
