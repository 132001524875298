import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedKoKr = shared['ko-KR']

const koKR = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: '보내기',
    [TRANSLATION_VALUE_KEYS.createdBy]: '작성자:'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: '%{leadName} 님',
    [TRANSLATION_VALUE_KEYS.clickSend]: "안녕하세요? '보내기'를 클릭해 주시면 곧 연락을 드리겠습니다.",
    [TRANSLATION_VALUE_KEYS.notLeadName]: '%{leadName} 님이 아닙니까?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: '보내기',
    [TRANSLATION_VALUE_KEYS.done]: '완료',
    [TRANSLATION_VALUE_KEYS.sending]: '보내는 중'
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: '이메일 주소',
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedKoKr.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: '*필수 입력란을 작성하세요',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedKoKr.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: '*필수 입력란을 작성하세요',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedKoKr.requiredSingleField
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedKoKr.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: '여기에 메시지를 입력하세요',
    [TRANSLATION_VALUE_KEYS.yourMessage]: '메시지',
    [TRANSLATION_VALUE_KEYS.yourEmail]: '이메일 주소'
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: '다음을 사용해 이 페이지를 공유하세요'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: '누구와 공유하고 싶습니까?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedKoKr.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedKoKr.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: '수신인',
    [TRANSLATION_VALUE_KEYS.email]: sharedKoKr.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedKoKr.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedKoKr.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedKoKr.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: '*여기에 이메일 주소를 입력하세요',
    [TRANSLATION_VALUE_KEYS.senderDetails]: '발신인 세부 정보'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedKoKr.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: '최다 조회'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: '파일',
    [TRANSLATION_VALUE_KEYS.image]: '이미지',
    [TRANSLATION_VALUE_KEYS.link]: '링크',
    [TRANSLATION_VALUE_KEYS.video]: '동영상',
    [TRANSLATION_VALUE_KEYS.article]: '글'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: '검색'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: '홈'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: '비밀번호를 입력하세요',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: '비밀번호가 정확하지 않습니다. 다시 입력해 주세요',
    [TRANSLATION_VALUE_KEYS.go]: '시작',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: '여기에 비밀번호를 입력하세요…',
    [TRANSLATION_VALUE_KEYS.viewContent]: '내용 보기'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedKoKr.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedKoKr.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: '새 탭에서 열기',
    [TRANSLATION_VALUE_KEYS.like]: '좋아요',
    [TRANSLATION_VALUE_KEYS.download]: '다운로드'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: '콘텐츠'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedKoKr.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedKoKr.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: sharedKoKr.email,
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedKoKr.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedKoKr.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedKoKr.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: '메시지',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedKoKr.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedKoKr.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedKoKr.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedKoKr.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedKoKr.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedKoKr.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: '여기에 메시지를 입력하세요...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: '자세히',
    [TRANSLATION_VALUE_KEYS.less]: '간단히'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: '감사합니다'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: '이 페이지에서는 이메일 주소를 인증해야 합니다',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: '확인 코드를 요청하려면 이메일을 입력하세요',
    [TRANSLATION_VALUE_KEYS.enterEmail]: '이메일 입력',
    [TRANSLATION_VALUE_KEYS.continueToPage]: '페이지로 계속 이동',
    [TRANSLATION_VALUE_KEYS.sendCode]: '새 코드 보내기',
    [TRANSLATION_VALUE_KEYS.codeSent]: '새 코드 전송됨',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: '확인 코드가 전송되었습니다',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: '이메일이 프로모션, 업데이트 또는 스팸 폴더에 있을 수 있으므로 해당 이메일을 확인해 주세요.',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: '이 이메일 주소에 이 페이지를 볼 수 있는 권한이 없습니다',
    [TRANSLATION_VALUE_KEYS.accessPageError]: '죄송합니다, 이 페이지에 액세스하는 데 문제가 있습니다',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: '액세스하려면 회사 담당자에게 문의해 주시기를 바랍니다',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: '확인 코드를 입력하세요',
    [TRANSLATION_VALUE_KEYS.typeCode]: '코드를 입력하세요',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: '잘못된 코드입니다',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: '에러가 발생했습니다',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedKoKr.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedKoKr.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedKoKr.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedKoKr.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: '이 페이지는 비밀번호로 보호됩니다',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: '아래에 비밀번호를 입력하세요',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: '여기에 비밀번호를 입력하세요',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: '비밀번호 확인',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: '잘못된 비밀번호'
  }
}

export default koKR
