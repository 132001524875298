import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import GUID from 'common/helpers/GUID'
import { useInterval } from 'shared/customHooks'
import * as pingsApi from 'LiveBoard/api/pings'
import { getCurrentItemId, getCurrentContentItemId } from './selectors'

const PING_INTERVAL = FollozeState.envConfig.PING_INTERVAL || 5 * 1000 // 5 Second
const GUID_KEY = GUID.keys.timeSpent
const NUM_PINGS_TO_DELAY = 1

const PingContainer = (props) => {
  const leadId = useSelector(state => state.lead.id)
  const boardId = useSelector(state => state.board.id)
  const itemId = useSelector(state => {
    return getCurrentItemId(state)
  })
  const contentItemId = useSelector(state => {
    return getCurrentContentItemId(state)
  })
  const isSessionActive = useSelector(state => state.session.isActive)
  const sessionGuid = useSelector(state => state.session.guid)
  const [isIntervalRunning, setIsIntervalRunning] = useState(false)
  const [intervalFactor, setIntervalFactor] = useState(NUM_PINGS_TO_DELAY + 1)

  useInterval(() => {
    if (intervalFactor > NUM_PINGS_TO_DELAY) {
      setIntervalFactor(intervalFactor - 1)
    }
    sendPing()
  }, isIntervalRunning ? intervalFactor * PING_INTERVAL : null)

  useEffect(() => {
    isSessionActive && initiatePings()
    return stopPings()
  }, [])

  useEffect(() => {
    isSessionActive ? initiatePings() : stopPings()
  }, [itemId, isSessionActive])

  const handleCloseWindow = useCallback(() => {
    sendPing()
  }, [isSessionActive, leadId, itemId, contentItemId, sessionGuid])

  useEffect(() => {
    const handleBeforeUnload = () => {
      handleCloseWindow()
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [handleCloseWindow])
  
  const initiatePings = () => {
    setIsIntervalRunning(true)
    sendPing()
  }

  const stopPings = () => {
    setIsIntervalRunning(false)
  }

  const sendPing = () => {
    validateSession() && isSessionActive && pingsApi.sendPing({
      leadId: leadId,
      boardId: boardId,
      itemId: itemId,
      guid: GUID.get(GUID_KEY),
      sessionGuid: sessionGuid,
      contentItemId: contentItemId
    })
  }

  const validateSession = () => {
    return !!leadId && !!boardId && !!sessionGuid
  }

  return null
}

export default PingContainer