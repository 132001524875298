import * as api from 'LiveBoard/api/cta'

const OPEN_CTA_MODAL = 'cta/OPEN_CTA_MODAL'
const CLOSE_CTA_MODAL = 'cta/CLOSE_CTA_MODAL'
export const SUBMIT_CTA_SUCCESS = 'cta/SUBMIT_CTA_SUCCESS'
export const TRACK_SHARE_CLICK = 'cta/TRACK_SHARE_CLICK'
export const SHARE_BY_EMAIL_SUCCESS = 'cta/SHARE_BY_EMAIL_SUCCESS'

export const VIEWER_ROLE = 2

export function submitMessage(values) {
  return submitCta(api.submitMessage, values)
}

export function submitVisitorEmail(values) {
  return submitCta(api.submitVisitorEmail, values)
}

export function submitForm(values, formFields) {
  return submitCta(api.submitForm, { ...values, form_fields: formFields })
}

export function submitLinkCta(values) {
  return submitCta(api.submitLinkCta, values)
}

export function submitShareCta(values) {
  return submitCta(api.submitShareCta, values)
}

function submitCta(action, values) {
  return (dispatch, getState) => {
    const boardId = getState().board.id

    return action(boardId, values)
      .then((lead) => {
        dispatch({
          type: SUBMIT_CTA_SUCCESS,
          trackingParams: {
            button: values.cta.label,
            area: values.cta.area,
            type: values.type,
            link: values.link
          },
          lead
        })
      })
  }
}

function submitCtaSuccess(lead) {
  return {
    type: SUBMIT_CTA_SUCCESS,
    lead
  }
}

export function shareByEmail(email, options) {
  return (dispatch, getState) => {
    const { board } = getState()

    return api.shareByEmail(board.id, email, options)
      .then(() => {
        dispatch({
          type: SHARE_BY_EMAIL_SUCCESS,
          trackingParams: {
            email: email
          }
        })
      })
  }
}

export function trackShareClick() {
  return {
    type: TRACK_SHARE_CLICK
  }
}

export function openCta() {
  return {
    type: OPEN_CTA_MODAL
  }
}

export function closeCta() {
  return {
    type: CLOSE_CTA_MODAL
  }
}

const initialState = { isOpen: false }

export function prepareInitialState() {
  return initialState
}

export default function cta(state = initialState, action) {
  switch(action.type) {
    case OPEN_CTA_MODAL:
      return {
        ...state,
        isOpen: true
      }
    case CLOSE_CTA_MODAL:
      return {
        ...state,
        isOpen: false
      }
    default:
      return state
  }
}
