import React from 'react'
import { I18n } from 'shared/services/I18n'

export { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from 'shared/services/I18n'

export default function withTranslation(translationKey) {
  return WrappedComponent => {
    class Translation extends React.Component {
      getTranslation(value, params) {
        const locale = FollozeState.initialState.locale

        return I18n(locale).translate(translationKey, value, params)
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            getTranslation={this.getTranslation} />
        )
      }
    }

    return Translation
  }
}
