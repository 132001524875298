export default class VisibilityService {
  constructor() {
    let visibilityChangeAttr
    this.changeHandler = null

    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      this.hiddenAttr = "hidden"
      visibilityChangeAttr = "visibilitychange"
    } else if (typeof document['mozHidden'] !== "undefined") {
      this.hiddenAttr = "mozHidden"
      visibilityChangeAttr = "mozvisibilitychange"
    } else if (typeof document['msHidden'] !== "undefined") {
      this.hiddenAttr = "msHidden"
      visibilityChangeAttr = "msvisibilitychange"
    } else if (typeof document['webkitHidden'] !== "undefined") {
      this.hiddenAttr = "webkitHidden"
      visibilityChangeAttr = "webkitvisibilitychange"
    }

    document.addEventListener(visibilityChangeAttr, this.fireChange)
  }

  onChange = (handler) => {
    this.changeHandler = handler
  }

  isHidden = () => {
    return !!this.hiddenAttr ? document[this.hiddenAttr] : false
  }

  fireChange = () => {
    const isHidden = this.isHidden()
    this.changeHandler && this.changeHandler(isHidden)
  }
}
