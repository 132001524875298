import { axios, withPartialContent } from 'shared/services/axios'

export function fetchItem({ itemId, boardId, bySlug }) {
  return axios.get(`/live_board/v2/items/${itemId}`, {
    params: { by_slug: bySlug, board_id: boardId }
  }).then(response => response.data)
}

export const fetchItems = withPartialContent(({ boardId, categoryId, search, page, perPage }) => {
  return axios.post(`/live_board/v2/boards/${boardId}/items`, {
    category: categoryId,
    search,
    page,
    per_page: perPage
  })
})

export const fetchHasItems = withPartialContent(({ boardId }) => {
  return axios.get(`/live_board/v2/boards/${boardId}/items_presence`)
})

export function likeItem({ itemId, sourceType, contentItemId }) {
  return axios.post(`${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v2/content_items/${contentItemId}/likes`, {
    item_id: itemId,
    source_type: sourceType
  })
    .then(response => response.data)
}

export function fetchJourneyItem(itemId, { query, categoryId, boardId }) {
  return axios.post(`/live_board/v2/journeys/${itemId}`, {
    search: query, category_id: categoryId, board_id: boardId
  }).then(response => response.data)
}

export function viewItem({ itemId, guid, contentItemId, sourceType }) {
  return axios.post(`${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v2/content_items/${contentItemId}/lead_views`, {
    guid,
    item_id: itemId,
    source_type: sourceType
  })
}

export function fetchItemDownloadUrl(contentItemId) {
  return axios.get('/live_board/v2/downloads', {
    params: {
      content_item_id: contentItemId
    }
  }).then(response => response.data)
}

export function trackItemDownloaded({ itemId, contentItemId, sourceType }) {
  return axios.post(`${FollozeState.envConfig.ANALYTICS_SERVICE_ADDRESS}/live_board/v2/content_items/${contentItemId}/downloads`, {
    item_id: itemId,
    source_type: sourceType
  }).then(response => response.data)
}
