import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedPtBR = shared['pt-BR']

const ptBR = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedPtBR.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Criado por'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Olá %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: 'Clique em "ENVIAR" e entraremos em contato com você em breve.',
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Não é %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedPtBR.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedPtBR.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedPtBR.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedPtBR.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedPtBR.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Preencha os campos obrigatórios',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedPtBR.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Preencha os campos obrigatórios'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedPtBR.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedPtBR.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Sua mensagem',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedPtBR.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Compartilhe esta página usando'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Com quem gostaria de compartilhar?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedPtBR.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedPtBR.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedPtBR.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedPtBR.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedPtBR.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedPtBR.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedPtBR.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'compartilhe@email.aqui',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Dados do remetente'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedPtBR.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Mais visto'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Arquivo',
    [TRANSLATION_VALUE_KEYS.image]: 'Imagem',
    [TRANSLATION_VALUE_KEYS.link]: 'Link',
    [TRANSLATION_VALUE_KEYS.video]: 'Vídeo',
    [TRANSLATION_VALUE_KEYS.article]: 'Artigo'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Pesquisar'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Início'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Digite a senha',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'A senha está incorreta. Por favor, tente novamente',
    [TRANSLATION_VALUE_KEYS.go]: 'Ir',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Digite a senha aqui',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Ver conteúdo'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedPtBR.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedPtBR.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Abrir em nova aba',
    [TRANSLATION_VALUE_KEYS.like]: 'Curtir',
    [TRANSLATION_VALUE_KEYS.download]: 'Baixar'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Conteúdos'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedPtBR.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedPtBR.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Email',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedPtBR.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedPtBR.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedPtBR.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Mensagem',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedPtBR.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedPtBR.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedPtBR.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedPtBR.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedPtBR.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedPtBR.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Digite sua mensagem aqui...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Mais',
    [TRANSLATION_VALUE_KEYS.less]: 'Menos'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Obrigada'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Esta página requer que você confirme seu endereço de e-mail',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Insira seu e-mail para solicitar um código de confirmação',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Insira seu e-mail',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Continuar para a página',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Enviar um novo código',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Novo código enviado',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'O código de confirmação foi enviado para você. Verifique se há um e-mail de',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Observe que o e-mail pode estar em sua pasta de promoções, atualizações ou spam.',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Este endereço de e-mail não está autorizado a visualizar esta página',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Lamentamos que você esteja tendo problemas para acessar esta página',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Contate o representante da sua empresa para obter acesso',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Insira seu código de confirmação',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Digite o código aqui',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Código incorreto',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Ocorreu um erro',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedPtBR.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedPtBR.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedPtBR.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedPtBR.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Esta página é protegida por senha',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Digite a senha abaixo',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Digite a senha aqui',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Confirmar senha',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Senha incorreta'
  }
}

export default ptBR
