import { axios } from 'shared/services/axios'
import { default as axiosService } from 'axios'

const SITENAME = 'Folloze Microsites'
const DOMAIN = '.microsoft.com'

export function setCookiesConsent(boardId, leadId, options) {
  return axios.post(`/live_board/v1/boards/${boardId}/cookies_consents`,{
    lead_id: leadId,
    consent_origin: options.consentOrigin,
    iso_code: options.isoCode
  })
  .then(response => response.data)
}

export function fetchMsProviderPrivacyData({locale, country}) {
  return axiosService.get(`https://uhf.microsoft.com/${locale}/shell/api/mscc`, {
    params: {
      sitename: SITENAME,
      domain: DOMAIN,
      country
    }
  })
  .then(response => response.data)
}