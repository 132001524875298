const CLOUDINARY_VIDEO = "../../flz-3rd-party/cld-video-player-1.5.1.min.js"

const findScript = scriptSrc => document.querySelector(`script[src="${scriptSrc}"]`)
const mountScript = (root, script) => root.appendChild(script)
const findCloudinaryScript = () => findScript(CLOUDINARY_VIDEO)
const isCloudinaryScriptMounted = () => !!findCloudinaryScript()
const setupCloudinaryVideo = (script, res, rej) => {
  const configuration = {
    src: CLOUDINARY_VIDEO,
    onload: () => res(),
    onerror: (e) => rej(e)
  }

  return Object.assign(script, configuration)
}

export const loadCloudinaryScript = () => {
  return new Promise((res, rej) => {
    if (isCloudinaryScriptMounted()) {
      res()
    } else {
      const script = document.createElement('script')
      setupCloudinaryVideo(script, res, rej)
      mountScript(document.head, script)
    }
  })
}
