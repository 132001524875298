import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedJa = shared.ja

const jaJA = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedJa.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: '作成者'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: '%{leadName} こんにちは',
    [TRANSLATION_VALUE_KEYS.clickSend]: '「送信」をクリックしてください。すぐにお返事を差し上げます。',
    [TRANSLATION_VALUE_KEYS.notLeadName]: '%{leadName}様ではありませんか？'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedJa.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedJa.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedJa.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedJa.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedJa.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: '必須項目を入力してください',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedJa.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: '必須項目を入力してください'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedJa.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedJa.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'メッセージ',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedJa.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'このページを共有する：'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'このページを共有する相手'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedJa.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedJa.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedJa.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedJa.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedJa.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedJa.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedJa.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'share@email.here',
    [TRANSLATION_VALUE_KEYS.senderDetails]: '送信者情報'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedJa.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: '最も人気'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'ファイル',
    [TRANSLATION_VALUE_KEYS.image]: '画像',
    [TRANSLATION_VALUE_KEYS.link]: 'リンク',
    [TRANSLATION_VALUE_KEYS.video]: '動画',
    [TRANSLATION_VALUE_KEYS.article]: '記事'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: '検索'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'ホーム'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'パスワードを入力してください。',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'パスワードが正しくありません。もう一度入力してください。',
    [TRANSLATION_VALUE_KEYS.go]: 'GO',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'パスワードを入力してください',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'コンテンツを見る'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedJa.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedJa.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: '新しいタブで開く',
    [TRANSLATION_VALUE_KEYS.like]: 'お気に入り',
    [TRANSLATION_VALUE_KEYS.download]: 'ダウンロード'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'コンテンツ'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedJa.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedJa.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'E-mail',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedJa.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedJa.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedJa.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: '宛先',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedJa.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedJa.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedJa.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedJa.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedJa.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedJa.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'メッセージを入力してください'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'さらに表示',
    [TRANSLATION_VALUE_KEYS.less]: '非表示にする'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'ありがとうございました'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'このページは、メールアドレスの確認が必要です',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'メールアドレスを入力して確認コードをリクエストしてください',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'メールアドレスを入力してください',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'ページに進む',
    [TRANSLATION_VALUE_KEYS.sendCode]: '新しいコードを送信',
    [TRANSLATION_VALUE_KEYS.codeSent]: '新しいコードが送信されました',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: '確認コードが送信されました。',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'からのメールを確認してください。メールが届かない場合は、プロモーション、最新情報、またはスパムフォルダもご確認ください。',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'このメールアドレスはこのページの閲覧が承認されていません',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'このページにアクセスできず、申し訳ございません',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'アクセスするには、勤務先の担当者の方にお問い合わせください',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: '確認コードを入力してください',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'ここにコードを入力',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: '不正なコード',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'エラーが発生しました',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedJa.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedJa.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedJa.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedJa.code

  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'このページはパスワードで保護されています',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: '下にパスワードを入力してください',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'ここにパスワードを入力してください',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'パスワードの確認',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'パスワードが正しくありません'
  }
}

export default jaJA
