import * as api from 'LiveBoard/api/campaignElements'
import get from 'lodash/get'
import { CAMPAIGN_ELEMENTS_TYPES as TYPES } from 'shared/constants'

const FETCH_CAMPAIGN_ELEMENT_SUCCESS = 'campaignElements/FETCH_CAMPAIGN_ELEMENT_SUCCESS'

export function fetchPrivacyMessage(boardId, elementId) {
  return fetchCampaignElement(boardId, elementId, TYPES.privacyMessage)
}

export function fetchCampaignElement(boardId, elementId, elementType) {
  return function (dispatch) {
    return api.fetchCampaignElement(boardId, elementId, elementType)
      .then(data  => {
        dispatch({
          type: FETCH_CAMPAIGN_ELEMENT_SUCCESS,
          elementType,
          data
        })

        return data
      })
  }
}

const initialState = {}

export function prepareInitialState() {
  return initialState
}

export default function campaignElements(state = initialState, action) {
  switch(action.type) {
    case FETCH_CAMPAIGN_ELEMENT_SUCCESS:
      return {
        ...state,
        [action.elementType]: action.data
      }
    default: {
      return state
    }
  }
}
