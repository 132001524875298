import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedDeDE = shared['de-DE']

const deDE = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedDeDE.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Kreiert von'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Hallo %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: 'Klicken Sie einfach auf „ABSENDEN“ und wir werden Ihnen in Kürze antworten.',
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Nicht %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedDeDE.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedDeDE.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedDeDE.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedDeDE.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedDeDE.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Bitte füllen Sie die Pflichtfelder aus',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedDeDE.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Bitte füllen Sie die Pflichtfelder aus',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedDeDE.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedDeDE.email
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedDeDE.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedDeDE.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Ihre Nachricht',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedDeDE.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Teilen Sie diese Seite'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Mit wem wollen Sie diese Seite teilen?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedDeDE.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedDeDE.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedDeDE.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedDeDE.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedDeDE.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedDeDE.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedDeDE.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'zumteilen@email.hier',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Senderangaben'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedDeDE.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Meistgesehen'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Datei',
    [TRANSLATION_VALUE_KEYS.image]: 'Bild',
    [TRANSLATION_VALUE_KEYS.link]: 'Link',
    [TRANSLATION_VALUE_KEYS.video]: 'Video',
    [TRANSLATION_VALUE_KEYS.article]: 'Artikel'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Suche'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Zuhause'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Bitte tragen Sie das Passwort ein',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'Das Passwort ist falsch. Bitte versuchen Sie es erneut',
    [TRANSLATION_VALUE_KEYS.go]: 'Los',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Passwort hier eintragen',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Inhalt anzeigen'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedDeDE.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedDeDE.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'In neuem Tab öffnen',
    [TRANSLATION_VALUE_KEYS.like]: 'Mögen',
    [TRANSLATION_VALUE_KEYS.download]: 'Herunterladen'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Inhalt'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedDeDE.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedDeDE.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Email',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedDeDE.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedDeDE.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedDeDE.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Ihre Nachricht',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedDeDE.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedDeDE.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedDeDE.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedDeDE.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedDeDE.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedDeDE.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Tragen Sie Ihre Nachricht hier ein...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Mehr',
    [TRANSLATION_VALUE_KEYS.less]: 'Weniger'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Vielen Dank'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Auf dieser Seite ist eine Bestätigung Ihrer E-Mail-Adresse erforderlich',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Geben Sie Ihre E-Mail-Adresse ein, um einen Bestätigungscode anzufordern',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Geben Sie Ihre E-Mail-Adresse ein',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Weiter zur Seite',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Neuen Code senden',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Neuer Code gesendet',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Der Bestätigungscode wurde Ihnen zugesandt. Achten Sie bitte auf eine E-Mail von <>. Hinweis: Die E-Mail könnte sich in Ihrem Werbe-, Update- oder Spam-Ordner befinden.',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Diese E-Mail-Adresse ist nicht für die Ansicht dieser Seite berechtigt',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Leider haben Sie Schwierigkeiten, diese Seite aufzurufen',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Wenden Sie sich bitte an Ihren Unternehmensvertreter, um Zugriff zu erhalten',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Geben Sie Ihren Bestätigungscode ein',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Code hier eingeben',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Falscher Code',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Ein Fehler ist aufgetreten',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedDeDE.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedDeDE.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedDeDE.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedDeDE.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Diese Seite ist passwortgeschützt',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Geben Sie bitte das Passwort weiter unten ein',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Geben Sie das Passwort hier ein',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Passwort bestätigen',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Falsches Passwort'
  }
}

export default deDE
