import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from 'shared/customHooks'
import { Outlet, useNavigate, useLocation, useParams } from 'react-router-dom'
import { closeGreeting } from 'LiveBoard/modules/greeting'
import { BodyStyleContainer } from 'LiveBoard/components/shared/BodyStyle'
import SessionContainer from 'LiveBoard/containers/SessionContainer'
import { PingContainer } from 'LiveBoard/containers/PingContainer'
import SphereItemDurationContainer from 'LiveBoard/containers/SphereItemDurationContainer'
import CampaignAnalyticsContainer from 'LiveBoard/containers/CampaignAnalyticsContainer'
import EmbeddedBoardContainer from 'LiveBoard/containers/EmbeddedBoardContainer'
import { trackViewedBoard } from 'LiveBoard/modules/board'
import { updateInvitationUsed } from 'LiveBoard/api/invitationWrappers'
import { fetchHasItems } from 'LiveBoard/modules/itemsView'
import { fetchBoard } from 'LiveBoard/modules/board'
import { fetchCampaign } from 'LiveBoard/modules/campaign'
import { validateSession } from 'LiveBoard/modules/session'
import { fetchOrganizationSettings } from 'LiveBoard/modules/organizationSettings'
import { fetchPresenter } from 'LiveBoard/modules/presenter'
import { fetchLead } from 'LiveBoard/modules/lead'
import { fetchPrivacyMessage} from 'LiveBoard/modules/campaignElements'
import { load } from 'LiveBoard/componentLoader'
import { initializeCookiesConsent } from 'LiveBoard/modules/cookiesConsent'
import { queryToParamsObject, paramsToQueryString } from 'shared/helpers/UrlHelper'
import {
  PERSONAL_DETAILS_PARAMS, TOKEN_PARAM,
  CAMPAIGN_CHANGE_COOKIE_NAME, HOME_ROUTE_CATEGORY_ID
} from 'LiveBoard/config/constants'
import { ItemViewerModalContainer } from 'LiveBoard/components/shared/ItemViewerModal'
import { CampaignTransition } from 'LiveBoard/components/shared/CampaignTransition'
import { getCookie, expireCookie } from 'shared/helper'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import { submitLinkCta } from 'LiveBoard/modules/cta'
import { CTA_AREAS, LINK_TYPES } from 'LiveBoard/components/shared/CallToAction/constants'
import RoutesService from 'LiveBoard/services/routes'
import GUID from 'common/helpers/GUID'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import get from 'lodash/get'

const LiveBoard = load('LiveBoard')
const GUID_KEY = GUID.keys.timeSpent
const { EventTypes, dispatch: dispatchPublicEvent } = PublicEventsDispatcher()

const LiveBoardContainer = props => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const currentBoard = useSelector(state => state.board)
  const lead = useSelector(state => state.lead)
  const presenter = useSelector(state => state.presenter)
  const campaign = useSelector(state => state.campaign)
  const isItemView = useSelector(state => state.itemViewer.isActive)
  const isGreetingOpen = useSelector(state => state.greeting.isOpen)
  const isGatingFormOpened = useSelector(state => state.itemViewer.isGatingFormOpened)
  const isClassicItemView = useSelector(state => state.itemViewer.viewMode === 'classic')
  const changeCampaignSuccessMessage = useRef('')
  const actions = useActions({
    closeGreeting, trackViewedBoard, fetchBoard,
    fetchCampaign, fetchPresenter, fetchLead,
    initializeCookiesConsent, fetchOrganizationSettings,
    fetchHasItems, validateSession, fetchPrivacyMessage,
    submitLinkCta
  }, [])

  useEffect(() => {
    GUID.create(GUID_KEY) //need to make sure we're using it wherever is needed!!!
    const navigateTo = changeRouteParams()
    if (navigateTo) { navigate(navigateTo, { replace: true })}

    actions.fetchLead()
    actions.fetchBoard(params.boardSlug)
      .then(board => {
        fetchBoardPresenter(board.id)

        Promise.all([
          actions.fetchCampaign({ boardId: board.id }),
          actions.fetchOrganizationSettings(board.id),
          actions.fetchPrivacyMessage(board.id, get(board.privacy, 'element_id'))
        ].filter(func => func))
          .then(([campaign, organizationSettings, privacyMessage]) => {
            actions.initializeCookiesConsent(board, campaign, organizationSettings, privacyMessage)
            fetchPendingPersonalizationCampaign(campaign)
          })

        validateLeadAndPublishActivity(board.id)
      })

    openItemViewerOnLanding()

    window.addEventListener('popstate', handleBrowserBackForwardActions)

    return () => {
      window.removeEventListener('popstate', handleBrowserBackForwardActions)
    }
  }, [])

  useEffect(() => {
    actions.fetchHasItems()
  }, [lead.id])

  useEffect(() => {
    if (campaign.isChanged) {
      changeCampaignSuccessMessage.current = campaign.successMessage
      redirectOnCampaignChange()
      // timeout to fetching to enable a clean transition without new rendering in the back
      setTimeout(() => {
        Promise.all([
          actions.fetchCampaign({ boardId: campaign.board_id, forceFetch: true }),
          fetchBoardPresenter(campaign.board_id, true)
        ])
          .then(fetchPendingPersonalizationCampaign)
      }, 3000)
    }
  }, [campaign.isChanged])

  useEffect(() => {
    dispatchPageView()
  }, [location.pathname, location.search, location.hash])

  useEffect(() => {
    window.addEventListener(EventTypes.linkCtaClick, handleLinkCtaClick)
    return () => {
      window.removeEventListener(EventTypes.linkCtaClick, handleLinkCtaClick)
    }
  }, [])

  const handleLinkCtaClick = (e) => {
    const cta = get(e, 'detail')
    const area = cta.area && Object.keys(CTA_AREAS).find(area => CTA_AREAS[area] === cta.area)

    actions.submitLinkCta({
      cta: {
        area: area ? cta.area : null,
        label: cta.label || null
      },
      email: get(lead, 'email', null),
      type: LINK_TYPES.inline,
      link: cta.link || null
    })
  }


  const handleBrowserBackForwardActions = () => {
    openItemViewerOnLanding(window.location.pathname)
  }

  const openItemViewerOnLanding = (path=location.pathname) => {
    const urlItemMatch = RoutesService.matchItemPath(path)

    if (urlItemMatch) {
      openItemViewer(urlItemMatch.params, true)
    } else {
      closeItemViewer()
    }
  }

  const closeItemViewer = () => {
    dispatchPublicEvent(EventTypes.closeItemViewer)
  }

  const openItemViewer = (urlParams, isLanding) => {
    dispatchPublicEvent(EventTypes.openItemViewer, {
      item: {
        id: urlParams.itemId,
        slug: urlParams.itemSlug
      },
      category: {
        id: urlParams.categoryId,
        slug: urlParams.categorySlug
      },
      queryString: '',
      options: { isLanding }
    })
  }

  const fetchBoardPresenter = (boardId, forceFetch = false) => {
    const searchToken = new URLSearchParams(location.search).get(TOKEN_PARAM)

    actions.fetchPresenter(boardId, searchToken, forceFetch)
  }

  const fetchPendingPersonalizationCampaign = (campaign) => {
    if (campaign.pending_personalization) {
      actions.fetchCampaign({ boardId: campaign.board_id, forceFetch: true })
    }
  }

  const shouldCleanCategorySlug = (categoryPathParams) => {
    const showHome = get(campaign, "items.show_home", true)
    return !!categoryPathParams &&
      categoryPathParams.categorySlug === HOME_ROUTE_CATEGORY_ID &&
      !showHome
  }

  const editRedirectPath = (redirectPath) => {
    const categoryPathParams = get(RoutesService.matchCategoryPath(redirectPath), "params", null)
    return shouldCleanCategorySlug(categoryPathParams) ? "" : redirectPath
  }

  const redirectOnCampaignChange = () => {
    const cookieName = `${CAMPAIGN_CHANGE_COOKIE_NAME}-${campaign.board_id}`
    const campaignChangeCookie = getCookie(cookieName)

    if (campaignChangeCookie) {
      const redirectPath = editRedirectPath(campaignChangeCookie) && campaignChangeCookie
      navigate(redirectPath + location.search)
      openItemViewerOnLanding(redirectPath)
      expireCookie(cookieName)
    }
  }

  const changeRouteParams = () => {
    const { pathname, search } = location
    const token = FollozeState.initialState.token
    const searchObject = queryToParamsObject(search)

    if (!isEmpty(pick(searchObject, PERSONAL_DETAILS_PARAMS))) {
      let params = omit(searchObject, [...PERSONAL_DETAILS_PARAMS, TOKEN_PARAM])

      if(!!token) {
        params.token = token
      }
      const queryString = paramsToQueryString(params)

      return `${pathname}${queryString}`
    }
  }

  const dispatchPageView = () => {
    dispatchPublicEvent(
      EventTypes.pageView, {
        location: window.location.href,
        lead,
        campaignSettings: {
          ga: get(currentBoard.integrations, 'ga'),
          munchkin: get(currentBoard.integrations, ['marketo', 'munchkin']),
          eloqua: get(currentBoard.integrations, 'eloqua')
        }
      })
  }

  const validateLeadAndPublishActivity = (boardId) => {
    actions.validateSession()
      .then(() => {
        actions.trackViewedBoard(boardId)

        const searchParams = new URLSearchParams(location.search)

        if (searchParams.has(TOKEN_PARAM)) {
          updateInvitationUsed(searchParams.get(TOKEN_PARAM))
        }
      })
  }

  if (isEmpty(lead) || !campaign.id) {
    return null
  }
  else {
    return (
      <>
        <LiveBoard
          key={campaign.id}
          campaign={campaign}
          lead={lead}
          presenter={presenter}
          isItemView={isItemView}
          isGreetingOpen={isGreetingOpen}
          onGreetingCardClose={actions.closeGreeting}
          isGatingFormOpened={isGatingFormOpened}
          isClassicItemView={isClassicItemView}
          allowEmbedding={currentBoard.allow_embedding}>
          <Outlet />
        </LiveBoard>
        <CampaignTransition
          shouldTransition={campaign.isChanged}
          themeColor={campaign.themeColorHex}
          successMessage={changeCampaignSuccessMessage.current || campaign.successMessage} />
        <ItemViewerModalContainer />
        <BodyStyleContainer />
        <SessionContainer />
        <PingContainer />
        <SphereItemDurationContainer />
        <CampaignAnalyticsContainer />
        {currentBoard.allow_embedding && <EmbeddedBoardContainer />}
      </>
    )
  }
}

export default LiveBoardContainer
