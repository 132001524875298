import MsProvider from './MsProvider'
import AppProvider from './AppProvider'

export const COOKIE_CONSENT_TYPES = {
  app: AppProvider,
  ms: MsProvider
}

export const COOKIE_CONSENT_DATA_TYPES = {
  byLocation: 'byLocation'
}

export const NO_CONSENT_NEEDED = false
export const CONSENT_NEEDED = true