import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedSvSE = shared.sv

const svSE = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedSvSE.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Skapat av'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Hej %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: 'Klicka bara på ‘SKICKA” så hör vi av oss till dig inom kort.',
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Inte %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedSvSE.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedSvSE.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedSvSE.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedSvSE.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedSvSE.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Vänligen fyll i obligatoriska fält',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedSvSE.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Vänligen fyll i obligatoriska fält'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedSvSE.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedSvSE.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Ditt meddelande',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedSvSE.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Dela denna sida med'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Vem vill du dela den med?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedSvSE.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedSvSE.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedSvSE.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedSvSE.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedSvSE.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedSvSE.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedSvSE.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'dela@e-post.här',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Avsändaruppgifter'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedSvSE.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Mest visade'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Fil',
    [TRANSLATION_VALUE_KEYS.image]: 'Bild',
    [TRANSLATION_VALUE_KEYS.link]: 'Länk',
    [TRANSLATION_VALUE_KEYS.video]: 'Video',
    [TRANSLATION_VALUE_KEYS.article]: 'Artikel'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Sök'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Hem'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Vänligen ange lösenord',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'Lösenordet är felaktigt. Vänligen försök igen',
    [TRANSLATION_VALUE_KEYS.go]: 'GÅ',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Ange lösenord här',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Visa innehåll'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedSvSE.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedSvSE.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Öppna i ny flik',
    [TRANSLATION_VALUE_KEYS.like]: 'Gilla',
    [TRANSLATION_VALUE_KEYS.download]: 'Ladda ned'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Innehåll'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedSvSE.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedSvSE.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'E-post',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedSvSE.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedSvSE.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedSvSE.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Meddelande',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedSvSE.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedSvSE.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedSvSE.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedSvSE.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedSvSE.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedSvSE.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Skriv ditt meddelande här...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Mer',
    [TRANSLATION_VALUE_KEYS.less]: 'Mindre'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Tack'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Denna sida kräver att du verifierar din e-postadress',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Ange din e-postadress för att begära en bekräftelsekod',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Ange din e-post',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Fortsätt till sidan',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Skicka en ny kod',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Ny kod skickad',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Bekräftelsekoden har skickats till dig. Vänligen kolla efter ett e-postmeddelande från',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Notera att e-postmeddelandet kanske finns i din kampanj-, uppdateringar- eller skräppostmapp.',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Denna e-postadress är inte behörig att visa denna sida',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Tyvärr har du problem med att få åtkomst till denna sida',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Vänligen kolla med din företagsrepresentant för att få åtkomst',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Ange din bekräftelsekod',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Skriv koden här',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Felaktig kod',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Ett fel uppstod',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedSvSE.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedSvSE.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedSvSE.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedSvSE.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Denna sida är lösenordsskyddad',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Vänligen ange lösenordet nedan',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Ange lösenordet här',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Verifiera lösenord',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Fel lösenord'
  }

}

export default svSE
