import * as liveEventApi from 'LiveBoard/api/liveEvent'
import isEmpty from 'lodash/isEmpty'

const FETCH_EVENT_URLS_SUCCESS = 'liveEvent/FETCH_EVENT_URLS_SUCCESS'
const SET_VIEW = 'liveEvent/SET_VIEW'

export const VIEWS = {
  preMeeting: 'preMeeting',
  meeting: 'meeting',
  postMeeting: 'postMeeting',
  leftMeeting: 'leftMeeting',
  noEmbeddedMeeting: 'noEmbeddedMeeting'
}

function api(options = {}) {
  return !!options.getApi ? options.getApi() : liveEventApi
}

export function fetchEventUrls(boardId) {
  return function(dispatch, getState, options) {
    api(options).fetchEventUrls(boardId)
      .then((eventUrls) => {
        dispatch({
          type: FETCH_EVENT_URLS_SUCCESS,
          eventUrls
        })
      })
  }
}

export function updateView(view) {
  return (dispatch) => {
    dispatch({
      type: SET_VIEW,
      view
    })
  }
}

const initialState = {
  isActive: false,
  view: null
}

export function prepareInitialState({ live_event }) {
  if (isEmpty(live_event)) {
    return initialState
  }

  return live_event
}

export default function organizationSettings(state = initialState, action) {
  switch(action.type) {
    case FETCH_EVENT_URLS_SUCCESS:
      return {
        ...state,
        eventUrls: action.eventUrls
      }
    case SET_VIEW:
      return {
        ...state,
        view: action.view,
        isActive: action.view == VIEWS.meeting
      }
    default:
      return state
  }
}
