__webpack_public_path__ = window.FollozeState.envConfig.FOLLOZE_CLIENT_PATH + '/'
import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'
import { setOptions as setAxiosOptions } from 'shared/services/axios'
import { FollozeApi } from './services'

import store from './store'
import Root from './Root'

window.FollozeApi = FollozeApi

setAxiosOptions({
  getGuid: () => store.getState().session.guid
})

const honeybadger = Honeybadger.configure({
  api_key: FollozeState.envConfig.HONEYBADGER_API_KEY,
  environment: FollozeState.envConfig.NODE_ENV,
  onerror: false // disables errors outside of ErrorBoundry
})

Honeybadger.beforeNotify(() => false) // Disable error notifications until we'll fix source maps

ReactDOM.render(
  <ErrorBoundary honeybadger={honeybadger}>
    <AppContainer>
      <Root store={store} />
    </AppContainer>
  </ErrorBoundary>,
  document.getElementById('app')
)

if (module.hot) {
  module.hot.accept('./Root', () => {
    const NewRoot = require('./Root').default

    ReactDOM.render(
      <ErrorBoundary honeybadger={honeybadger}>
        <AppContainer>
          <NewRoot store={store}/>
        </AppContainer>
      </ErrorBoundary>,
      document.getElementById('app')
    )
  })

  module.hot.accept("./rootReducer", () =>
    store.replaceReducer(require("./rootReducer").default)
  )
}