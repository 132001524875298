import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import VisibilityService from 'common/services/VisibilityService'
import IdleService from 'common/services/IdleService'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import { createSession, setSessionStatus } from 'LiveBoard/modules/session'
import { trackViewedBoard } from 'LiveBoard/modules/board'
import { trackItemViewed } from 'LiveBoard/modules/items'
import { stopTrackingForSession } from 'LiveBoard/modules/lead'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

const HIDDEN_TIME_FOR_IDLE = 1000 * 60 * 2 // 2 minutes
const SESSION_STORAGE_KEY = 'is_initial_session_used'
const follozeEventTypes = PublicEventsDispatcher().EventTypes

class SessionContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isIdle: false,
      isHidden: false,
      hiddenAt: null
    }

    this.visibilityService = new VisibilityService()
    this.idleService = new IdleService()
  }

  componentDidMount() {
    this.idleService.onChange((state) => {
      this.setState({ isIdle: state })
    })

    this.visibilityService.onChange((state) => {
      this.setState((prevState) => {
        return({
          isHidden: state,
          hiddenAt: state ? Date.now() : prevState.hiddenAt
        })
      })
    })

    window.addEventListener(follozeEventTypes.stopTrackingForVisit, this.hadleStopTrackingCurrentLead)
  }

  componentDidUpdate(prevProps, prevState) {
    if(!isEqual(prevState.isIdle, this.state.isIdle)) {
      this.handleIdleChanged()
    }
    else if(!isEqual(prevState.isHidden, this.state.isHidden)) {
      this.handleHiddenChanged()
    }
    else if(
      !isEqual(prevProps.guid, this.props.guid) &&
      (prevProps.isActive != null)
      //ensure it will apply only when new session is created after idle time
      //in case a new session is created after validating session, shouldnt get to this condition
      //in order to avoid duplicate content tracking
    ) {
      this.trackContentView()
    }
  }

  componentWillUnmount() {
    this.idleService.onChange(null)
    this.visibilityService.onChange(null)
    window.removeEventListener(follozeEventTypes.stopTrackingForVisit, this.hadleStopTrackingCurrentLead)
  }

  handleIdleChanged = () => {
    const isActive = this.isSessionActive()
    this.handleUpdateSessionStatus({isActive: isActive, shouldCreateSession: isActive})
  }

  handleHiddenChanged = () => {
    let shouldCreateSession = false
    const isActive = this.isSessionActive()

    if (!this.state.isHidden) {
      shouldCreateSession = isActive && this.isTabHiddenIdle()
      this.setState({ hiddenAt: null })
    }
    this.handleUpdateSessionStatus({isActive: isActive, shouldCreateSession: shouldCreateSession})
  }

  isSessionActive = () => {
    return !this.state.isIdle && !this.state.isHidden
  }

  isTabHiddenIdle = () => {
    return ( Date.now() - this.state.hiddenAt ) >= HIDDEN_TIME_FOR_IDLE
  }

  handleUpdateSessionStatus = ({ isActive, shouldCreateSession }) => {
    if (shouldCreateSession) {
    //in case user return to tab after the defined idle time or after mouse move
      this.props.createSession()
    }
    else {
      this.props.setSessionStatus({ isActive })
    }
  }

  trackContentView = () => {
    this.props.trackViewedBoard(this.props.boardId)
    !!this.props.currentItemId && this.props.trackItemViewed(this.props.currentItemId)
  }

  hadleStopTrackingCurrentLead = () => {
    this.props.stopTrackingForSession()
  }

  render() {
    return null
  }
}

SessionContainer.propTypes = {
  guid: PropTypes.string.isRequired,
  leadId: PropTypes.number.isRequired,
  boardId: PropTypes.number.isRequired,
  isActive: PropTypes.bool
}

function mapStateToProps({ session, lead, board, itemViewer }) {
  return {
    leadId: lead.id,
    boardId: board.id,
    guid: session.guid,
    isActive: session.isActive,
    currentItemId: itemViewer.currentItemId
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createSession, setSessionStatus, trackViewedBoard,
    trackItemViewed, stopTrackingForSession
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionContainer)