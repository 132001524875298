/**
 * This service will detect if the user is idle by following these rules:
 * 1) Once a minute the service will check if the user moved his mouse
 * 2) If he did nothing happens
 * 3) If the user doesn't move his mouse for 2 minutes, idle is set to true
 * 4) The only way to escape idle mode is to click somewhere on the page
 */

const TIME_FOR_IDLE = 1000 * 60 * 10 // 10 minutes
const TIME_FOR_DETECTION = 1000 * 30 // 30 seconds

export default class IdleService {

  constructor() {
    this.body = document.body
    this.isIdle = false
    this.detectIsIdle = this.detectIsIdle.bind(this)
    this.setIdle = this.setIdle.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.setNotIdle = this.setNotIdle.bind(this)
    this.countForIdleDetect()
  }

  countForIdleDetect() {
    setTimeout(this.detectIsIdle, TIME_FOR_DETECTION)
  }

  detectIsIdle() {
    this.runningTimout = setTimeout(this.setIdle, TIME_FOR_IDLE)
    this.body.addEventListener("mousemove", this.onMouseMove)
  }

  onMouseMove() {
    this.body.removeEventListener("mousemove", this.onMouseMove)
    clearTimeout(this.runningTimout)
    this.countForIdleDetect()
  }

  setIdle() {
    this.body.removeEventListener("mousemove", this.onMouseMove)
    this.body.addEventListener("mousemove", this.setNotIdle)

    this.isIdle = true
    this.fireChange()
  }

  setNotIdle() {
    this.body.removeEventListener("mousemove", this.setNotIdle)
    this.countForIdleDetect()

    this.isIdle = false
    this.fireChange()
  }

  fireChange() {
    this.changeHandler && this.changeHandler(this.isIdle)
  }

  onChange(handler) {
    this.changeHandler = handler
  }
}
