import * as sessionApi from 'LiveBoard/api/session'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

export const CREATE_SESSION_SUCCESS = 'sessions/CREATE_SESSION_SUCCESS'
export const SET_SESSION_STATUS = 'sessions/SET_SESSION_STATUS'

export function validateSession() {
  return function(dispatch) {
    return sessionApi.validateSession()
    .then((data) => {
      const guid = get(data, 'guid')
      if (guid) {
        dispatch({
          type: CREATE_SESSION_SUCCESS,
          newGuid: guid
        })
      } else {
        dispatch({
          type: SET_SESSION_STATUS,
          isActive: true
        })
      }
    })
  }
}

export function createSession() {
  return function(dispatch) {
    return sessionApi.createSession()
    .then((data) => {
      dispatch({
        type: CREATE_SESSION_SUCCESS,
        newGuid: data.guid
      })
    })
  }
}

export function setSessionStatus({ isActive }) {
  return function(dispatch) {
    dispatch({
      type: SET_SESSION_STATUS,
      isActive
    })
  }
}

const initialState = {
  isActive: null
}

export function prepareInitialState({ session }) {
  if (isEmpty(session)) {
    return initialState
  }

  return {
    ...initialState,
    ...session
  }
}

export default function session(state = initialState, action) {
  switch(action.type) {
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        guid: action.newGuid,
        isActive: true
    }
    case SET_SESSION_STATUS:
      return {
        ...state,
        isActive: action.isActive
    }
    default:
      return state
  }
}