import Base from './Base'
import {
  COOKIE_CONSENT_DATA_TYPES, NO_CONSENT_NEEDED, CONSENT_NEEDED
} from './constants'
import { PrivacyWarningBase } from 'LiveBoard/components/shared/PrivacyWarning'
import get from 'lodash/get'

export const PRIVACY_COOKIE_NAME = 'privacywarning'
export const REGULATED_COUNTRIES_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CZ',
  'CY',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'EL',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'SK',
  'ES',
  'SE',
  'GB',
  'UK',
  'GR',
  'EU'
]

class AppProvider extends Base {
  constructor(board, campaign, privacyMessage) {
    super(board,campaign)
    this.dataType = COOKIE_CONSENT_DATA_TYPES.byLocation
    this.privacyCookieName = PRIVACY_COOKIE_NAME
    this.campaign = campaign
    this.board = board
    this.privacyMessage = privacyMessage
  }

  isConsentNeeded = () => {
    return new Promise((resolve, reject) => {
      const isConsentNeeded = this.checkConsent({
        countryCode: this.getIsoCode(),
        shouldCheckConsent: this.privacyWarningCheck,
        isRegulatedCountries: get(this.board, 'privacy.regulated_countries_only', false)
      })

      resolve(isConsentNeeded)
    })
  }

  privacyMessageProperties = () => {
    return {
      privacyMessageComponent: PrivacyWarningBase,
      privacyMessageMetadata: {
        config: {...this.board.privacy, ...this.privacyMessage},
        themeColorHex: this.campaign.themeColorHex
      }
    }
  }

  checkConsent = ({countryCode, shouldCheckConsent, isRegulatedCountries}) => {
    if (!shouldCheckConsent) {
      return NO_CONSENT_NEEDED
    }
    else if (this.hasConsentAlready()) {
      return NO_CONSENT_NEEDED
    }
    else {
      return this.isConsentNeededByCountry(countryCode, isRegulatedCountries)
    }
  }

  isConsentNeededByCountry = (countryCode, isRegulatedCountries) => {
    if (isRegulatedCountries && countryCode) {
      return REGULATED_COUNTRIES_CODES.includes(countryCode.toUpperCase())
    }
    else {
      return CONSENT_NEEDED
    }
  }

  hasConsentAlready = () => {
    const cookieData = get(this.getConsentCookie(), [this.organizationId], null)

    if (cookieData) {
      return cookieData.hasConsent
    }
  }
}

export default AppProvider