import { useEffect, useState } from 'react'
import { I18n } from 'shared/services/I18n'

export { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from 'shared/services/I18n'

export default function useTranslation(translationKey, locale) {
  const [currentLocale, setCurrentLocale] = useState(locale)

  function getTranslation(value, params) {
    return I18n(currentLocale).translate(translationKey, value, params)
  }

  useEffect(() => {
    setCurrentLocale(locale)
  }, [locale])

  return { getTranslation }
}
