import React from 'react'
import PropTypes from 'prop-types'

import './style.css.scss'

const LiveEventIndication = (props) => {

  return (
    <div className="live-event-indication" onClick={props.onClose}>
      <b>Live Event</b>
      <span className="icon-live-streaming" />
    </div>
  )
}

LiveEventIndication.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default LiveEventIndication
