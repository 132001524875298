import { useState, useEffect } from 'react'

const useFetch = (fetchFunction, params) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const abortController = new AbortController()
    const abortSignal = abortController.signal

    fetchData(params, abortSignal)

    return () => {
      abortController.abort()
    }
  }, [params])

  const fetchData = async (params, abortSignal) => {
    setIsError(false)
    setIsLoading(true)
    setData(null)

    try {
      const response = await fetchFunction(params, abortSignal)
      setData(response)
    } catch (error) {
      console.error(error)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    data,
    isLoading,
    isError,
    fetchData
  }
}

export default useFetch
