import { axios } from 'shared/services/axios'

export function fetchCurrentLead() {
  return axios.get('/live_board/v1/leads/me').then(response => response.data)
}

export function stopTrackingForSession() {
  return axios.delete('/live_board/v2/track_leads')
    .then(response => response.data)
}

export function publishLeadEvents({ contentItemId, timestamp, eventName }) {
  return axios.post('/live_board/v2/sphere/publish_lead_events', {
    content_item_id: contentItemId,
    timestamp,
    event_name: eventName
  })
}