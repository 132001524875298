import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedNbNO = shared.no

const nbNO = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedNbNO.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Laget av'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Hei %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: 'Bare klikk på «SEND», og så kontakter vi deg snart.',
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Er du ikke %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedNbNO.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedNbNO.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedNbNO.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedNbNO.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedNbNO.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Fyll ut de obligatoriske feltene',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedNbNO.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Fyll ut de obligatoriske feltene'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedNbNO.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedNbNO.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Meldingen din',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedNbNO.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Del denne siden med'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Hvem vil du dele den med?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedNbNO.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedNbNO.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedNbNO.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedNbNO.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedNbNO.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedNbNO.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedNbNO.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'del@epostadressen.her',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Om avsenderen'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedNbNO.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Mest sett'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Fil',
    [TRANSLATION_VALUE_KEYS.image]: 'Bilde',
    [TRANSLATION_VALUE_KEYS.link]: 'Lenke',
    [TRANSLATION_VALUE_KEYS.video]: 'Video',
    [TRANSLATION_VALUE_KEYS.article]: 'Artikkel'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Søk'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Startsiden'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Angi passordet',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'Feil passord. Prøv igjen',
    [TRANSLATION_VALUE_KEYS.go]: 'GÅ',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Angi passordet her',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Se innholdet'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedNbNO.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedNbNO.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Åpne i en ny fane',
    [TRANSLATION_VALUE_KEYS.like]: 'Lik',
    [TRANSLATION_VALUE_KEYS.download]: 'Last ned'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Innhold'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedNbNO.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedNbNO.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Epostadresse',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedNbNO.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedNbNO.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedNbNO.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Meldingen din',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedNbNO.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedNbNO.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedNbNO.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedNbNO.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedNbNO.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedNbNO.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Skriv meldingen din her...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Mer',
    [TRANSLATION_VALUE_KEYS.less]: 'Mindre'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Takk skal du ha'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Denne siden krever at du bekrefter e-postadressen din',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Angi e-postadressen din for å kunne be om bekreftelseskode',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Angi e-postadresse',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Fortsett til siden',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Send ny kode',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Ny kode er sendt',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Bekreftelseskoden er sendt til deg. Vennligst sjekk for en e-post fra',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: "Merk at e-posten kan havne i mappene for 'kampanjer', 'oppdateringer' eller 'spam'",
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Denne e-postadressen har ikke lov til å vise denne siden',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Vi beklager at du har problemer med å få tilgang til denne siden',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Du må kontakte bedriftsrepresentanten din for å be om tilgang',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Angi bekreftelseskoden din',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Angi koden her',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Feil kode',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Det oppsto en feil',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedNbNO.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedNbNO.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedNbNO.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedNbNO.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Denne siden er passordbeskyttet',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Du må angi passordet nedenfor',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Angi passordet her',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Bekreft passordet',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Feil passord'
  }
}

export default nbNO
