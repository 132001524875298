import { ITEM_SOURCES } from 'shared/constants'
import invert from 'lodash/invert'

export const ITEM_TYPE_CONFIG_BASE = {
  linkedin_post: () => {
    return {
      canOpenInNewTab: true
    }
  },
  facebook_video: () => {
    return {
      canOpenInNewTab: true
    }
  },
  prezi: () => {
    return {
      canOpenInNewTab: true
    }
  },
  youtube: () => {
    return {
      canOpenInNewTab: true
    }
  },
  oembed: () => {
    return {
      canOpenInNewTab: true
    }
  },
  wistia: () => {
    return {
      canOpenInNewTab: true
    }
  },
  google: () => {
    return {
      canOpenInNewTab: true
    }
  },
  link: () => {
    return {
      canOpenInNewTab: true
    }
  },
  pdf: () => {
    return {
      canOpenInNewTab: true
    }
  },
  snapshot: () => {
    return {
      canOpenInNewTab: true
    }
  },
  video: () => {
    return {
      canOpenInNewTab: true
    }
  },
  vidyard: () => {
    return {
      canOpenInNewTab: true
    }
  },
  image: () => {
    return {
      canOpenInNewTab: true
    }
  },
  brighttalk: () => {
    return {
      canOpenInNewTab: true
    }
  },
  brightcove: () => {
    return {
      canOpenInNewTab: false
    }
  },
  file: () => {
    return {
      canOpenInNewTab: false
    }
  },
  box: () => {
    return {
      canOpenInNewTab: false
    }
  },
  article: () => {
    return {
      canOpenInNewTab: false
    }
  }
}

export const getItemType = (item) => {
  const isLink = item.item_source == ITEM_SOURCES.link
  return ( isLink ? item.presented_item_type : invert(ITEM_SOURCES)[item.item_source] )
}

export const getItemTypeConfig = (item) => {
  const configEntry = getItemType(item)
  return ITEM_TYPE_CONFIG_BASE[configEntry](item)
}