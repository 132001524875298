function PublicEventsDispatcher() {
    "ngInject";

    var EventTypes = {
        ctaClick: 'Folloze.ctaClick',
        ctaSubmit: 'Folloze.ctaSubmit',
        pageView: 'Folloze.pageview',
        onConsent: 'Folloze.onConsent',
        consentGiven: 'Folloze.consentGiven',
        stopTrackingForVisit: 'Folloze.stopTrackingForVisit',
        openItemViewer: 'Folloze.openItemViewer',
        closeItemViewer: 'Folloze.closeItemViewer',
        itemsContentMounted: 'Folloze.itemsContentMounted',
        liveEventMounted: 'Folloze.liveEventMounted',
        itemViewerLoaded: 'Folloze.itemViewerLoaded',
        linkCtaClick: 'Folloze.linkCtaClick'
    };

    function dispatch(eventType, data){
        // dispatch event
        if(typeof CustomEvent !== "function") {
            return
        }

        var event = new CustomEvent(eventType, {
            bubbles: true,
            cancelable: true,
            detail: data
        });

        document.dispatchEvent(event);
    }

    return {
        EventTypes: EventTypes,
        dispatch: dispatch
    }
}

module.exports = PublicEventsDispatcher;
