import React from 'react'
import PropTypes from 'prop-types'
import { triggerClickOnEnter, ACCESSIBILITY_CLASSES, TAB_INDEXES } from 'shared/helpers/AccessibilityHelper'

import './style.css.scss'

const CloseButton = (props) => {

  return (
    <span
      className={`close-button ${props.closeIcon} ${ACCESSIBILITY_CLASSES.main}`}
      onClick={props.onClose}
      onKeyUp={triggerClickOnEnter}
      tabIndex={props.tabIndex || TAB_INDEXES.CloseButton}
      role="button"
      aria-label="close"
      style={props.themeColorHex ? {color: props.themeColorHex} : {}} />
  )
}

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  closeIcon: PropTypes.string.isRequired,
  themeColorHex: PropTypes.string
}

CloseButton.defaultProps = {
  closeIcon: 'icon-close-thick'
}

export default CloseButton
