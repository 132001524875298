import React from 'react'
import PropTypes from 'prop-types'
import { CloseButton } from 'shared/UI/CloseButton'
import { Transition } from 'react-transition-group'

import './style.css.scss'

const TRANSITION_DURATION = 300

class PrivacyWarningBase extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      height: 0
    }
  }

  componentDidMount() {
    if (this.wrapperElement) {
      const height = this.wrapperElement.clientHeight
      this.setState({ height })
    }
  }

  transitionStyles = () => {
    if (this.props.config.is_top) {
      return {
        entering: { marginTop: "0" },
        entered: { marginTop: "0"},
        exiting: { marginTop: -this.state.height },
        exited: { marginTop: -this.state.height }
      }
    }
    else {
      return {
        entering: { marginBottom: "0" },
        entered: { marginBottom: "0"},
        exiting: { marginBottom: -this.state.height },
        exited: { marginBottom: -this.state.height }
      }
    }
  }

  render() {
    const { message, link, is_top: isPrivacyTop, can_close: canClose } = this.props.config

    const defaultStyle = {
      transition: `margin ${TRANSITION_DURATION}ms ease-in-out`
    }

    const transitionStyles = this.transitionStyles()

    return (
      <Transition
        timeout={TRANSITION_DURATION}
        in={this.props.isOpen}
        unmountOnExit>
        { state => (
          <div
              className={`privacy-warning-container ${isPrivacyTop ? '' : 'bottom'}`}
              style={{
                ...defaultStyle,
                ...transitionStyles[state]
              }}
              ref={ (wrapperElement) => this.wrapperElement = wrapperElement}>
            <h2>{message}</h2>
            { link && (
              <a href={link} target="_blank" style={{ color: this.props.themeColorHex }} rel="noopener noreferrer">
                Learn More
              </a>
            )}
            { canClose && <CloseButton onClose={this.props.onClose} /> }
          </div>
        )}
      </Transition>
    )
  }
}

PrivacyWarningBase.propTypes = {
  config: PropTypes.object.isRequired,
  themeColorHex: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PrivacyWarningBase