import Folloze from 'folloze-sdk'

const FollozeService = () => {
  const initHost = ({container, url, args}) => Folloze.initHost(container, url, args)

  const getHost = () => {
    try {
      return Folloze.host
    }
    catch (e) { return }
  }

  return {
    getHost,
    initHost,
    MessageType: Folloze.MessageType
  }
}

export default FollozeService()
