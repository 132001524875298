import { axios, withPartialContent } from 'shared/services/axios'

export function fetchCategory({ categoryId, boardId, bySlug }) {
  return axios.get(`/live_board/v2/categories/${categoryId}`, {
    params: { by_slug: bySlug, board_id: boardId }
  }).then(response => response.data)
}

export const fetchCategories = withPartialContent(({ boardId }) => {
  return axios.get(`/live_board/v2/boards/${boardId}/categories`)
})
