const SVG_EXTENSION = require("shared/constants").SVG_EXTENSION,
 CLOUDINARY_VIDEO_SOURCE_TYPE = require("shared/constants").CLOUDINARY_VIDEO_SOURCE_TYPE,
 isEmpty = require("lodash/isEmpty"),
 get = require("lodash/get");
const browser = require('detect-browser')
const CloudinaryCreator = require('./CloudinaryCreator')

function MediaUtilityService() {
  "ngInject";

  const CLOUD_NAME = "folloze";
  const IMAGES_DOMAIN = 'images.folloze.com'
  const fetchSubstring = 'fetch'
  const uploadSubstring = 'upload'
  const CLOUDINARY_URL_REGEX = new RegExp(
    `(?:((http|https):)?)\/\/(${IMAGES_DOMAIN}|res.cloudinary.com\/folloze)\/(image|video).(${fetchSubstring}|${uploadSubstring})\/`
  )
  const SUPPORTED_VIDEO_FORMATS = ['mov', 'mp4', 'webm']
  const BEGINNING_OF_VIDEO = 0

  const cloudinaryConnection = new cloudinary.Cloudinary({
    cloud_name: CLOUD_NAME,
    secure: true,
    secure_distribution: IMAGES_DOMAIN,
    cname: IMAGES_DOMAIN,
    private_cdn: true
  })

  function isUrlSource(config) {
    return config.type ===  CLOUDINARY_VIDEO_SOURCE_TYPE.url
  }

  function getVideoUrl(config) {
    const url = config.value
    return isVideoSupported(url) ? url : changeFormat(url, 'mp4')
  }

  function setupCloudinaryVideoPlayer({
    videoSourceConfig,
    videoTransformation,
    startTimeOffset,
    eventMethods,
    closeVideo,
    isScheduled,
    options,
    playerId
  }) {
    let player = cloudinaryConnection.videoPlayer(playerId, {
      ...options,
      showLogo: false
    })
    const videoSource = isUrlSource(videoSourceConfig) ? getVideoUrl(videoSourceConfig) : videoSourceConfig.value

    player.source(videoSource, {
      sourceTypes: SUPPORTED_VIDEO_FORMATS,
      ...(!isEmpty(videoTransformation) && { transformation: videoTransformation })
    })
    player.videojs.poster(changeFormat(videoSource, 'jpg'))

    const isReplay = startTimeOffset === 0 || startTimeOffset > videoSourceConfig.duration
    let firstPlay = true

    player.on("play", function () {
      if (startTimeOffset > videoSourceConfig.duration && !videoSourceConfig.isOnDemand) {
        closeVideo()
      }
      else {
        if (firstPlay) {
          firstPlay = false
          isReplay ? eventMethods.onRePlay() :  eventMethods.onLivePlay()
          const playTime = startTimeOffset < videoSourceConfig.duration ? startTimeOffset : BEGINNING_OF_VIDEO
          player.currentTime(playTime)
        }
        else if (isReplay) {
          eventMethods.onRePlay()
        }
      }
    })

    player.on("ended", function () {
      if (startTimeOffset > 0 && !videoSourceConfig.isOnDemand) {
        closeVideo()
      }
    })

    player.on("pause", function () {
      if (isScheduled && !isReplay) {
        player.play()
      }
    })

    return player
  }

  function generateVideoPlayer(
    videoSourceConfig,
    playerId,
    options = {},
    videoTransformation = {},
    startTimeOffset,
    closeVideo = () => null,
    eventMethods = {
      onLivePlay: () => null,
      onRePlay: () => null,
    },
    isScheduled
    ) {
    return CloudinaryCreator.loadCloudinaryScript({
      cloud_name: CLOUD_NAME,
      secure: true,
      secure_distribution: IMAGES_DOMAIN,
      cname: IMAGES_DOMAIN,
      private_cdn: true,
      isVideo: true
    }).then(() => {
      return setupCloudinaryVideoPlayer({
        videoSourceConfig,
        videoTransformation,
        startTimeOffset,
        closeVideo,
        eventMethods,
        isScheduled,
        options,
        playerId
      })
    })
  }

  function changeFormat(url, format) {
    return url.replace(/\.[^.]+$/, `.${format}`)
  }

  function isVideoSupported(url) {
    return SUPPORTED_VIDEO_FORMATS.forEach(function(format) {
      return url.endsWith(format);
    })
  }

  function getPublicId(url) {
    return url.replace(CLOUDINARY_URL_REGEX, '')
  }

  function getFileName(url) {
    return getPublicId(url).split('.').shift() //remove file extension
  }

  function isFetchUrl(url) {
    return url.includes(`/${fetchSubstring}/`) ||
           !url.includes(`/${uploadSubstring}/`)
  }

  function getImageUrl({ image, transformation, width, height, isVideo = false }) {
    if (isEmpty(get(image, 'url'))) {
      return null
    }

    const imagePublicId = getPublicId(image.url)

    let cloudianryTransformation = []

    if (!isEmpty(get(image.transformation, 'crop'))) {
      cloudianryTransformation.push(image.transformation.crop);
    }

    if (!isEmpty(get(image.transformation, 'gravity'))) {
      cloudianryTransformation.push(image.transformation.gravity);
    }

    if (!image.url.endsWith(SVG_EXTENSION) && !isVideo) {
      const quality = get(image.transformation, 'quality')
      const isIOS = !!(browser && (browser.os === 'iOS'))

      cloudianryTransformation.push({
        ...(!isIOS && {fetchFormat: "auto"}),
        quality: `auto${quality ? `:${quality}` : ''}`,
        height: height,
        width: width,
        crop: 'fill'
      });
    }

    if (!isEmpty(transformation)) {
      cloudianryTransformation = cloudianryTransformation.concat(transformation);
    }

    let urlParams = {
      transformation: cloudianryTransformation,
      type: isFetchUrl(image.url) ? fetchSubstring : uploadSubstring
    }

    return isVideo ?
      cloudinaryConnection.video_url(imagePublicId, urlParams) :
      cloudinaryConnection.url(imagePublicId, urlParams)
  }

  function getImageUrlWithoutTransformations(image) {
    if (isEmpty(get(image, 'url'))) {
      return null
    }

    const imagePublicId = getPublicId(image.url)
    if (!imagePublicId) {
      return image.url
    }

    const urlParams = {
      type: isFetchUrl(image.url) ? fetchSubstring : uploadSubstring
    }
    return cloudinaryConnection.url(imagePublicId, urlParams)
  }

  return {
    CLOUD_NAME,
    getPublicId,
    getFileName,
    getImageUrl,
    generateVideoPlayer,
    getImageUrlWithoutTransformations
  }
}

module.exports = MediaUtilityService();
