import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { Tracker } from './middlewares'
import rootReducer from './rootReducer'
import { preloaders } from 'LiveBoard/modules'
import { preloadState } from 'shared/modules/helper'

const enhancer = compose(
  applyMiddleware(thunk, Tracker),
  window.__REDUX_DEVTOOLS_EXTENSION__ && FollozeState.envConfig.NODE_ENV !== 'production' ?
    window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
)

const store = createStore(
  rootReducer,
  preloadState(FollozeState.initialState, preloaders),
  enhancer
)

export default store