var INVITATION_SOURCES = require('common/constants/invitationSources');

function PlatformService(){

    return {
        platform: function(){
            if (/salesforce|salesloft/.test(window.location.pathname))
                return INVITATION_SOURCES.plugin
            if (/office_addin/.test(window.location.pathname))
                return INVITATION_SOURCES.outlook
            return INVITATION_SOURCES.app
        }
    }
}

module.exports = PlatformService
