import capitalize from 'lodash/capitalize'

export const substringAfterPatternLastInstance = (str, pattern) => {
  const splittedStr = str.split(pattern)
  return splittedStr.pop()
}

export const fuzzyMatch = (str, search) => {
  try {
    const pattern = search.split('').reduce((a, b) => a + '.*' + b)
    const regexp = new RegExp(pattern, 'i') // ignore case
    return regexp.test(str)
  } catch {
    return false
  }
}

export const stringMatch = (str, search) => {
  try {
    const pattern = search.replace(/\s/g, '')
    const regexp = new RegExp(pattern, 'i') // ignore case
    return regexp.test(str.replace(/\s/g, ''))
  } catch {
    return false
  }
}

// 1234 -> 1,234
export function formatNumberWithCommas(x) {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const generateRandomString = () => {
  return Math.random().toString(36).slice(2)
}

// lodash startCase func supports special chars only from v5.
export const startCaseWithSpecialChars = (string) => (
  string.replace(/\w+/g, capitalize)
)
