import { getCookie, setCookie } from 'shared/helper'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const COOKIE_NAME = 'gatingFormSubmitted'
export const SUBMIT_GATING_FORM = 'gatingForm/SUBMIT_GATING_FORM'

export const handleGatingFormSubmit = () => {
  return (dispatch, getState) => {
    const { board, itemViewer } = getState()
    const journeyItemIndex = itemViewer.currentItemIndex
    const journeyItems = itemViewer.items
    const contentItemId = get(journeyItems, `[${journeyItemIndex}].content_item_id`)
    const gatingFormCookie = getCookie(COOKIE_NAME) || {}
    gatingFormCookie[board.id] = true
    setCookie(COOKIE_NAME, gatingFormCookie)

    dispatch({
      type: SUBMIT_GATING_FORM,
      contentItemId
    })
  }
}

const initialState = { submitted: false }

export const prepareInitialState = ({ board }) => {
  if (isEmpty(board)) {
    return initialState
  }

  const gatingFormCookie = getCookie(COOKIE_NAME)

  return {
    submitted: get(gatingFormCookie, [board.id], false)
  }
}

export default function gatingForm(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_GATING_FORM:
      return { submitted: true }
    default:
      return state
  }
}
