import React from 'react'
import PropTypes from 'prop-types'
import { Button, BUTTON_SIZES ,BUTTON_TYPES } from 'shared/UI/Button'
import values from 'lodash/values'

import './style.css.scss'

class Dialog extends React.Component {
  componentDidMount() {
    const { provideDialog } = this.props
    provideDialog && provideDialog(this.dialogElement)
  }

  render() {
    return(
      <section
        style={{ borderColor: this.props.themeColor }}
        className={`dialog-container ${this.props.className}`}
        ref={ref => this.dialogElement = ref}>
        {this.props.children}
        { this.props.showButtons &&
          <div className='dialog-buttons'>
            { this.props.onCancel &&
              <Button
                className='cancel'
                size={this.props.buttonsSize}
                type={this.props.cancelButtonType}
                onClick={this.props.onCancel}>
                {this.props.cancelLabel}
              </Button>
            }
            { this.props.showCloseButton &&
              <Button
                disabled={this.props.canClose}
                size={this.props.buttonsSize}
                type={this.props.closeButtonType}
                onClick={this.props.onClose}>
                {this.props.closeLabel}
              </Button>
            }
            { this.props.onCustomButton &&
              <Button
                customStyle={this.props.customButtonStyle}
                size={this.props.buttonsSize}
                type={this.props.customButtonType}
                onClick={this.props.onCustomButton}>
                {this.props.customButtonLabel}
              </Button>
            }
          </div>
        }
      </section>
    )
  }
}

Dialog.propTypes = {
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string.isRequired,
  cancelButtonType: PropTypes.oneOf(values(BUTTON_TYPES)),
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  closeLabel: PropTypes.string.isRequired,
  closeButtonType: PropTypes.oneOf(values(BUTTON_TYPES)),
  onCustomButton: PropTypes.func,
  customButtonLabel: PropTypes.string,
  customButtonType: PropTypes.oneOf(values(BUTTON_TYPES)),
  buttonsSize: PropTypes.oneOf(values(BUTTON_SIZES)),
  showButtons: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  provideDialog: PropTypes.func,
  canClose: PropTypes.bool,
  themeColor: PropTypes.string,
  customButtonStyle: PropTypes.object
}

Dialog.defaultProps = {
  cancelLabel: "Cancel",
  closeLabel: "OK",
  buttonsSize: BUTTON_SIZES.small,
  showButtons: true,
  className: "",
  cancelButtonType: BUTTON_TYPES.secondary,
  closeButtonType: BUTTON_TYPES.primary,
  customButtonType: BUTTON_TYPES.primary,
  canClose: false,
  showCloseButton: true
}

export default Dialog