import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedEsES = shared['es-ES']

const esES = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedEsES.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Creado por'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Hola %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: "Simplemente, haz clic en 'ENVIAR' y nos pondremos en contacto contigo en breve.",
    [TRANSLATION_VALUE_KEYS.notLeadName]: '¿No eres %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedEsES.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedEsES.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedEsES.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedEsES.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedEsES.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Por favor, rellena los campos obligatorios',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedEsES.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Por favor, rellena los campos obligatorios'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedEsES.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedEsES.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Tu mensaje',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedEsES.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Comparte esta página utilizando:'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: '¿Con quién te gustaría compartirla?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedEsES.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedEsES.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedEsES.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedEsES.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedEsES.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedEsES.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedEsES.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'compartir@correoelectrónico.aquí',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Datos del remitente'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedEsES.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Más visto'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Archivo',
    [TRANSLATION_VALUE_KEYS.image]: 'Imagen',
    [TRANSLATION_VALUE_KEYS.link]: 'Enlace',
    [TRANSLATION_VALUE_KEYS.video]: 'Vídeo',
    [TRANSLATION_VALUE_KEYS.article]: 'Artículo'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Buscar'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Inicio'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Por favor, escribe la contraseña',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'La contraseña es incorrecta. Por favor, inténtalo de nuevo',
    [TRANSLATION_VALUE_KEYS.go]: 'IR',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Escribe la contraseña aquí',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Ver contenido'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedEsES.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedEsES.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Abrir en una pestaña nueva',
    [TRANSLATION_VALUE_KEYS.like]: 'Me gusta',
    [TRANSLATION_VALUE_KEYS.download]: 'Descargar'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Contenidos'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedEsES.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedEsES.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Correo electrónico',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedEsES.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedEsES.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedEsES.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Mensaje',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedEsES.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedEsES.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedEsES.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedEsES.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedEsES.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedEsES.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Escribe tu mensaje aquí'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Más',
    [TRANSLATION_VALUE_KEYS.less]: 'Menos'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Gracias'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Tiene que verificar su dirección de correo electrónico en esta página',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Introduzca su correo electrónico para solicitar un código de confirmación',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Introduzca su correo electrónico',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Ir a la página',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Enviar un código nuevo',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Se ha enviado un código nuevo',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Le hemos enviado el código de confirmación. Compruebe si ha recibido un correo electrónico de',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Tenga en cuenta que puede estar en su carpeta de promociones, actualizaciones o correo no deseado.',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'La dirección de correo electrónico no tiene permisos para ver esta página',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Sentimos que esté teniendo problemas para acceder a esta página',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Consúltelo con el representante de su empresa para obtener acceso',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Introduzca su código de confirmación',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Escriba el código aquí',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Código incorrecto',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Se ha producido un error',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedEsES.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedEsES.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedEsES.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedEsES.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Esta página está protegida con una contraseña',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Introduzca la contraseña a continuación',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Introduzca la contraseña aquí',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Verificar contraseña',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Contraseña incorrecta'
  }
}

export default esES
