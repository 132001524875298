import * as api from 'LiveBoard/api/items'
import { fetchCategory } from 'LiveBoard/api/categories'
import InbyPropagationService from 'common/services/InbyPropagationService'
import { HOME_ROUTE_CATEGORY_ID, CONTENT_SOURCE_TYPE } from 'LiveBoard/config/constants'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'

const SET_CURRENT_ITEM = 'itemViewer/SET_CURRENT_ITEM'
const SET_VIEWER_MODE = 'itemViewer/SET_VIEWER_MODE'
const FETCH_JOURNEY_ITEM = 'itemViewer/FETCH_JOURNEY_ITEM'
const FETCH_JOURNEY_ITEM_SUCCESS = 'itemViewer/FETCH_JOURNEY_ITEM_SUCCESS'
const LIKE_ITEM = 'itemViewer/LIKE_ITEM'
const CLOSE_JOURNEY = 'itemViewer/CLOSE_JOURNEY'
const SET_GATING_FORM_IS_OPENED = 'itemViewer/SET_GATING_FORM_IS_OPENED'
export const LIKE_ITEM_SUCCESS = 'itemViewer/LIKE_ITEM_SUCCESS'
export const TRACK_CLICKED_ON_NEXT_ITEM = 'itemViewer/TRACK_CLICKED_ON_NEXT_ITEM'
export const TRACK_CLICKED_ON_PREV_ITEM = 'itemViewer/TRACK_CLICKED_ON_PREV_ITEM'
export const TRACK_ITEM_DOWNLOADED = 'itemViewer/TRACK_ITEM_DOWNLOADED'
export const TRACK_ITEM_VIEW_DURATION = 'itemViewer/TRACK_ITEM_VIEW_DURATION'

const inbyPropagationService = InbyPropagationService()

export function setIsGatingFormOpened(isOpened) {
  return dispatch => {
    return dispatch({
      type: SET_GATING_FORM_IS_OPENED,
      isOpened
    })
  }
}

export function setViewerMode(viewMode) {
  return (dispatch) => {
    return dispatch({
      type: SET_VIEWER_MODE,
      viewMode
    })
  }
}

export function setCurrentItem(itemId, itemIndex, categoryId) {
  return (dispatch) => {
    return dispatch({
      type: SET_CURRENT_ITEM,
      itemId,
      itemIndex,
      categoryId
    })
  }
}

export function fetchJourneyItem({ itemId, categoryId, itemSlug, categorySlug }) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_JOURNEY_ITEM })

    if (itemSlug || categorySlug) {
      getIdsFromSlugsAndFetch({ itemSlug, categorySlug })(dispatch, getState)
    } else {
      fetchJourney(itemId, categoryId)(dispatch, getState)
    }
  }
}

function getIdsFromSlugsAndFetch({ itemSlug, categorySlug }) {
  return (dispatch, getState) => {
    const state = getState()
    const boardId = state.board.id
    const item = getItem(itemSlug, state)
    const category = getCategory(categorySlug, state, item)

    const itemPromise = isEmpty(item)
      ? api.fetchItem({ itemId: itemSlug, boardId, bySlug: true })
      : item
    const categoryPromise = isEmpty(category)
      ? fetchCategory({ categoryId: categorySlug, boardId, bySlug: true })
      : category

    Promise.all([itemPromise, categoryPromise])
      .then(([item, category]) => {
        fetchJourney(item.id, category.id)(dispatch, getState)
      })
  }
}

function getItem(slug, state) {
  // priority to itemViewer state because it has journey items
  // journey item has already its corresponding category_id
  return find(state.itemViewer.items, { slug }) ||
    find(state.items.data, { slug })
}

function getCategory(slug, state, item) {
  if (slug === HOME_ROUTE_CATEGORY_ID) {
    return { id: null }
  } else if (slug) {
    return find(state.categories.data, { slug })
  } else if (!!item && item.category_id) {
    return { id: item.category_id }
  } else { return null }
}

function fetchJourney(itemId, categoryId) {
  return (dispatch, getState) => {
    const { itemsView, presenter, board } = getState()
    const { query } = itemsView
    const presenterEmail = presenter.email

    return api.fetchJourneyItem(
      itemId,
      {
        boardId: board.id,
        query,
        categoryId: categoryId === HOME_ROUTE_CATEGORY_ID ? null : categoryId
      })
      .then((journey) => {
        return dispatch({
          type: FETCH_JOURNEY_ITEM_SUCCESS,
          ...extractJourneyParams(journey),
          categoryId,
          itemId,
          presenterEmail
        })
      })
  }
}

function extractJourneyParams(journey) {
  const journeyIndex = journey.journey_index
  return {
    items: journey.items,
    itemsCount: journey.items_count,
    nextItemIndex: journey.next_item_index,
    prevItemIndex: journey.prev_item_index,
    currentItem: journey.items[journeyIndex],
    journeyIndex
  }
}

export function closeJourney() {
  return {
    type: CLOSE_JOURNEY
  }
}

export function likeItem(item) {
  return function(dispatch) {
    dispatch({
      type: LIKE_ITEM,
      journeyIndex: item.journey_index
    })

    return api.likeItem({
      itemId: item.id,
      contentItemId: item.content_item_id,
      sourceType: CONTENT_SOURCE_TYPE.item
    })
      .then(() => {
        dispatch({
          type: LIKE_ITEM_SUCCESS,
          trackingParams: {
            item_id: item.id,
            item_name: item.name
          },
          contentItemId: item.content_item_id
        })
      })
  }
}

export function trackClickedOnNextItem() {
  return {
    type: TRACK_CLICKED_ON_NEXT_ITEM
  }
}

export function trackClickedOnPrevItem() {
  return {
    type: TRACK_CLICKED_ON_PREV_ITEM
  }
}

export function trackItemDownloaded(item) {
  return function(dispatch) {
    return api.trackItemDownloaded({
      itemId: item.id,
      contentItemId: item.content_item_id,
      sourceType: CONTENT_SOURCE_TYPE.item
    })
      .then(() => {
        dispatch({
          type: TRACK_ITEM_DOWNLOADED,
          trackingParams: {
            item_id: item.id,
            item_name: item.name
          },
          contentItemId: item.content_item_id
        })
      })
  }
}

export function trackItemViewDuration({ contentItemId, duration }) {
  return {
    type: TRACK_ITEM_VIEW_DURATION,
    contentItemId,
    duration
  }
}

export function fetchItemDownloadUrl(contentItemId) {
  return (dispatch) => {
    return api.fetchItemDownloadUrl(contentItemId)
  }
}

const initialState = {
  currentItemId: null,
  currentItemIndex: null,
  currentCategoryId: null,
  itemsCount: 0,
  items: {},
  isActive: false,
  viewMode: null,
  isGatingFormOpened: false
}

export function prepareInitialState({ journey }) {
  if (isEmpty(journey)) {
    return initialState
  }

  const journeyParams = extractJourneyParams(journey)
  const currentItem = journeyParams.items[journeyParams.journeyIndex]

  return {
    ...initialState,
    isFetching: false,
    currentItemId: currentItem.id,
    currentItemIndex: journeyParams.journeyIndex,
    itemsCount: journeyParams.itemsCount,
    items: {
      ...journeyParams.items,
      [journeyParams.journeyIndex]: {
        ...currentItem,
        nextItemIndex: journeyParams.nextItemIndex,
        prevItemIndex: journeyParams.prevItemIndex
      }
    }
  }
}

function item(state = {}, action) {
  switch (action.type) {
    case LIKE_ITEM:
      return {
        ...state,
        liked_by_user: true
      }
    case FETCH_JOURNEY_ITEM_SUCCESS:
      return {
        ...action.currentItem,
        link_url: inbyPropagationService.getItemUrl({
          inviter: action.presenterEmail,
          url: action.currentItem.link_url
        }),
        nextItemIndex: action.nextItemIndex,
        prevItemIndex: action.prevItemIndex
      }
    default:
      return state
  }
}

export default function itemViewer(state = initialState, action) {
  switch (action.type) {
    case SET_VIEWER_MODE:
      return {
        ...state,
        viewMode: action.viewMode
      }
    case SET_CURRENT_ITEM:
      return {
        ...state,
        currentItemId: +action.itemId,
        currentItemIndex: action.itemIndex,
        currentCategoryId: action.categoryId
      }
    case FETCH_JOURNEY_ITEM:
      return {
        ...state,
        isActive: true,
        isFetching: true
      }
    case FETCH_JOURNEY_ITEM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentItemId: action.itemId,
        currentItemIndex: action.journeyIndex,
        currentCategoryId: action.categoryId,
        itemsCount: action.itemsCount,
        items: {
          ...merge(state.items, action.items),
          [action.journeyIndex]: item(state.items[action.journeyIndex], action)
        }
      }
    case CLOSE_JOURNEY:
      return initialState
    case SET_GATING_FORM_IS_OPENED:
      return {
        ...state,
        isGatingFormOpened: action.isOpened
      }
    default:
      if (action.journeyIndex !== undefined) {
        return {
          ...state,
          items: {
            ...state.items,
            [action.journeyIndex]: item(state.items[action.journeyIndex], action)
          }
        }
      } else {
        return state
      }
  }
}
