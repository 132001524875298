import * as api from 'LiveBoard/api/boards'
import isEmpty from 'lodash/isEmpty'

const FETCH_BOARD_SUCCESS = 'board/FETCH_BOARD_SUCCESS'
export const TRACK_VIEWED_BOARD = 'board/TRACK_VIEWED_BOARD'

export function fetchBoard(boardSlug) {
  return function(dispatch, getState) {
    const currentBoard = getState().board

    if (isEmpty(currentBoard)) {
      return api.fetchBoard(boardSlug)
        .then((board) => {
          dispatch({
            type: FETCH_BOARD_SUCCESS,
            board
          })

          return board
        })
    }
    else {
      return Promise.resolve(currentBoard)
    }
  }
}

export function trackViewedBoard(boardId) {
  return function(dispatch) {
    api.trackLeadView(boardId)

    return dispatch({
      type: TRACK_VIEWED_BOARD
    })
  }
}

const initialState = {}

export function prepareInitialState({ board }) {
  if (isEmpty(board)) {
    return initialState
  }

  return board
}

export default function board(state = initialState, action) {
  switch(action.type) {
    case FETCH_BOARD_SUCCESS:
      return action.board
    default:
      return state
  }
}
