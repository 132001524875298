import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'shared/hocs'
import { withCookieConsent, withOutboundLink } from 'LiveBoard/hocs'
import { trackItemViewed } from 'LiveBoard/modules/items'
import { gatingForm } from 'LiveBoard/modules/gatingForm'
import {
  fetchJourneyItem, closeJourney, trackClickedOnNextItem,
  trackClickedOnPrevItem, setCurrentItem
} from 'LiveBoard/modules/itemViewer'
import { fetchCategories } from 'LiveBoard/modules/categories'
import { load } from 'LiveBoard/componentLoader'
import RoutesService from 'LiveBoard/services/routes'
import { HOME_ROUTE_CATEGORY_ID } from 'LiveBoard/config/constants'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import { getItemRoute as getItemRouteHelper } from 'LiveBoard/components/shared/itemUrlHelper'
import get from 'lodash/get'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import isEqual from 'lodash/isEqual'

const ItemViewer = load('ItemViewer/ItemViewer')
const { EventTypes, dispatch: dispatchPublicEvent } = PublicEventsDispatcher()

const DEFAULT_GATING_CONFIG = {
  show: false,
  is_mandatory: false,
  known_lead: false,
  type: "form",
  metadata: {
    form: {
      form_title: null,
      submit_label: null,
      fields: {
        name: { state: "required" },
        email: { state: "required" },
        headline: { state: "optional" },
        company: { state: "optional" },
        phone: { state: "optional" },
        note: { state: "optional" }
      }
    }
  }
}

const PLACEHOLDER_BANNER_HEIGHT = "placeholder"
const NO_BANNER_HEIGHT = "without"

class ItemViewerContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (isEmpty(this.props.categories.data)){
      this.props.fetchCategories({ boardId: this.props.board.id })
    }

    this.props.fetchJourneyItem(this.props.params)

    const currentItem = get(this, 'props.currentItem', null)
    if(currentItem) {
      this.itemViewedDispatcher(currentItem)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentItem, currentItemIndex
    } = this.props

    if(!isEqual(this.props.params, prevProps.params)) {
      this.props.fetchJourneyItem(this.props.params)
    }

    if(prevProps.currentItemIndex != currentItemIndex) {
      this.itemViewedDispatcher(currentItem)
    }
  }

  componentWillUnmount() {
    this.props.closeJourney()
    dispatchPublicEvent(EventTypes.closeItemViewer)
  }

  itemViewedDispatcher = (item) => {
    this.props.trackItemViewed(item.id)
    dispatchPublicEvent(EventTypes.itemViewerLoaded, { item })
  }

  handleNextItem = () => {
    this.props.trackClickedOnNextItem()
    this.navigateToItem(this.props.items.nextItemIndex)
  }

  handlePrevItem = () => {
    this.props.trackClickedOnPrevItem()
    this.navigateToItem(this.props.items.prevItemIndex)
  }

  navigateToItem = (itemIndex) => {
    const {
      navigate, location, onCookieConsent, items, setCurrentItem
    } = this.props
    const nextItem = items.data[itemIndex]
    if (!nextItem) {
      return
    }

    const itemRoute = this.getItemRoute(nextItem)
    onCookieConsent()
    setCurrentItem(nextItem.id, nextItem.journey_index, nextItem.category_id)

    this.props.fetchJourneyItem({
      itemId: nextItem.id,
      categoryId: nextItem.category_id
    })

    navigate(`${itemRoute}${location.search}`)
  }

  handleClickItem = (url) => {
    this.props.onCookieConsent()
    this.props.onOpenOutboundLink(url)
  }

  getItemRoute = (nextItem) => {
    const { category_id, category_slug } = nextItem
    return getItemRouteHelper(
      nextItem,
      { id: category_id || HOME_ROUTE_CATEGORY_ID,
        slug: category_slug || HOME_ROUTE_CATEGORY_ID },
      this.props.board
    )
  }

  getItemUrl = (itemIndex) => {
    const item = get(this.props.items, `data[${itemIndex}]`, null)
    if (!item) {
      return ''
    }
    return `${this.getItemRoute(item)}${this.props.location.search}`
  }

  render() {
    return (
      <ItemViewer
        items={this.props.items}
        currentItem={this.props.currentItem}
        gatingConfig={this.props.gatingConfig}
        isGated={this.props.isGated}
        themeColorHex={this.props.themeColorHex}
        contactCard={this.props.contactCard}
        isLiveEventActive={this.props.isLiveEventActive}
        header={this.props.header}
        itemsCount={this.props.itemsCount}
        bannerHeight={this.props.bannerHeight}
        lead={this.props.lead}
        onLike={this.props.onLike}
        onDownload={this.props.onDownload}
        onOpenInNewTab={this.props.onOpenInNewTab}
        onClose={this.props.onClose}
        onNextItem={this.handleNextItem}
        onPrevItem={this.handlePrevItem}
        seller={this.props.seller}
        onClickItem={this.handleClickItem}
        requireSsl={this.props.board.is_ssl}
        blockDownload={this.props.blockDownload}
        hasNavigation={this.props.itemViewerConfig.has_navigation}
        isClassicTheme={this.props.itemViewerConfig.is_classic_theme}
        bannerConfig={this.props.bannerConfig}
        headerTextColor={this.props.headerTextColorHex}
        boardId={this.props.board.id}
        isItemView={this.props.isItemView}
        isGreetingOpen={this.props.isGreetingOpen}
        isGatingFormOpened={this.props.isGatingFormOpened}
        nextItemUrl={this.getItemUrl(this.props.items.nextItemIndex)}
        prevItemUrl={this.getItemUrl(this.props.items.prevItemIndex)}
        showLikes={this.props.showLikes} />
    )
  }
}

ItemViewerContainer.propTypes = {
  items: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  currentItemId: PropTypes.number,
  currentCategoryId: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
  currentItem: PropTypes.object,
  itemsCount: PropTypes.number.isRequired,
  gatingConfig: PropTypes.object.isRequired,
  isGated: PropTypes.bool.isRequired,
  themeColorHex: PropTypes.string.isRequired,
  contactCard: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  bannerHeight: PropTypes.string,
  lead: PropTypes.object.isRequired,
  fetchJourneyItem: PropTypes.func.isRequired,
  setCurrentItem: PropTypes.func.isRequired,
  closeJourney: PropTypes.func.isRequired,
  trackClickedOnNextItem: PropTypes.func.isRequired,
  trackClickedOnPrevItem: PropTypes.func.isRequired,
  seller: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onCookieConsent: PropTypes.func.isRequired,
  onOpenOutboundLink: PropTypes.func.isRequired,
  blockDownload: PropTypes.bool,
  categories: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  bannerConfig: PropTypes.object,
  onOpenInNewTab: PropTypes.func.isRequired,
  onLike: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
}

function mapStateToProps({
  itemsView,
  board,
  campaign,
  lead,
  itemViewer,
  presenter,
  liveEvent,
  gatingForm,
  categories,
  greeting
}, ownProps) {
  const gatingConfig = merge(
    DEFAULT_GATING_CONFIG,
    campaign.items.gating,
    { text: get(campaign.items, 'gating.metadata.form.form_title', 'Gating Form') }
  )
  const { currentItemId, currentItemIndex, currentCategoryId } = itemViewer
  const {
    banner, header, themeColorHex, contact_card: contactCard, items: campaignItems
  } = campaign
  const currentItem = itemViewer.items[currentItemIndex]
  const prevItemIndex = get(currentItem, 'prevItemIndex')
  const nextItemIndex = get(currentItem, 'nextItemIndex')

  const items = {
    currentItemIndex,
    prevItemIndex,
    nextItemIndex,
    data: pick(itemViewer.items, [currentItemIndex, prevItemIndex, nextItemIndex])
  }

  const isKnownLeadGated = gatingConfig.known_lead && !gatingForm.submitted
  const isGated = gatingConfig.show && !!get(currentItem, 'is_gated', false)
    && (lead.anon_guest || isKnownLeadGated)

  const isLiveEventActive = liveEvent.isActive

  return {
    board,
    gatingConfig,
    isGated,
    items,
    currentItemId,
    currentItem,
    currentItemIndex,
    currentCategoryId,
    itemsCount: itemViewer.itemsCount,
    lead,
    themeColorHex,
    contactCard,
    isLiveEventActive,
    header,
    bannerHeight: banner.show ? banner.height : (header.show ? PLACEHOLDER_BANNER_HEIGHT : NO_BANNER_HEIGHT),
    seller: presenter,
    blockDownload: campaignItems.block_download,
    categories,
    itemViewerConfig: {
      ...ownProps.configDefaults,
      ...campaign.items.item_viewer_config
    },
    bannerConfig: banner,
    headerTextColorHex: campaign.headerTextColorHex,
    isItemView: itemViewer.isActive,
    isGreetingOpen: greeting.isOpen,
    isGatingFormOpened: itemViewer.isGatingFormOpened,
    showLikes: campaign.items.show_likes
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeJourney, trackItemViewed, trackClickedOnNextItem, trackClickedOnPrevItem,
    fetchJourneyItem, setCurrentItem, gatingForm, fetchCategories
  }, dispatch)
}

export default withCookieConsent(
  withOutboundLink(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemViewerContainer))
  )
)
