import React from 'react'
import PropTypes from 'prop-types'
import { CloseButton } from 'shared/UI/CloseButton'
import { ItemModalActionButtons } from 'LiveBoard/components/shared/ItemModalActionButtons'

const ModalHeaderActions = (props) => {
  return (
    <>
      { props.isMobile && props.isClassicTheme ?
        <>
          <CloseButton
            closeIcon="icon-chevron-left"
            onClose={props.onClose}
            themeColorHex={props.themeColorHex} />

          <ItemModalActionButtons data={props.menuActions} />
        </>
        :
        !props.isClassicTheme &&
          <CloseButton
            closeIcon="icon-close-thick"
            onClose={props.onClose} />
      }
    </>
  )
}

ModalHeaderActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isClassicTheme: PropTypes.bool,
  themeColorHex: PropTypes.string,
  menuActions: PropTypes.object
}

export default ModalHeaderActions