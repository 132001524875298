class Base {
  constructor() {
    if (this.constructor === Base) {
      throw new TypeError('Abstract class "Base" cannot be instantiated directly.');
    }
  }

  getBoardRoute(board, options) {
    throw new Error('You have to implement the method getBoardRoute!');
  }

  getCategoryRoute(category, board) {
    throw new Error('You have to implement the method getCategoryRoute!');
  }

  getItemRoute({ item, category, board }) {
    throw new Error('You have to implement the method getItemRoute!');
  }

  getNextItemRoute(item, board) {
    throw new Error('You have to implement the method getJourenyItemRoute!');
  }

  matchItemPath(path) {
    throw new Error('You have to implement the method matchItemPath!');
  }

  matchCategoryPath(path) {
    throw new Error('You have to implement the method matchCategoryPath!');
  }

  matchBoardPath(path) {
    throw new Error('You have to implement the method matchBoardPath!');
  }
}
export default Base


