const INVITATION_SOURCES = require('common/constants/invitationSources')
const PlatformService = require('common/data/PlatformService')
const axios = require('shared/services/axios').axios

const TrackingService = () => {
  const platformService = PlatformService()
  const trackingConfig = { ...FollozeState.trackingConfig }

  const getPlatform = () => {
    const platform = platformService.platform()

    if (platform == INVITATION_SOURCES.plugin) {
      return trackingConfig.platforms.plugin
    }
    else if (/office_addin/.test(window.location.pathname)) {
      return trackingConfig.platforms.outlook
    }
    else {
      return trackingConfig.platforms.app
    }
  }

  const createTrack = (event_id, properties, platform = {}, source) => {
    if (platform.id == trackingConfig.platforms.campaign.id) {
      // currently liveboard tracking disabled
      console.info("TRACK: (ignore)", event_id, properties)
      return Promise.resolve()
    }
    else {
      console.info("TRACK:", event_id, properties)
      return axios.post(`/${source}/v1/tracking`, {
        event: {
          id: event_id,
          data: properties,
          platform: (platform || getPlatform())
        }
      })
    }
  }

  return {
    track: (event_id, properties, platform) => {
      return createTrack(event_id, properties, platform, "api")
    },
    liveBoardTrack: (event_id, properties, platform) => {
      return createTrack(event_id, properties, platform, "live_board")
    },
    config: trackingConfig
  }
}

module.exports = TrackingService
