export const CTA_AREAS = {
  header: "header",
  sellerCard: "contact card",
  banner: "banner",
  promotion: "promotion",
  gating: "gating",
  widget: "widget",
  sideBySide: "sideBySide"
}

export const LINK_TYPES = {
  inline: "inline",
  newTab: "new_tab"
}

export const LINK_TARGETS = {
  [LINK_TYPES.inline]: '_self',
  [LINK_TYPES.newTab]: '_blank'
}