import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BodyStyle from './BodyStyle'

const BodyStyleContainer = ({ isModalOpen, isGreetingOpen, isItemViewerOpen, itemViewerMode }) => (
  <BodyStyle
    isModalOpen={isModalOpen}
    isGreetingOpen={isGreetingOpen}
    isItemViewerOpen={isItemViewerOpen}
    itemViewerMode={itemViewerMode} />
)

BodyStyleContainer.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isGreetingOpen: PropTypes.bool.isRequired,
  isItemViewerOpen: PropTypes.bool.isRequired,
  itemViewerMode: PropTypes.string
}

function mapStateToProps({ cta, greeting, itemViewer }) {
  return {
    isModalOpen: cta.isOpen,
    isGreetingOpen: greeting.isOpen,
    isItemViewerOpen: itemViewer.isActive,
    itemViewerMode: itemViewer.viewMode
  }
}

export default connect(mapStateToProps, null)(BodyStyleContainer)
