import TrackingService from 'common/tracking/services/TrackingService'
import * as boardActions from 'LiveBoard/modules/board'
import * as itemsActions from 'LiveBoard/modules/items'
import * as itemViewerActions from 'LiveBoard/modules/itemViewer'
import * as itemsViewActions from 'LiveBoard/modules/itemsView'
import * as gatingFormActions from 'LiveBoard/modules/gatingForm'
import { CLOSE_PRIVACY_WARNING } from 'LiveBoard/modules/cookiesConsent'
import * as leadsApi from 'LiveBoard/api/leads'
import * as ctaActions from 'LiveBoard/modules/cta'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isFunction from 'lodash/isFunction'

const instance = TrackingService()
const EVENTS = instance.config.events.lead

const ACTION_TO_EVENT = {
  [boardActions.TRACK_VIEWED_BOARD]: EVENTS.viewed_board,
  [itemsActions.TRACK_VIEWED_ITEM]: EVENTS.viewed_item,
  [itemViewerActions.TRACK_CLICKED_ON_NEXT_ITEM]: EVENTS.clicked_on_next_item,
  [itemViewerActions.TRACK_CLICKED_ON_PREV_ITEM]: EVENTS.clicked_on_previous_item,
  [itemViewerActions.LIKE_ITEM_SUCCESS]: EVENTS.liked_an_item,
  [itemViewerActions.TRACK_ITEM_DOWNLOADED]: EVENTS.downloaded_an_item,
  [CLOSE_PRIVACY_WARNING]: EVENTS.confirmed_cookie_usage,
  [ctaActions.TRACK_SHARE_CLICK]: EVENTS.clicked_on_share_button,
  [ctaActions.SHARE_BY_EMAIL_SUCCESS]: EVENTS.shared_a_campaign,
  [ctaActions.SUBMIT_CTA_SUCCESS]: EVENTS.clicked_on_cta,
  [itemsViewActions.SET_CATEGORY]: EVENTS.changed_category,
  [itemsViewActions.SET_SEARCH_QUERY]: EVENTS.searched_items
}

const RECOMMENDATION_EVENTS = [
  itemViewerActions.LIKE_ITEM_SUCCESS,
  itemViewerActions.TRACK_ITEM_DOWNLOADED,
  gatingFormActions.SUBMIT_GATING_FORM,
  itemViewerActions.TRACK_ITEM_VIEW_DURATION
]

const RECOMMENDATION_EVENT_NAME = {
  [itemViewerActions.LIKE_ITEM_SUCCESS]: 'item_like',
  [itemViewerActions.TRACK_ITEM_DOWNLOADED]: 'item_download',
  [gatingFormActions.SUBMIT_GATING_FORM]: 'filled_gating_form',
  [itemViewerActions.TRACK_ITEM_VIEW_DURATION]: (details) => {
    return `item_view_${details.duration}_sec`
  }
}

const getRecommendationEventName = (action) => {
  const eventName = RECOMMENDATION_EVENT_NAME[action.type]
  return (isFunction(eventName)) ? eventName(action) : eventName
}

const handleRecommendationEvent = (action) => {
  if (includes(RECOMMENDATION_EVENTS, action.type)) {
    leadsApi.publishLeadEvents({
      contentItemId: action.contentItemId,
      timestamp: Date.now(),
      eventName: getRecommendationEventName(action)
    })
  }
}

const Tracker = store => next => action => {
  const event = get(ACTION_TO_EVENT, action.type)

  if (event) {
    const { board } = store.getState()

    instance.liveBoardTrack(event, {
      ...action.trackingParams,
      board_id: board.id,
      board_name: board.name
    }, instance.config.platforms.campaign)
  }

  handleRecommendationEvent(action)

  next(action)
}

export default Tracker
