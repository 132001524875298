import * as geoLocationsApi from 'LiveBoard/api/geoLocations'
import * as cookiesConsentApi from 'LiveBoard/api/cookiesConsent'
import * as sessionCookiesApi from 'LiveBoard/api/sessionCookies'
import { getCookie, setCookie, deleteAllCookies } from 'shared/helper'
import cookieManagementTypes from 'common/constants/cookieManagementTypes'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import userLocale from 'shared/helpers/UserLocale'
import get from 'lodash/get'

const DATA_FETCHERS = {
  byLocation: geoLocationsApi.fetchGeoLocation
}
const follozeEventTypes = PublicEventsDispatcher().EventTypes

class Base {

  constructor(board, campaign, privacyMessage) {
    if (this.constructor === Base) {
      throw new TypeError('Abstract class "Base" cannot be instantiated directly.');
    }
    this.organizationId = board.organization_id
    this.boardId = board.id
    this.cookieManagement = board.privacy.cookie_management
    this.isExternalCookieManagement = this.cookieManagement == cookieManagementTypes.external
    this.privacyWarningCheck = get(board.privacy, 'privacy_warning_check', false)
    this._providerData = null
    this._privacyCookieName = ''
  }

  getProviderData = () => {
    return DATA_FETCHERS[this.dataType]()
  }

  get providerData() {
    return this._providerData
  }

  set providerData(newData) {
    this._providerData = newData
  }

  get privacyCookieName() {
    return this._privacyCookieName
  }

  set privacyCookieName(cookieName) {
    this._privacyCookieName = cookieName
  }

  getIsoCode() {
    return get(this.providerData,'country_code', '')
  }

  getUserLocale() {
    return userLocale()
  }

  consentData() {
    return { hasConsent: true }
  }

  setConsentCookie = () => {
    let privacyWarningCookie = getCookie(this.privacyCookieName) || {}
    privacyWarningCookie[this.organizationId] = this.consentData()
    setCookie(this.privacyCookieName, privacyWarningCookie)
  }

  createServerSessionCookies = (consentOrigin) => {
    const consentGiven = consentOrigin == follozeEventTypes.consentGiven
    const internalCookieManagement = this.cookieManagement == cookieManagementTypes.internal
    const externalCookieManagementAfterConsent = this.isExternalCookieManagement && consentGiven

    if (!this.cookieManagement ||
      internalCookieManagement ||
      externalCookieManagementAfterConsent
    ){
      return sessionCookiesApi.setSessionCookies(this.boardId)
    }
    else {
      return Promise.resolve()
    }
  }

  updateCookiesConsent(leadId, options) {
    cookiesConsentApi.setCookiesConsent(this.boardId, leadId, options)
  }

  getConsentCookie = () => {
    const privacyWarningCookie = getCookie(this.privacyCookieName)
    return privacyWarningCookie
  }

  removeCookieConsent() {
    deleteAllCookies()
  }
}

export default Base