import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedTrTR = shared.tr

const trTR = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedTrTR.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Oluşturan'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Merhaba %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: 'GÖNDER i tıkladığınızda kısa süre içinde size geri döneceğiz.',
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Tam ad değil mi?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedTrTR.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedTrTR.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedTrTR.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedTrTR.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedTrTR.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Lütfen zorunlu alanları doldurunuz',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedTrTR.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Lütfen zorunlu alanları doldurunuz'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedTrTR.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedTrTR.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Mesajın',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedTrTR.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Bu sayfayı şunu kullanarak paylaş'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Kiminle paylaşmak istiyorsun?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedTrTR.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedTrTR.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedTrTR.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedTrTR.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedTrTR.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedTrTR.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedTrTR.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'e-posta burada paylaş',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Gönderici ayrıntıları'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedTrTR.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'En çok görüntülenen'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Dosya',
    [TRANSLATION_VALUE_KEYS.image]: 'Mesaj',
    [TRANSLATION_VALUE_KEYS.link]: 'Bağlantı',
    [TRANSLATION_VALUE_KEYS.video]: 'Video',
    [TRANSLATION_VALUE_KEYS.article]: 'Makale'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Ara'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Ana Sayfa'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Lütfen şifreyi gir',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'Yanlış şifre. Lütfen tekrar deneyin',
    [TRANSLATION_VALUE_KEYS.go]: 'Git',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Şifreyi buraya gir',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Şifre başlığı'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedTrTR.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedTrTR.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Yeni bir sekmede aç',
    [TRANSLATION_VALUE_KEYS.like]: 'Beğen',
    [TRANSLATION_VALUE_KEYS.download]: 'İndir'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'İçerik'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedTrTR.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedTrTR.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'E-posta',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedTrTR.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedTrTR.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedTrTR.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Mesajı',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedTrTR.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedTrTR.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedTrTR.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedTrTR.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedTrTR.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedTrTR.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Mesajını buraya yaz...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Daha fazla',
    [TRANSLATION_VALUE_KEYS.less]: 'Daha az'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Teşekkürler'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Bu sayfada e-posta adresinizi doğrulamanız gerekiyor',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Onay kodu talep etmek için e-postanızı girin',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'E-postanızı girin',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Sayfaya devam et',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Yeni bir kod gönder',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Yeni kod gönderildi',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Onay kodu size gönderilmiştir. Lütfen',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'gelen bir e-postayı kontrol edin. E-postanın promosyon, güncelleme veya spam klasörünüzde olabileceğini lütfen unutmayın',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Bu e-posta adresinin bu sayfayı görüntüleme yetkisi yok',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Üzgünüz, bu sayfaya erişim konusunda sorun yaşıyorsunuz',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Erişim kazanmak için lütfen şirket temsilcinizle iletişime geçin',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Onay kodunuzu girin',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Kodu buraya yazın',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Kod yanlış',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Bir Hata Oluştu',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedTrTR.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedTrTR.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedTrTR.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedTrTR.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Bu Sayfa şifre ile korunuyor',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Lütfen aşağıya şifreyi girin',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Şifreyi buraya girin',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Şifreyi doğrula',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Yanlış şifre'
  }
}

export default trTR
