import React, { useState, useEffect, useRef } from 'react';

export default function useInterval(handler, delay) {
  const savedHandler = useRef()

  const invokeHandler = () => {
    savedHandler.current && savedHandler.current()
  }

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    if (delay !== null) {
      let id = setInterval(invokeHandler, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}