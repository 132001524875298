import React, { useState } from 'react'
import { ConfirmationDialog } from 'shared/UI/ConfirmationDialog'
export { CONFIRMATION_TYPES } from 'shared/UI/ConfirmationDialog'

const useConfirm = () => {
  const [promise, setPromise] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dialogProps, setDialogProps] = useState({})

  const confirm = (args = {}) => {
    setDialogProps(args)
    return new Promise((resolve) => {
      setPromise({ resolve })
      setIsDialogOpen(true)
    })
  }

  const clearDialogProps = () => {
    setDialogProps({})
  }

  const handleClose = () => {
    setPromise(null)
    setIsDialogOpen(false)
    clearDialogProps()
  }

  const handleConfirm = () => {
    promise?.resolve(true)
    handleClose()
  }

  const handleCancel = () => {
    promise?.resolve(false)
    handleClose()
  }

  const Dialog = () => (
    isDialogOpen &&
    <ConfirmationDialog
      {...dialogProps}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onClose={handleClose}
    />
  )
  return [Dialog, confirm]
}

export default useConfirm
