import { axios } from 'shared/services/axios'

const pingEndpoint = FollozeState.envConfig.PINGY_URI

export function sendPing({ leadId, boardId, itemId, guid, sessionGuid, contentItemId }) {
  if (!pingEndpoint) {
    return
  }

  return navigator.sendBeacon(`${pingEndpoint}pings`, JSON.stringify({
    lead_id: leadId,
    board_id: boardId,
    item_id: itemId,
    client_guid: guid,
    session_guid: sessionGuid,
    content_item_id: contentItemId
  }))
}
