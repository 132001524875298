module.exports = {
  themes: {
    black: {
      name: 'black',
      index: 3,
      hex: '#000000',
      gradient: 'linear-gradient(to bottom, #000000, #012c47)'
    },
    turquoise: {
      name: 'turquoise',
      index: 1,
      hex: '#37b6b7',
      gradient: 'linear-gradient(to bottom, #37b6b7, #012c47)'
    },
    blue: {
      name: 'blue',
      index: 0,
      hex: '#418bba',
      gradient: 'linear-gradient(to bottom, #1a86cd, #012c47)'
    },
    navy_blue: {
      name: 'navy_blue',
      index: 2,
      hex: '#2F5162',
      gradient: 'linear-gradient(to bottom, #2F5162, #012c47)'
    },
    green: {
      name: 'green',
      index: 4,
      hex: '#87b75f',
      gradient: 'linear-gradient(to bottom, #87b75f, #012c47)'
    },
    orange: {
      name: 'orange',
      index: 5,
      hex: '#fa8027',
      gradient: 'linear-gradient(to bottom, #fa8027, #012c47)'
    },
    red: {
      name: 'red',
      index: 7,
      hex: '#ee3f3f',
      gradient: 'linear-gradient(to bottom, #ee3f3f, #012c47)'
    },
    fuchsia: {
      name: 'fuchsia',
      index: 6,
      hex: '#db519b',
      gradient: 'linear-gradient(to bottom, #db519b, #012c47)'
    }
  },
  cta_destination: {
    email: { name: 'Contact Me', privacy: true, send_mail: false },
    message: { name: 'Send a Message', privacy: true, send_mail: false },
    new_tab: { name: 'Open a URL - new tab', privacy: false, send_mail: false },
    inline: { name: 'Open a URL - inline', privacy: false, send_mail: false },
    form: { name: 'Form', privacy: false, send_mail: true },
    share: { name: 'Share', privacy: true, send_mail: true },
    registration: { name: 'Registration', privacy: false, send_mail: true }
  },
  banner_height: {
    basic: { name: 'Basic' },
    short: { name: 'Short' },
    medium: { name: 'Medium' },
    tall: { name: 'Tall' }
  },
  background_position: {
    vertical: {
      center: { name: 'Align center' },
      top: { name: 'Align top' },
      bottom: { name: 'Align bottom' }
    },
    horizontal: {
      center: { name: 'Align center' },
      left: { name: 'Align left' },
      right: { name: 'Align right' }
    }
  },
  background_tint: {
    none: { opacity: 0, name: 'None' },
    theme_mid: { opacity: 0.5, name: '50% Theme' },
    theme: { opacity: 0.75, name: '75% Theme' },
    light_mid: { opacity: 0.5, name: '50% Light', gradient_override: 'white' },
    light: { opacity: 0.75, name: '75% Light', gradient_override: 'white' },
    dark_low: { opacity: 0.25, name: '25% Dark', gradient_override: 'black' },
    dark_mid: { opacity: 0.5, name: '50% Dark', gradient_override: 'black' },
    dark: { opacity: 0.75, name: '75% Dark', gradient_override: 'black' }
  },
  text_color: {
    light: { hex: '#fff', name: 'Light' },
    semi_dark: { hex: '#999', name: 'Semi Dark' },
    dark: { hex: '#333', name: 'Dark' }
  },
  text_alignment: {
    center: { name: 'Center' },
    left: { name: 'Left' },
    right: { name: 'Right' }
  },
  default_tracking_config: {
    show: false,
    button_label: 'Do not track me',
    dialog_button_label: '',
    dialog_text: ''
  },
  promotion_position: {
    below_banner: { name: 'Below Banner', value: 0, disabled: false },
    above_items: { name: 'Above Items', value: 1, disabled: false },
    below_items: { name: 'Below Items', value: 2, disabled: false },
    above_footer: { name: 'Above Footer', value: 3, disabled: false }
  },
  columns_alignment: {
    align_center: { name: 'Align Center', value: 0 },
    align_left: { name: 'Align Left', value: 1 },
    align_right: { name: 'Align Right', value: 2 }
  },
  columns_sizes: {
    small: { name: 'Small', value: 0 },
    large: { name: 'Large', value: 1 }
  },
  icons: {
    'briefcase': 'c-briefcase',
    'bulb': 'c-bulb',
    'bullhorn': 'c-bullhorn',
    'call': 'c-call',
    'cart': 'c-cart',
    'doc': 'c-doc',
    'flag': 'c-flag',
    'graph': 'c-graph',
    'graph-2': 'c-graph-2',
    'group': 'c-group',
    'idea': 'c-idea',
    'knight': 'c-knight',
    'laptop': 'c-laptop',
    'like': 'c-like',
    'present': 'c-present',
    'search': 'c-search',
    'send': 'c-send',
    'settings': 'c-settings',
    'setup': 'c-setup',
    'share': 'c-share',
    'sort': 'c-sort',
    'thumb': 'c-thumb',
    'trophy': 'c-trophy'
  },
  category_themes: {
    classic: { name: 'Classic' },
    tab: { name: 'Accent' }
  },
  default_mobile_background: {
    url: null,
    tint: 'theme',
    position: {
      x: 'center',
      y: 'center'
    }
  },
  default_form: {
    id: null,
    name: 'Untitled Form',
    board_id: null,
    organization_id: null,
    state: null,
    form_type: null,
    data: {
      form_title: '',
      submit_label: '',
      success_message: ''
    }
  },
  default_form_fields: {
    name: {
      type: 'text',
      label: 'Name',
      order: 1,
      state: 'required',
      placeholder: 'First Name'
    },
    email: {
      type: 'email',
      label: 'Email',
      order: 2,
      state: 'required',
      placeholder: 'your@email.here'
    },
    headline: {
      type: 'text',
      label: 'Title',
      order: 3,
      state: 'optional',
      placeholder: 'Title'
    },
    company: {
      type: 'text',
      label: 'Company',
      order: 4,
      state: 'optional',
      placeholder: 'Company'
    },
    phone: {
      type: 'tel',
      label: 'Phone',
      order: 5,
      state: 'optional',
      placeholder: 'Phone Number'
    },
    note: {
      type: 'long_text',
      label: 'Message',
      order: 6,
      state: 'optional',
      placeholder: 'Type your message here...'
    }
  },
  default_live_event: {
    type: 'zoom',
    title: '',
    subtitle: '',
    config: {
      schedule: {
        is_enabled: false,
        times: {
          start_time: null
        },
        add_to_calendar: true
      },
      meeting_url: ''
    },
    placeholder: {
      title: ''
    },
    attend_activity: {
      show: true
    }
  },
  live_events_types: {
    zoom: {
      name: 'Zoom',
      url_text: 'Zoom Meeting Url',
      upload_video_enabled: false
    },
    stream: {
      name: 'Stream',
      url_text: 'Stream Url',
      upload_video_enabled: false
    },
    simulive: {
      name: 'Simulive',
      url_text: '',
      upload_video_enabled: true
    }
  }
}
