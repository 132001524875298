import { fetchCategories } from 'LiveBoard/api/categories'
import { fetchItems } from 'LiveBoard/api/items'
import { fetchBoard } from 'LiveBoard/api/boards'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import MediaUtilityService from 'common/services/MediaUtilityService'

const { dispatch: dispatchPublicEvent, EventTypes } = PublicEventsDispatcher()

const FollozeApi = () => {
  const getBoard = (boardSlug) => {
    return fetchBoard(boardSlug)
  }

  const getCategories = (boardId) => {
    return fetchCategories({ boardId })
  }

  const getItems = ({ boardId, categoryId, search, page, perPage }) => {
    return fetchItems({ boardId, categoryId, search, page, perPage })
  }

  const openItemViewer = ({
    item,
    category,
    queryString = '',
    options = {}
  }) => {
    dispatchPublicEvent(EventTypes.openItemViewer, {
      item,
      category,
      queryString,
      options
    })
  }

  const getImageUrl = (image) => {
    return MediaUtilityService.getImageUrl({ image })
  }

  return {
    getBoard,
    getCategories,
    getItems,
    openItemViewer,
    getImageUrl
  }
}

export default FollozeApi()