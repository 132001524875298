export const ROOT_PATH = '/app'
export const SALES_ROOT_PATH = '/sales'
export const OUTREACH_ROOT_PATH = '/outreach'
export const SUPER_ADMIN_ROOT_PATH = `${ROOT_PATH}/super_admin`
export const COMPANY_ADMIN_ROOT_PATH = `${ROOT_PATH}/admin`
export const FOLLOZE_HELP_DESK_LINK = 'https://help.folloze.com/hc/en-us'
export const FOLLOZE_HOME_PAGE_LINK = 'https://www.folloze.com'
export const ITEM_ONLINE_STATUS = 1

export const PROMO_AREA_COLUMNS_ALIGNMENT_CLASSES = {
  0: 'align-center',
  1: 'align-left',
  2: 'align-right'
}

export const PROMO_AREA_COLUMNS_SIZE_CLASSES = {
  0: 'promo-icon-small',
  1: 'promo-icon-large'
}

export const PROMO_AREA_COLUMNS_IN_ROW_CLASSES = {
  1: 'one-in-row',
  2: 'two-in-row',
  3: 'three-in-row',
  4: 'four-in-row'
}

export const USER_SETTINGS_LABELS = {
  mySettings: 'My Settings',
  companyAdmin: 'Company Admin',
  about: 'About Folloze',
  superAdmin: 'Super Admin',
  signout: 'Sign Out'
}

export const LIVE_BOARD_SECTIONS = {
  header: 'header',
  banner: 'banner',
  items: 'items',
  promotion1: 'promotion1',
  promotion2: 'promotion2',
  promotion3: 'promotion3',
  side_by_side1: 'side_by_side1',
  side_by_side2: 'side_by_side2',
  side_by_side3: 'side_by_side3',
  contact_card: 'contact_card'
}

export const LIVE_BOARD_CLASSNAMES = {
  [LIVE_BOARD_SECTIONS.header]: 'header-container',
  [LIVE_BOARD_SECTIONS.banner]: 'banner-container',
  [LIVE_BOARD_SECTIONS.items]: 'items-view-container',
  [LIVE_BOARD_SECTIONS.promotion1]: 'promotion-area-container promo-area-1',
  [LIVE_BOARD_SECTIONS.promotion2]: 'promotion-area-container promo-area-2',
  [LIVE_BOARD_SECTIONS.promotion3]: 'promotion-area-container promo-area-3',
  [LIVE_BOARD_SECTIONS.side_by_side1]: 'side-by-side-area-container side-by-side-area-1',
  [LIVE_BOARD_SECTIONS.side_by_side2]: 'side-by-side-area-container side-by-side-area-2',
  [LIVE_BOARD_SECTIONS.side_by_side3]: 'side-by-side-area-container side-by-side-area-3',
  [LIVE_BOARD_SECTIONS.contact_card]: 'seller-card-container'
}

export const NOTIFICATION_TYPES = {
  success: 'success',
  info: 'info',
  error: 'error',
  warning: 'warning'
}

export const NOTIFICATION_CONFIG = {
  [NOTIFICATION_TYPES.success]: {
    className: 'success', icon: 'icon-tick-button-inverted'
  },
  [NOTIFICATION_TYPES.info]: {
    className: 'info', icon: 'icon-info'
  },
  [NOTIFICATION_TYPES.error]: {
    className: 'error', icon: 'icon-suppressed'
  },
  [NOTIFICATION_TYPES.warning]: {
    className: 'warning', icon: 'icon-warning-outline'
  }
}

export const DEFAULT_THEME_COLOR = '#1a828f'

export const VIDEO_BACKGROUND_PLACEHOLDER = 'https://images.folloze.com/image/upload/v1575894653/bfzma0frzdzt5jlwjsr2.png'
// eslint-disable-next-line
export const ACCEPTED_VIDEO_FORMATS_REGEX = "(\.mp4|\.webm|\.mov|\.ogv|\.3gp|\.3g2|\.wmv|\.mpeg|\.mkv|\.avi)$"
export const SVG_EXTENSION = '.svg'

export const ITEM_CONTENT_ACTIONS = {
  createSnapshotUrl: 'createSnapshotUrl',
  analyzeUrl: 'analyzeUrl',
  getFileMetadata: 'getFileMetadata'
}

export const ITEM_SOURCES = {
  link: 1,
  file: 2,
  box: 3,
  article: 4
}

export const LAYOUT_POSITION = {
  belowBanner: 'below_banner',
  aboveFooter: 'above_footer'
}

export const CLOUDINARY_VIDEO_SOURCE_TYPE = {
  url: 'url',
  publicId: 'publicId'
}

export const CAMPAIGN_ELEMENTS_TYPES = {
  footer: 1,
  privacyMessage: 2,
  formPrivacyMessage: 3,
  privacyPolicy: 4
}

export const PRIVACY_POLICIES = {
  explicit: 'explicit',
  implicit: 'implicit',
  none: 'none'
}

export const BOARD_STATUS_LABELS = {
  online: 'Online',
  offline: 'Offline',
  draft: 'Draft',
  archived: 'Archived'
}

export const ADMIN_CONFIRM_SAVE_MODAL_TEXT = {
  title: 'Are you sure that you want to perform this admin action?',
  message: 'This action may have an impact on the entire company instance and live boards.'
}

export const COOKIE_MANAGEMENT_OPTIONS = {
  internal: 'internal',
  external: 'external'
}
