import { axios } from 'shared/services/axios'

export function fetchCampaignElement(boardId, elementId, elementType) {
  return axios.get(`/live_board/v2/campaign_elements/${elementId}`, {
    params: {
      board_id: boardId,
      element_type: elementType
    }
  }).then(response => response.data)
}
