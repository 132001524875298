import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedNlNL = shared['nl-NL']

const nlNL = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedNlNL.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Gecreëerd door'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Hallo %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: "Klik gewoon op 'VERSTUREN' en we zullen spoedig contact met je opnemen.",
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Ben je niet %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedNlNL.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedNlNL.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedNlNL.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedNlNL.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedNlNL.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Vul de verplichte velden in',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedNlNL.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Vul de verplichte velden in',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedNlNL.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedNlNL.email
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedNlNL.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedNlNL.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Je boodschap',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedNlNL.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Deel deze pagina met'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Met wie wil je dit delen?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedNlNL.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedNlNL.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedNlNL.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedNlNL.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedNlNL.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedNlNL.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedNlNL.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'deel@email.hier',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Gegevens afzender'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedNlNL.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Meest bekeken'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Bestand',
    [TRANSLATION_VALUE_KEYS.image]: 'Afbeelding',
    [TRANSLATION_VALUE_KEYS.link]: 'Link',
    [TRANSLATION_VALUE_KEYS.video]: 'Video',
    [TRANSLATION_VALUE_KEYS.article]: 'Artikel'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Zoeken'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Thuispagina'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Geef je wachtwoord in',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'Het wachtwoord is fout. Probeer opnieuw',
    [TRANSLATION_VALUE_KEYS.go]: 'Begin',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Geef je wachtwoord hier in…',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Inhoud bekijken'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedNlNL.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedNlNL.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Openen in nieuw tabblad',
    [TRANSLATION_VALUE_KEYS.like]: 'Leuk vinden',
    [TRANSLATION_VALUE_KEYS.download]: 'Downloaden'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Inhoud'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedNlNL.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedNlNL.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Email',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedNlNL.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedNlNL.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedNlNL.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Boodschap',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedNlNL.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedNlNL.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedNlNL.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedNlNL.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedNlNL.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedNlNL.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Typ je boodschap hier...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Meer',
    [TRANSLATION_VALUE_KEYS.less]: 'Minder'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Dank je'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Op deze pagina dient u uw e-mailadres te verifiëren',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Voer uw e-mailadres in om een bevestigingscode aan te vragen',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Voer uw e-mailadres in',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Ga verder naar pagina',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Stuur een nieuwe code',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Nieuwe code verzonden',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'De bevestigingscode is naar u verzonden. Controleer of u een e-mail hebt ontvangen van',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Let op: De e-mail kan in uw map met reclame, updates of spam zijn terechtgekomen',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Dit e-mailadres heeft geen toestemming om deze pagina te bekijken',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Het spijt ons dat u problemen ondervindt bij het openen van deze pagina',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Neem contact op met een medewerker van het bedrijf om toegang te krijgen',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Voer je bevestigingscode in',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Typ hier de code',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Onjuiste code',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Er is een fout opgetreden',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedNlNL.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedNlNL.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedNlNL.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedNlNL.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Deze pagina is beveiligd met een wachtwoord',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Voer hieronder het wachtwoord in',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Voer hier het wachtwoord in',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Wachtwoord verifiëren',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Onjuist wachtwoord'
  }
}

export default nlNL
