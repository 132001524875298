import React from 'react'
import PropTypes from 'prop-types'
import browser from 'detect-browser'

import './style.css.scss'

class BodyStyle extends React.Component {
  componentDidMount() {
    this.addLiveBoardClass()
    this.handleBrowserDetection()
    this.updateScrollState()
  }

  componentDidUpdate(prevProps) {
    const { isModalOpen, isGreetingOpen } = this.props
    if((prevProps.isModalOpen != isModalOpen) ||
      (prevProps.isGreetingOpen != isGreetingOpen )) {
      this.updateScrollState()
    }

    if(prevProps.isItemViewerOpen != this.props.isItemViewerOpen) {
      this.props.isItemViewerOpen ? this.handleItemViewerOpen() : this.handleItemViewerClose(prevProps.itemViewerMode)
    }
  }

  addLiveBoardClass = () => {
    document.body.classList.add('live-board')
  }

  updateScrollState = () => {
    this.props.isModalOpen || this.props.isGreetingOpen ? this.disableScroll() : this.enableScroll()
  }

  handleBrowserDetection = () => {
    if(browser && browser.name) {
      document.body.classList.add(browser.name)
    }
  }

  enableScroll = () => {
    document.body.classList.remove('disable-scroll')
  }

  disableScroll = () => {
    document.body.classList.add('disable-scroll')
  }

  handleItemViewerOpen = () => {
    document.body.classList.add('item-viewer-open')
    this.props.itemViewerMode &&
      document.body.classList.add(`item-viewer-${this.props.itemViewerMode}`)
  }

  handleItemViewerClose = (viewerMode) => {
    document.body.classList.remove('item-viewer-open')
    viewerMode &&
      document.body.classList.remove(`item-viewer-${viewerMode}`)
  }

  render () {
    return null
  }
}

BodyStyle.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isGreetingOpen: PropTypes.bool.isRequired,
  isItemViewerOpen: PropTypes.bool.isRequired,
  itemViewerMode: PropTypes.string
}

export default BodyStyle
