import React from 'react'
import PropTypes from 'prop-types'
import {
  withTranslation, TRANSLATION_VALUE_KEYS, TRANSLATION_KEYS
} from 'LiveBoard/hocs'

import './style.css.scss'

const SuccessMessage = (props) => {
  const successMessage = props.successMessage ||
    props.getTranslation(TRANSLATION_VALUE_KEYS.thankYou)

  return (
    <div className="success-message-box">
      <div className="success-message-icon icon-sent"></div>
      <div className="success-message-text">{successMessage}</div>
    </div>
  )
}

SuccessMessage.propTypes = {
  successMessage: PropTypes.string
}

export default withTranslation(TRANSLATION_KEYS.successMessage)(SuccessMessage)