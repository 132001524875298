import { matchPath } from 'react-router-dom'
import {
  BOARD_ROUTE, ITEM_ROUTE, CATEGORY_ROUTE, HOME_ROUTE_CATEGORY_ID
} from 'LiveBoard/config/constants'
import Base from './Base'

const BOARD_PATH = "public/prism/:boardId"
const CATEGORY_PATH = "category/:categoryId"
const ITEM_PATH = "item/:itemId"
const HOME_PATH = "category/home"

class RestUrls extends Base {

  constructor() {
    super()
    this.boardPath = BOARD_PATH
    this.categoryPath = CATEGORY_PATH
    this.itemPath = ITEM_PATH
    this.oldItemPath = ITEM_PATH
    this.homePath = HOME_PATH
  }

  getBoardRoute(board, options) {
    return `${BOARD_ROUTE}${board.id}`
  }

  getCategoryRoute(category, board) {
    return `${BOARD_ROUTE}${board.id}${CATEGORY_ROUTE}${category.id}`
  }

  getItemRoute({ item, category, board }) {
    const categoryRoute = `${CATEGORY_ROUTE}${!!category ? category.id : HOME_ROUTE_CATEGORY_ID}`
    return `${BOARD_ROUTE}${board.id}${categoryRoute}${ITEM_ROUTE}${item.id}`
  }

  matchItemPath(path) {
    return matchPath(`${this.boardPath}/${this.itemPath}`, path)
  }

  matchCategoryPath(path) {
    return matchPath(`${this.boardPath}/${this.categoryPath}`, path)
  }

  matchBoardPath(path) {
    return matchPath(`${this.boardPath}`, path)
  }
}

export default RestUrls