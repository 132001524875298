import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useActions, usePrevious } from 'shared/customHooks'
import {
  withCookieConsent,
  withCustomPreviewNavigation,
  withOutboundLink
} from 'LiveBoard/hocs'
import {
  fetchItem, fetchCategories, setCategory,
  changeSearchQuery,
  SEARCH_CATEGORY_ID, setPreviousLocation
} from 'LiveBoard/modules/itemsView'
import { HOME_ROUTE_CATEGORY_ID } from 'LiveBoard/config/constants'
import { ITEM_ONLINE_STATUS } from 'shared/constants'
import { load } from 'LiveBoard/componentLoader'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import SearchResultsContainer from 'LiveBoard/containers/SearchResultsContainer'
import RoutesService from 'LiveBoard/services/routes'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

const Category = load('Items/Views/Category/Category')
const BoardContent = load('BoardContent/BoardContent')
const { EventTypes, dispatch: dispatchPublicEvent } = PublicEventsDispatcher()

const BoardContentContainer = (props) => {
  const board = useSelector(({board}) => board)
  const campaign = useSelector(({campaign}) => campaign)
  const itemsView = useSelector(({itemsView: { categories, categoryId, query }}) => {return { categories, categoryId, query }})
  const categoriesData = useSelector(({categories: { data: categoriesData }}) => categoriesData)
  const leadingItemId = useSelector(({campaign}) => get(campaign.items, 'leading_item.id'))
  const prevLeadingItemId = usePrevious(leadingItemId)
  const itemsData = useSelector(({items: { data: itemsData }}) => itemsData)
  const showItems = useSelector(({campaign}) => get(campaign.items, 'show', true))

  useEffect(() => {
    if (showItems) {
      handleCategories(true)
      !!leadingItemId && actions.fetchItem(board.id, leadingItemId)
    }

    window.addEventListener('popstate', handleBrowserBackForwardActions)

    return () => {
      window.removeEventListener('popstate', handleBrowserBackForwardActions)
    }
  }, [])

  useEffect(() => {
    if (!prevLeadingItemId && !!leadingItemId) {
      actions.fetchItem(board.id, leadingItemId)
    }
  }, [leadingItemId])

  useEffect(() => {
    if (showItems) {
      handleCategories(false)
    }
  }, [showItems])

  const actions = useActions({
    fetchCategories,
    setCategory,
    fetchItem,
    changeSearchQuery,
    setPreviousLocation
  }, [])

  const handleBrowserBackForwardActions = () => {
    const selectedCategoryId = itemsView.categoryId
    if (selectedCategoryId != SEARCH_CATEGORY_ID) {
      const { params: { categorySlug, itemSlug} } = getMatchFromUrlPath(window.location.pathname)

      actions.setPreviousLocation(categorySlug)

      getCategories()
        .then((categories) => setCategoryFromSlug(categories, categorySlug, itemSlug))
    }
  }

  const getMatchFromUrlPath = (path) => {
    return RoutesService.matchCategoryPath(path)
      || RoutesService.matchItemPath(path)
      || RoutesService.matchBoardPath(path)
  }

  const setCategoryFromSlug = (categoriesData, categorySlug, itemSlug) => {
    const categoryId = getCategoryIdFromSlug(categoriesData, categorySlug)
    actions.setCategory(categoryId, window.location, itemSlug)
      .then(nextRoute => {
        !!nextRoute && props.navigate(nextRoute)
      })
  }

  const getCategoryIdFromSlug = (categories, categorySlug) => {
    if (categorySlug == HOME_ROUTE_CATEGORY_ID) {
      return HOME_ROUTE_CATEGORY_ID
    }
    else {
      const category = find(categories.data, {slug: categorySlug})
      return !!category ? category.id : null
    }
  }

  const handleCategories = (isLandingOnBoard) => {
    getCategories()
      .then((categories) => handleInitialCategory(categories, isLandingOnBoard))
  }

  const getCategories = () => {
    return new Promise((resolve) => {
      if (isEmpty(categoriesData.data)) {
        actions.fetchCategories({ boardId: board.id })
          .then((categories) => resolve(categories))
      }
      else {
        resolve(categoriesData)
      }
    })
  }

  const getInitialCategoryId = (isLandingOnItem, categories) => {
    return isLandingOnItem ? null : getCategoryIdFromParams(categories)
  }

  const handleLandingOnItem = () => {
    const boardRoute = RoutesService.getBoardRoute(board, {addHomeSuffix: false})
    const search = props.location.search

    actions.setPreviousLocation(`${boardRoute}${search || ''}`)
  }

  const handleInitialCategory = (categories, isLandingOnBoard) => {
    const selectedCategoryId = itemsView.categoryId
    if (selectedCategoryId != SEARCH_CATEGORY_ID) {
      const pathParams = RoutesService.matchItemPath(window.location.pathname)
      const categoryId = getInitialCategoryId(!!pathParams, categories)
      !!pathParams && handleLandingOnItem()
      actions.setCategory(categoryId, props.location, props.params.itemSlug)
        .then(nextRoute => {
          !!nextRoute && !isLandingOnBoard && props.navigate(nextRoute)
        })
    }
  }

  const getCategoryIdFromParams = (categories) => {
    if (!!props.params.categoryId) {
      return props.params.categoryId
    }
    if (props.params.categorySlug == HOME_ROUTE_CATEGORY_ID) {
      return HOME_ROUTE_CATEGORY_ID
    }
    else {
      const category = find(categories.data, {slug: props.params.categorySlug})
      return !!category ? category.id : null
    }
  }

  const handleCategoryChange = (categoryId) => {
    props.onCookieConsent()
    return actions.setCategory(categoryId, props.location)
  }

  const getPromotionByPosition = (promotion) => {
    let itemsByPosition = {}
    sortBy(promotion.items, 'key').forEach((promoArea, index) => {
      itemsByPosition[promoArea.key] = {...promoArea, index}
    })

    const positionPromotion = {
      ...promotion,
      items: itemsByPosition
    }
    return positionPromotion
  }

  const handleItemClick = ({item, category, queryString}) => {
    const categoryToOpen = category || { slug: HOME_ROUTE_CATEGORY_ID }
    dispatchPublicEvent(EventTypes.openItemViewer, {
      item,
      category: categoryToOpen,
      queryString
    })
  }

  const BoardContentComponent = itemsView.query ? SearchResultsContainer : Category
  const selectedCategory = itemsView.categories[itemsView.categoryId]
  const leadingItem = !!leadingItemId ? itemsData[leadingItemId] : null
  const leadingItemAvailable = get(itemsData, [leadingItemId, 'status']) == ITEM_ONLINE_STATUS

  return (
    <BoardContent
      board={board}
      campaign={campaign}
      promotion={getPromotionByPosition(campaign.promotion)}
      sideBySide={campaign.side_by_side}
      leadingItem={leadingItem}
      onCategoryChange={handleCategoryChange}
      selectedCategoryId={get(selectedCategory, 'id')}
      hasLeadingItem={!!leadingItemId}
      leadingItemAvailable={leadingItemAvailable}
      onItemClick={handleItemClick}>
        <BoardContentComponent
          themeColorHex={campaign.themeColorHex}
          query={itemsView.query}
          category={selectedCategory} />
    </BoardContent>
  )
}

BoardContentContainer.propTypes = {
  onProvideHeaderChildren: PropTypes.func,
  onCookieConsent: PropTypes.func.isRequired
}

export default withOutboundLink(
  withCookieConsent(
    withCustomPreviewNavigation(BoardContentContainer)
  )
)