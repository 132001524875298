import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'shared/hocs'
import { QUERY_PARAM } from 'LiveBoard/config/constants'
import { setCategory, openSearch } from 'LiveBoard/modules/itemsView'
import { queryToParamsObject } from 'shared/helpers/UrlHelper'
import { load } from 'LiveBoard/componentLoader'

const SearchResults = load('Items/Views/SearchResults/SearchResults')

class SearchResultsContainer extends React.Component {
  componentDidMount() {
    const { openSearch, location: { search } } = this.props
    const paramsQueryValue = new URLSearchParams(search)

    openSearch(paramsQueryValue.get(QUERY_PARAM))
  }

  handleClose = () => {
    const {
      previousCategoryId, setCategory, navigate, location
    } = this.props

    setCategory(previousCategoryId, location)
      .then(nextRoute => { !!nextRoute && navigate(nextRoute) })
  }

  render() {
    const {
      category,categoryTheme, query, themeColorHex, items, itemIds, mostViewedItemId,
      isLoading, hasMorePages, onItemClick, onNextPage, shouldFetchNextPage,
      gatingEnabled, showViews, showLikes, showItemDescription
    } = this.props

    return (
      <SearchResults
        category={category}
        categoryTheme={categoryTheme}
        query={query}
        themeColorHex={themeColorHex}
        onClose={this.handleClose}
        onItemClick={onItemClick}
        items={items}
        itemIds={itemIds}
        mostViewedItemId={mostViewedItemId}
        isLoading={isLoading}
        hasMorePages={hasMorePages}
        onNextPage={onNextPage}
        shouldFetchNextPage={shouldFetchNextPage}
        gatingEnabled={gatingEnabled}
        showViews={showViews}
        showLikes={showLikes}
        showItemDescription={showItemDescription} />
    )
  }
}

SearchResultsContainer.propTypes = {
  category: PropTypes.object,
  categoryTheme: PropTypes.string,
  query: PropTypes.string,
  themeColorHex: PropTypes.string,
  setCategory: PropTypes.func.isRequired,
  openSearch: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  items: PropTypes.object,
  itemIds: PropTypes.array,
  mostViewedItemId: PropTypes.number,
  isLoading: PropTypes.bool,
  hasMorePages: PropTypes.bool,
  location: PropTypes.object.isRequired
}

function mapStateToProps({ itemsView: {previousCategoryId} }) {
  return {
    previousCategoryId
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCategory, openSearch }, dispatch)
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResultsContainer)
)

