import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'
import { setCookiesConsent, setLeadCookie } from 'LiveBoard/modules/cookiesConsent'
import get from 'lodash/get'

const follozeEventTypes = PublicEventsDispatcher().EventTypes

export default function withCookieConsent(WrappedComponent) {
  const Consent = (props) => {
    useEffect(() => {
      window.addEventListener(follozeEventTypes.consentGiven, handleExternalConsent)
      return () => {
        window.removeEventListener(follozeEventTypes.consentGiven, handleExternalConsent)
      }
    },[])

    const handleConsent = () => {
      if (!props.hasConsent && props.trackCurrentLead) {
        props.setCookiesConsent(WrappedComponent.name)
      }
    }

    const handleExternalConsent = () => {
      props.setLeadCookie(follozeEventTypes.consentGiven)
    }

    return (
      <WrappedComponent
        {...props.childProps}
        onCookieConsent={handleConsent} />
    )
  }

  withCookieConsent.propTypes = {
    hasConsent: PropTypes.bool.isRequired
  }

  function mapStateToProps({ cookiesConsent, lead }, props) {
    return {
      childProps: props,
      hasConsent: get(cookiesConsent, 'hasConsent'),
      trackCurrentLead: get(lead, 'trackCurrentLead', true)
    }
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCookiesConsent, setLeadCookie }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(Consent)
}