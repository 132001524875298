import { createSelector } from 'reselect'

const itemViewer = ({ itemViewer }) => itemViewer
const currentItem = ({ itemViewer }) => itemViewer.items[itemViewer.currentItemIndex]

export const getCurrentItemId = createSelector(
  itemViewer,
  (itemViewer) => itemViewer?.currentItemId
)

export const getCurrentContentItemId = createSelector(
  currentItem,
  (currentItem) => currentItem?.content_item_id
)
