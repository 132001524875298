import React from 'react'
import PropTypes from 'prop-types'
import { CloseButton } from 'shared/UI/CloseButton'

import './style.css.scss'

const Header = (props) => {
  return (
    <div className="dialog-header">
      <h1 className="dialog-title">
        {props.title}
      </h1>
     { props.subtitle &&
        <h2 className='dialog-subtitle'>
          {props.subtitle}
        </h2>
      }
      <CloseButton
        closeIcon='icon-clear'
        onClose={props.onClose} />
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func
}

export default Header