export const TRANSLATION_KEYS = {
  searchResults: 'searchResults',
  emailDialog: 'emailDialog',
  shareDialog: 'shareDialog',
  mobileMenu: 'mobileMenu',
  mobileItemsHeader: 'mobileItemsHeader',
  mobileEmailDialog: 'mobileEmailDialog',
  ctaShareBoard: 'ctaShareBoard',
  customForm: 'customForm',
  knownLead: 'knownLead',
  unknownLead: 'unknownLead',
  ctaSendMessage: 'ctaSendMessage',
  mobileShareBoard: 'mobileShareBoard',
  standardFooter: 'standardFooter',
  mostViewedTag: 'mostViewedTag',
  password: 'password',
  searchForm: 'searchForm',
  shareEmail: 'shareEmail',
  submitButton: 'submitButton',
  validationErrors: 'validationErrors',
  itemTypeConfig: 'itemTypeConfig',
  itemsView: 'itemsView',
  itemDescription: 'itemDescription',
  successMessage: 'successMessage',
  twoFAPage: 'twoFAPage',
  passwordPage: 'passwordPage'
}

export const TRANSLATION_VALUE_KEYS = {
  send: 'send',
  sending: 'sending',
  done: 'done',
  yourEmailHere: 'yourEmailHere',
  yourMessageHere: 'yourMessageHere',
  createdBy: 'createdBy',
  hi: 'hi',
  clickSend: 'clickSend',
  notLeadName: 'notLeadName',
  yourEmail: 'yourEmail',
  requiredField: 'requiredField',
  emailField: 'emailField',
  requiredSingleField: 'requiredSingleField',
  approvedField: 'approvedField',
  sharePage: 'sharePage',
  shareMessage: 'shareMessage',
  email: 'email',
  to: 'to',
  firstName: 'firstName',
  lastName: 'lastName',
  shareEmailHere: 'shareEmailHere',
  mostViewed: 'mostViewed',
  file: 'file',
  link: 'link',
  image: 'image',
  video: 'video',
  search: 'search',
  home: 'home',
  viewContent: 'viewContent',
  enterPasswordHere: 'enterPasswordHere',
  go: 'go',
  passwordEmpty: 'passwordEmpty',
  passwordIncorrect: 'passwordIncorrect',
  senderDetails: 'senderDetails',
  searchResults: 'searchResults',
  noSearchResults: 'noSearchResults',
  shareBoard: 'shareBoard',
  contents: 'contents',
  openNewTab: 'openNewTab',
  like: 'like',
  download: 'download',
  more: 'more',
  less: 'less',
  article: 'article',
  thankYou: 'thankYou',
  // custom form fields - START
  headline: 'headline',
  company: 'company',
  phone: 'phone',
  name_label: 'name_label',
  last_name_label: 'last_name_label',
  email_label: 'email_label',
  headline_label: 'headline_label',
  company_label: 'company_label',
  phone_label: 'phone_label',
  note_label: 'note_label',
  name_placeholder: 'name_placeholder',
  last_name_placeholder: 'last_name_placeholder',
  email_placeholder: 'email_placeholder',
  headline_placeholder: 'headline_placeholder',
  company_placeholder: 'company_placeholder',
  phone_placeholder: 'phone_placeholder',
  note_placeholder: 'note_placeholder',
  enterEmail: 'enterEmail',
  twoFAPageTitle: 'twoFAPageTitle',
  twoFAPageDescription: 'twoFAPageDescription',
  sendCode: 'sendCode',
  codeSent: 'codeSent',
  codeSentConfirm: 'codeSentConfirm',
  codeSentConfirmNote: 'codeSentConfirmNote',
  continueToPage: 'continueToPage',
  emailNotAuthorized: 'emailNotAuthorized',
  accessPageError: 'accessPageError',
  checkAccessNote: 'checkAccessNote',
  enterConfirmationCode: 'enterConfirmationCode',
  typeCode: 'typeCode',
  incorrectCode: 'incorrectCode',
  errorOccurred: 'errorOccurred',
  passwordPageTitle: 'passwordPageTitle',
  passwordPageDescription: 'passwordPageDescription',
  enterPasswordPlaceholder: 'enterPasswordPlaceholder',
  verifyPassword: 'verifyPassword',
  wrongPassword: 'wrongPassword',
  code: 'code'
  // custom form fields - END
}
