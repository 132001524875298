import { BOARD_ROUTE } from 'LiveBoard/config/constants'
import RestUrls from './RestUrls'
import VanityUrls from './VanityUrls'
import startsWith from 'lodash/startsWith'

const getService = () => {
  if (startsWith(window.location.pathname, BOARD_ROUTE))
    return new RestUrls()
  else {
    return new VanityUrls()
  }
}

export default getService()
