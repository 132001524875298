import * as categoriesApi from 'LiveBoard/api/categories'
import { mergeArraysWithoutDuplicates } from 'shared/helper'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const FETCH_CATEGORIES_SUCCESS = 'categories/FETCH_CATEGORIES_SUCCESS'

function api(options = {}) {
  return !!options.getApi ? options.getApi() : categoriesApi
}

export function fetchCategories(params) {
  return function(dispatch, getState, options) {
    // leadingItemId is used only by the mock API for the preview
    return api(options).fetchCategories(params)
      .then((categories) => {
        dispatch({
          type: FETCH_CATEGORIES_SUCCESS,
          ...categories
        })

        return categories
      })
  }
}

const initialState = {
  data: {},
  categoryIds: [],
  promotedCategoryId: null
}

export function prepareInitialState({ categories }) {
  if (isEmpty(categories)) {
    return initialState
  }

  const promotedCategoryId = categories.promoted_category_id
  const categoryIds = !!promotedCategoryId ? [promotedCategoryId] : []

  return {
    ...initialState,
    categoryIds,
    promotedCategoryId
  }
}

export default function categories(state = initialState, action) {
  switch(action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.data,
        categoryIds: action.categories_ids
      }
    default:
      return state
  }
}
