import * as itemsActions from 'LiveBoard/modules/items'
import { fetchCategories as categoriesFetch } from 'LiveBoard/modules/categories'
import { mergeArraysWithoutDuplicates } from 'shared/helper'
import { removeQueryStringParam } from 'shared/helpers/UrlHelper'
import { BOARD_ROUTE, CATEGORY_TYPES, HOME_ROUTE_CATEGORY_ID, QUERY_PARAM } from 'LiveBoard/config/constants'
import RoutesService from 'LiveBoard/services/routes'
import get from 'lodash/get'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { I18n, TRANSLATION_VALUE_KEYS, TRANSLATION_KEYS } from 'shared/services/I18n'
export const SET_CATEGORY = 'itemsView/SET_CATEGORY'
const SET_INITIAL_CATEGORY = 'itemsView/SET_INITIAL_CATEGORY'
export const SET_SEARCH_QUERY = 'itemsView/SET_SEARCH_QUERY'
const FETCH_ITEMS = 'itemsView/FETCH_ITEMS'
const FETCH_FIRST_PAGE_SUCCESS = 'itemsView/FETCH_FIRST_PAGE_SUCCESS'
const FETCH_NEXT_PAGE_SUCCESS = 'itemsView/FETCH_NEXT_PAGE_SUCCESS'
const SET_PREVIOUS_LOCATION = 'itemsView/SET_PREVIOUS_LOCATION'
const SET_SHOULD_FETCH_NEXT_PAGE = 'itemsView/SET_SHOULD_FETCH_NEXT_PAGE'
const OPEN_SEARCH = 'itemsView/OPEN_SEARCH'
const FETCH_HAS_ITEMS_SUCCESS = 'itemsView/FETCH_HAS_ITEMS_SUCCESS'

const TRANSLATION_KEY = TRANSLATION_KEYS.itemsView
const i18n = I18n(FollozeState.initialState.locale)

export const SEARCH_CATEGORY_ID = 'search'
export const HOME_CATEGORY = {
  id: HOME_ROUTE_CATEGORY_ID,
  slug: HOME_ROUTE_CATEGORY_ID,
  name: i18n.translate(TRANSLATION_KEY, TRANSLATION_VALUE_KEYS.home),
  category_type: CATEGORY_TYPES.category
}

export function fetchCategories(params) {
  return (dispatch, getState, options) => {
    // leadingItemId is used only by the mock API for the preview
    return categoriesFetch(params)(dispatch, getState, options)
  }
}

function fetchItems({ successActionType, page }) {
  return (dispatch, getState, options) => {
    dispatch({
      type: FETCH_ITEMS
    })

    const {
      itemsView: { categoryId, query },
      categories: { promotedCategoryId },
      items: { promotedItemId },
      campaign: { items }
    } = getState()

    const params = {
      page,
      categoryId,
      search: query
    }

    if (categoryId === SEARCH_CATEGORY_ID || categoryId === HOME_ROUTE_CATEGORY_ID) {
      params.categoryId = null
    }

    return itemsActions.fetchItems(params)(dispatch, getState, options)
      .then((items) => {
        dispatch({
          type: successActionType,
          isPromotedCategory: categoryId === promotedCategoryId,
          items,
          categoryId,
          promotedItemId
        })
      })
  }
}

export function fetchItem(boardId, itemId) {
  return (dispatch, getState, options) => {
    return itemsActions.fetchItem(boardId, itemId)(dispatch, getState, options)
  }
}

export function fetchHasItems(leadingItemId) {
  return (dispatch, getState, options) => {
    return itemsActions.fetchHasItems(leadingItemId)(dispatch, getState, options)
      .then((data) => {
        dispatch({
          type: FETCH_HAS_ITEMS_SUCCESS,
          hasItems: data.has_items
        })
      })
  }
}

export function fetchFirstPage() {
  return function(dispatch, getState, options) {
    return fetchItems({
      successActionType: FETCH_FIRST_PAGE_SUCCESS,
      page: 1
    })(dispatch, getState, options)
  }
}

export function fetchNextPage() {
  return function(dispatch, getState, options) {
    const { page, categories, categoryId } = getState().itemsView
    return fetchItems({
      successActionType: FETCH_NEXT_PAGE_SUCCESS,
      page: categories[categoryId].page + 1
    })(dispatch, getState, options)
  }
}

export function setInitialCategory(categoryId) {
  return {
    type: SET_INITIAL_CATEGORY,
    categoryId
  }
}

export function setCategory(categoryId, currentLocation = {}, itemSlug) {
  return function(dispatch, getState) {
    return new Promise((resolve) => {
      const { categories, board, campaign, itemsView } = getState()
      let categoryName, nextRoute
      const showHome = campaign.items.show_home
      const { data: categoriesData, categoryIds } = categories

      // if no categories at all
      if (isEmpty(categoryIds) && !showHome) {
        dispatch(handleSetCategory(null, null))
        return resolve()
      }

      const currentQueryString = currentLocation.search || ''
      const nextQueryString = removeQueryStringParam(currentQueryString, QUERY_PARAM)
      const nextCategoryId = setNextCategory(categoryId, categories, showHome)

      if (nextCategoryId === itemsView.categoryId) {
        return resolve()
      }

      if (nextCategoryId === HOME_ROUTE_CATEGORY_ID) {
        categoryName = nextCategoryId
        const boardRoute = RoutesService.getBoardRoute(board)
        nextRoute = `${boardRoute}${nextQueryString}`
      } else {
        const category = categoriesData[nextCategoryId]
        categoryName = category.name
        const categoryRoute = RoutesService.getCategoryRoute(category, board)
        nextRoute = `${categoryRoute}${nextQueryString}`
      }

      dispatch(handleSetCategory(nextCategoryId, categoryName))

      if (itemSlug) {
        return resolve()
      }

      if (!isEmpty(currentLocation)) {
        const { pathname, search, hash } = currentLocation
        const currentRoute = `${pathname}${search}${hash}`

        if (nextRoute === currentRoute) {
          return resolve()
        }
      }

      return resolve(nextRoute)
    })
  }
}

function setNextCategory(categoryId, categories, showHome) {
  const { data: categoriesData, categoryIds } = categories

  const checkIfParentCategory = (nextCategoryId) => {
    const nextCategory = categoriesData[nextCategoryId]

    if (nextCategory.category_type === CATEGORY_TYPES.parentCategory) {
      return nextCategory.subcategories_ids[0]
    } else {
      return nextCategoryId
    }
  }

  // make sure category still exists
  if (categoryId && categoriesData[categoryId]) {
    return checkIfParentCategory(categoryId)
  } else if (showHome) {
    // show home category
    return HOME_ROUTE_CATEGORY_ID
  } else {
    // find next category that is not link
    const nextCategoryId = find(categoryIds, (cid) => {
      return categoriesData[cid].category_type !== CATEGORY_TYPES.linkCategory
    })

    return checkIfParentCategory(nextCategoryId)
  }
}

function handleSetCategory(categoryId, categoryName) {
  return {
    type: SET_CATEGORY,
    categoryId,
    trackingParams: {
      category: categoryName
    }
  }
}

export function changeSearchQuery(query) {
  return {
    type: SET_SEARCH_QUERY,
    query,
    trackingParams: {
      query
    }
  }
}

export function openSearch(query) {
  return {
    type: OPEN_SEARCH,
    query
  }
}

export function setPreviousLocation(location) {
  return {
    type: SET_PREVIOUS_LOCATION,
    location
  }
}

export function setShouldFetchNextPage() {
  return {
    type: SET_SHOULD_FETCH_NEXT_PAGE
  }
}

const categoryInitialState = {
  itemIds: [],
  page: 1,
  totalPages: 1,
  itemsCount: 0
}

const categoriesInitialState = {
  search: categoryInitialState
}

const initialState = {
  loading: false,
  mostViewedItemId: null,
  categoryId: null,
  previousCategoryId: null,
  categories: categoriesInitialState,
  query: '',
  previousLocation: null,
  shouldFetchNextPage: false
}

/* eslint-disable camelcase */
export function prepareInitialState({ has_items_to_display }) {
  return { ...initialState, hasItems: has_items_to_display }
}

function category(state = categoryInitialState, action) {
  switch (action.type) {
    case FETCH_FIRST_PAGE_SUCCESS:
      return {
        ...state,
        id: action.categoryId,
        itemIds: action.isPromotedCategory
          ? mergeArraysWithoutDuplicates(
            [action.promotedItemId],
            action.items.item_ids
          )
          : action.items.item_ids,
        page: 1,
        totalPages: action.items.total_pages,
        itemsCount: action.items.items_count
      }
    case FETCH_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        id: action.categoryId,
        itemIds: mergeArraysWithoutDuplicates(
          state.itemIds,
          action.items.item_ids
        ),
        page: action.items.current_page,
        totalPages: action.items.total_pages,
        itemsCount: action.items.items_count
      }
    default:
      return state
  }
}

function categories(state, action) {
  switch (action.type) {
    case FETCH_FIRST_PAGE_SUCCESS:
    case FETCH_NEXT_PAGE_SUCCESS:
    case SET_INITIAL_CATEGORY:
    case SET_CATEGORY:
      return {
        ...state,
        [action.categoryId]: category(state[action.categoryId], action)
      }
    default:
      return state
  }
}

export default function itemsView(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITEMS:
      return {
        ...state,
        loading: true,
        categories: categories(state.categories, action)
      }
    case FETCH_FIRST_PAGE_SUCCESS:
    case FETCH_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        mostViewedItemId: action.items.most_viewed_item_id,
        shouldFetchNextPage: false,
        categories: categories(state.categories, action)
      }
    case SET_INITIAL_CATEGORY:
    case SET_CATEGORY:
      return {
        ...state,
        categoryId: action.categoryId,
        previousCategoryId: state.categoryId,
        categories: categories(state.categories, action),
        query: initialState.query
      }
    case SET_SEARCH_QUERY:
      return {
        ...state,
        query: action.query,
        categories: {
          ...state.categories,
          search: categoryInitialState
        }
      }
    case OPEN_SEARCH:
      return {
        ...state,
        query: action.query,
        categoryId: SEARCH_CATEGORY_ID,
        previousCategoryId: state.categoryId
      }
    case SET_PREVIOUS_LOCATION:
      return {
        ...state,
        previousLocation: action.location
      }
    case SET_SHOULD_FETCH_NEXT_PAGE:
      return {
        ...state,
        shouldFetchNextPage: true
      }
    case FETCH_HAS_ITEMS_SUCCESS:
      return {
        ...state,
        hasItems: action.hasItems
      }
    default:
      return state
  }
}
