export const CATEGORY_TYPES = {
  category: 1,
  parentCategory: 2,
  subcategory: 3,
  linkCategory: 4
}

export const HOME_ROUTE_CATEGORY_ID = 'home'

export const CATEGORY_TYPES_KEYS = {
  1: 'category',
  2: 'parentCategory',
  3: 'subcategory',
  4: 'linkCategory'
}

export const CATEGORY_THEMES = {
  classic: 'classic',
  tab: 'tab'
}

export const CONTENT_SOURCE_TYPE = {
  item: 'item'
}

export const PERSONAL_DETAILS_PARAMS = ['em', 'fn', 'ln', 'ro', 'ph', 'co', 'ind', 'loc', 'inby', 'dom', 'safetoken']

export const TOKEN_PARAM = 'token'
export const BOARD_ROUTE = '/public/prism/'
export const ITEM_ROUTE = '/item/'
export const CATEGORY_ROUTE = '/category/'
export const QUERY_PARAM = 'query'
export const CAMPAIGN_CHANGE_COOKIE_NAME = 'redirect_on_campaign_change'
