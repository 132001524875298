import React from 'react'
import PropTypes from 'prop-types'
import { getItemTypeConfig } from './config'

import './style.css.scss'

const ItemType = ({ item }) => {
  const typeConfig = getItemTypeConfig(item)
  return (
    <div className="item-type">
      <span className={typeConfig.icon} />
      <span className='item-type-text'>{typeConfig.label}</span>
    </div>
  )
}

ItemType.propTypes = {
  item: PropTypes.object.isRequired
}

export default ItemType
