import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedItIT = shared['it-IT']

const itIT = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedItIT.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Creato da'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Ciao %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: 'Fai clic su INVIA ​​e ti risponderemo a breve.',
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Non %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedItIT.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedItIT.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedItIT.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedItIT.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedItIT.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Si prega di compilare i campi obbligatori',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedItIT.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Si prega di compilare i campi obbligatori'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedItIT.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedItIT.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Il tuo messaggio',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedItIT.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Condividi questa pagina usando:'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Con chi vorresti condividerlo?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedItIT.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedItIT.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedItIT.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedItIT.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedItIT.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedItIT.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedItIT.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'condividi @email qui',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Dettagli mittente'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedItIT.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Più visti'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'File',
    [TRANSLATION_VALUE_KEYS.image]: 'Immagine',
    [TRANSLATION_VALUE_KEYS.link]: 'Link',
    [TRANSLATION_VALUE_KEYS.video]: 'Video',
    [TRANSLATION_VALUE_KEYS.article]: 'Articolo'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Cerca'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Home'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Inserisci la password',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'La password errata, Per favore riprova.',
    [TRANSLATION_VALUE_KEYS.go]: 'Vai',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Inserisci la password qui',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Visualizza contenuto'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedItIT.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedItIT.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Apri in una nuova scheda',
    [TRANSLATION_VALUE_KEYS.like]: 'Mi piace',
    [TRANSLATION_VALUE_KEYS.download]: 'Scarica'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Contenuti'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedItIT.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedItIT.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Email',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedItIT.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedItIT.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedItIT.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Messaggio',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedItIT.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedItIT.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedItIT.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedItIT.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedItIT.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedItIT.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Scrivi il tuo messaggio qui...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Più',
    [TRANSLATION_VALUE_KEYS.less]: 'Meno'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Grazie'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Questa pagina ti richiede la verifica del tuo indirizzo e-mail',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Inserisci il tuo indirizzo e-mail per richiedere un codice di conferma',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Inserisci il tuo indirizzo e-mail',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Continua alla pagina',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Invia un nuovo codice',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Nuovo codice inviato',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: "Il codice di conferma ti è stato inviato. Verifica la presenza di un'e-mail proveniente da",
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Tieni presente che potrebbe esserti arrivata nelle cartelle Promozioni, Aggiornamenti o Spam',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Questo indirizzo e-mail non è autorizzato a visualizzare questa pagina',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Siamo spiacenti di apprendere che tu stia riscontrando problemi ad accedere a questa pagina',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: "Rivolgiti al responsabile della tua azienda per ottenere l'accesso'",
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Inserisci il tuo codice di conferma',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Digita il codice qui',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Codice errato',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Si è verificato un errore',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedItIT.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedItIT.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedItIT.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedItIT.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Questa pagina è protetta da password',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Inserisci la password qui sotto',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Inserisci la password qui',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Verifica la password',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Password errata'
  }
}

export default itIT
