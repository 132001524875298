import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedFrCA = shared['fr-CA']

const frCA = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedFrCA.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Créé par'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Bonjour %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: 'Cliquez simplement sur « ENVOYER » et nous vous répondrons dans les plus brefs délais.',
    [TRANSLATION_VALUE_KEYS.notLeadName]: "Vous n'êtes pas %{leadName}?"
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedFrCA.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedFrCA.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedFrCA.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedFrCA.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedFrCA.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Veuillez remplir les champs obligatoires',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedFrCA.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Veuillez remplir les champs obligatoires'
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedFrCA.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedFrCA.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Votre message',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedFrCA.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Partager cette page en utilisant'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Avec qui aimeriez-vous le partager?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedFrCA.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedFrCA.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedFrCA.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedFrCA.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedFrCA.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedFrCA.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedFrCA.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'partager@email.ici',
    [TRANSLATION_VALUE_KEYS.senderDetails]: "Détails de l'expéditeur"
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedFrCA.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Les plus vus'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Fichier',
    [TRANSLATION_VALUE_KEYS.image]: 'Image',
    [TRANSLATION_VALUE_KEYS.link]: 'Lien',
    [TRANSLATION_VALUE_KEYS.video]: 'Vidéo',
    [TRANSLATION_VALUE_KEYS.article]: 'Article'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Recherche'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Accueil'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Veuillez entrer votre mot de passe',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'Le mot de passe est incorrect. Veuillez réessayer',
    [TRANSLATION_VALUE_KEYS.go]: 'Aller',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Entrer le mot de passe ici',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Afficher le contenu'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedFrCA.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedFrCA.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Ouvrir dans un nouvel onglet',
    [TRANSLATION_VALUE_KEYS.like]: 'Aimer',
    [TRANSLATION_VALUE_KEYS.download]: 'Télécharger'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Contenu'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedFrCA.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedFrCA.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Email',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedFrCA.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedFrCA.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedFrCA.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Message',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedFrCA.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedFrCA.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedFrCA.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedFrCA.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedFrCA.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedFrCA.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Tapez votre message ici...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Plus',
    [TRANSLATION_VALUE_KEYS.less]: 'Moins'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Merci'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Cette page requiert la vérification de votre adresse de courriel',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Saisissez votre adresse de courriel pour demander un code de confirmation',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Saisir votre adresse de courriel',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Continuer vers la page',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Envoyer un nouveau code',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Nouveau code envoyé',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Le code de confirmation vous a été envoyé. Veuillez vérifier si vous avez reçu un courriel de la part de',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Veuillez noter que le courriel peut se trouver dans le dossier dédié aux promotions, aux mises à jour ou aux pourriels',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: "Cette adresse de courriel n'est pas autorisée et ne donne pas accès à cette page",
    [TRANSLATION_VALUE_KEYS.accessPageError]: "Désolés de constater que vous n'arrivez pas à accéder à cette page",
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: "Veuillez vous adresser au représentant de votre entreprise pour obtenir l'accès",
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Saisissez votre code de confirmation',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Saisissez le code ici',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Code incorrect',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Une erreur est survenue',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedFrCA.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedFrCA.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedFrCA.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedFrCA.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Cette page est protégée par un mot de passe',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Veuillez saisir le mot de passe ci-dessous',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Saisir le mot de passe ici',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Vérifier le mot de passe',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Mot de passe incorrect'
  }
}

export default frCA
