import React from 'react'
import PropTypes from 'prop-types'
import { BUTTON_SIZES, BUTTON_TYPES } from 'shared/UI/Button'
import { Dialog } from 'shared/UI/Dialog'
import { CloseButton } from 'shared/UI/CloseButton'

import './style.css.scss'

export const CONFIRMATION_TYPES = {
  primary: 'primary',
  alert: 'alert',
  warning: 'warning',
  info: 'info'
}

const CONFIRMATION_ICONS = {
  [CONFIRMATION_TYPES.primary]: 'icon-primary',
  [CONFIRMATION_TYPES.alert]: 'icon-suppressed',
  [CONFIRMATION_TYPES.warning]: 'icon-warning-outline',
  [CONFIRMATION_TYPES.info]: 'icon-info'
}

const CONFIRMATION_BUTTONS = {
  [CONFIRMATION_TYPES.primary]: BUTTON_TYPES.primary,
  [CONFIRMATION_TYPES.alert]: BUTTON_TYPES.alert,
  [CONFIRMATION_TYPES.warning]: BUTTON_TYPES.warning,
  [CONFIRMATION_TYPES.info]: BUTTON_TYPES.info
}

const ConfirmationDialog = ({
  onCancel,
  onConfirm,
  onClose,
  onCustomButton,
  cancelLabel,
  confirmLabel,
  customButtonLabel,
  customButtonStyle,
  dialogClassName,
  themeColor,
  confirmationType,
  header,
  title,
  subtitle,
  message,
  children,
  canClose,
  closeOnConfirm,
  showCloseButton,
  showCloseIconButton
}) => {
  const handleOnConfirm = () => {
    closeOnConfirm && onClose()
    onConfirm()
  }

  const shouldRenderCloseIconButton = showCloseIconButton && onClose

  return (
    <div className={`confirm-dialog-container ${confirmationType || ''}`}>
      <span className="overlay" />
      <Dialog
        themeColor={themeColor}
        onCancel={onCancel}
        onClose={handleOnConfirm}
        onCustomButton={onCustomButton}
        buttonsSize={BUTTON_SIZES.medium}
        cancelLabel={cancelLabel}
        closeLabel={confirmLabel}
        customButtonLabel={customButtonLabel}
        customButtonStyle={customButtonStyle}
        closeButtonType={CONFIRMATION_BUTTONS[confirmationType]}
        showButtons={true}
        className={`confirmation-dialog ${dialogClassName}`}
        showCloseButton={showCloseButton}
        canClose={canClose}>
        {confirmationType !== CONFIRMATION_TYPES.none &&
          <div className='confirmation-content'>
            {shouldRenderCloseIconButton && <CloseButton onClose={onClose} />}
            <div className='header'>{header}</div>
            <div className='title'>{title}</div>
            {!!subtitle &&
              <div className='subtitle'>
                <span className={CONFIRMATION_ICONS[confirmationType]} />
                {subtitle}
              </div>
            }
            {
              message &&
              <div className='message'>
                {message}
              </div>
            }
          </div>
        }
        {children}
      </Dialog>
    </div>
  )
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  dialogClassName: PropTypes.string,
  confirmationType: PropTypes.string.isRequired,
  closeOnConfirm: PropTypes.bool.isRequired,
  header: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  canClose: PropTypes.bool,
  onCustomButton: PropTypes.func,
  customButtonLabel: PropTypes.string,
  customButtonStyle: PropTypes.string,
  themeColor: PropTypes.string,
  children: PropTypes.node,
  showCloseButton: PropTypes.bool,
  showCloseIconButton: PropTypes.bool
}

ConfirmationDialog.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  closeOnConfirm: true
}

export default ConfirmationDialog
