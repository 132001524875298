function CampaignAnalytics() {
    "ngInject";

    var trackings = [];

    var ProviderScripts = {
        ga: {
            register: function(obj){
                var id = obj.code;

                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

                ga('create', id, 'auto');

                return function track(page){
                    const url = new URL(page)
                    ga('send', 'pageview', url.pathname);
                }
            }
        },
        munchkin: {
            register: function(obj){
                var pending = [],
                    didInit;
                function trackChange(page){
                    Munchkin.munchkinFunction("visitWebPage", {url: page});
                }
                function track(page){
                    if(!didInit && window.Munchkin && typeof(Munchkin.munchkinFunction) == "function"){  
                        didInit = true;
                        while(pending.length)
                            trackChange(pending.shift());
                    }
                    if(didInit) {
                        trackChange(page)
                    }
                    else {
                        pending.push(page);
                    }
                }

                return track;
            }
        },
        eloqua: {
            register: function(obj){
                var id = obj.code;
                window._elqQ = window._elqQ || [];
                window._elqQ.push(['elqSetSiteId', id]);
                window._elqQ.push(['elqTrackPageView']);

                (function() {
                    var s = document.createElement('script'); s.type = 'text/javascript';
                    s.async = true; s.src = '//img.en25.com/i/elqCfg.min.js';
                    var x = document.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                })();

                return function track(page){
                    window._elqQ.push(['elqTrackPageView', page]);
                }
            }
        },
        pardot: {
            register: function(obj){
                window.piAId = obj.pi_aid;
                window.piCId = obj.pi_cid;
                window.piHostname = obj.pi_hostname;

                (function() {

                    function async_load(){
                        var s = document.createElement('script');
                        s.type = 'text/javascript';
                        s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                        var c = document.getElementsByTagName('script')[0];
                        c.parentNode.insertBefore(s, c);
                    }

                    async_load();
                })();
                return function track(page) {
                }
            }
        }
    };

    function register(analyticsSettings){
        for(var provider in analyticsSettings){
            if(!analyticsSettings[provider] || !analyticsSettings[provider].active)
                continue;

            var track = ProviderScripts[provider].register(
                analyticsSettings[provider]
            );

            trackings.push(track);
        }
    }

    function track(page){
        for(var i=0; i<trackings.length; i++)
            trackings[i](page);
    }

    return  {
        register: register,
        track: track
    }

}

module.exports = CampaignAnalytics;