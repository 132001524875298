import shared from './shared'
import { TRANSLATION_KEYS, TRANSLATION_VALUE_KEYS } from './constants'

const sharedFiFI = shared.fi

const fiFI = {
  [TRANSLATION_KEYS.standardFooter]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedFiFI.send,
    [TRANSLATION_VALUE_KEYS.createdBy]: 'Tekijä'
  },
  [TRANSLATION_KEYS.knownLead]: {
    [TRANSLATION_VALUE_KEYS.hi]: 'Hei %{leadName}',
    [TRANSLATION_VALUE_KEYS.clickSend]: 'Klikkaa vain LÄHETÄ-painiketta, niin otamme sinuun yhteyttä pian.',
    [TRANSLATION_VALUE_KEYS.notLeadName]: 'Etkö ole %{leadName}?'
  },
  [TRANSLATION_KEYS.submitButton]: {
    [TRANSLATION_VALUE_KEYS.send]: sharedFiFI.send,
    [TRANSLATION_VALUE_KEYS.done]: sharedFiFI.done,
    [TRANSLATION_VALUE_KEYS.sending]: sharedFiFI.sending
  },
  [TRANSLATION_KEYS.unknownLead]: {
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedFiFI.yourEmail,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedFiFI.yourEmailHere
  },
  [TRANSLATION_KEYS.validationErrors]: {
    [TRANSLATION_VALUE_KEYS.requiredField]: 'Täytä pakolliset kentät',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedFiFI.emailField,
    [TRANSLATION_VALUE_KEYS.approvedField]: 'Täytä pakolliset kentät',
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedFiFI.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedFiFI.email
  },
  [TRANSLATION_KEYS.ctaSendMessage]: {
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedFiFI.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.yourMessageHere]: sharedFiFI.yourMessageHere,
    [TRANSLATION_VALUE_KEYS.yourMessage]: 'Viestisi',
    [TRANSLATION_VALUE_KEYS.yourEmail]: sharedFiFI.yourEmail
  },
  [TRANSLATION_KEYS.shareDialog]: {
    [TRANSLATION_VALUE_KEYS.sharePage]: 'Jaa tämä sivu käyttämällä seuraavaa'
  },
  [TRANSLATION_KEYS.emailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareMessage]: 'Kenen kanssa haluat jakaa sen?'
  },
  [TRANSLATION_KEYS.mobileEmailDialog]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedFiFI.shareBoard
  },
  [TRANSLATION_KEYS.shareEmail]: {
    [TRANSLATION_VALUE_KEYS.email]: sharedFiFI.email
  },
  [TRANSLATION_KEYS.ctaShareBoard]: {
    [TRANSLATION_VALUE_KEYS.to]: sharedFiFI.to,
    [TRANSLATION_VALUE_KEYS.email]: sharedFiFI.email,
    [TRANSLATION_VALUE_KEYS.yourEmailHere]: sharedFiFI.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.lastName]: sharedFiFI.lastName,
    [TRANSLATION_VALUE_KEYS.firstName]: sharedFiFI.firstName,
    [TRANSLATION_VALUE_KEYS.shareEmailHere]: 'share@email.here',
    [TRANSLATION_VALUE_KEYS.senderDetails]: 'Lähettäjän tiedot'
  },
  [TRANSLATION_KEYS.mobileShareBoard]: {
    [TRANSLATION_VALUE_KEYS.shareBoard]: sharedFiFI.shareBoard
  },
  [TRANSLATION_KEYS.mostViewedTag]: {
    [TRANSLATION_VALUE_KEYS.mostViewed]: 'Katsotuimmat'
  },
  [TRANSLATION_KEYS.itemTypeConfig]: {
    [TRANSLATION_VALUE_KEYS.file]: 'Tiedosto',
    [TRANSLATION_VALUE_KEYS.image]: 'Kuva',
    [TRANSLATION_VALUE_KEYS.link]: 'Linkki',
    [TRANSLATION_VALUE_KEYS.video]: 'Video',
    [TRANSLATION_VALUE_KEYS.article]: 'Artikkeli'
  },
  [TRANSLATION_KEYS.searchForm]: {
    [TRANSLATION_VALUE_KEYS.search]: 'Hae'
  },
  [TRANSLATION_KEYS.itemsView]: {
    [TRANSLATION_VALUE_KEYS.home]: 'Etusivu'
  },
  [TRANSLATION_KEYS.password]: {
    [TRANSLATION_VALUE_KEYS.passwordEmpty]: 'Kirjoita salasana',
    [TRANSLATION_VALUE_KEYS.passwordIncorrect]: 'Salasana on väärä. Yritä uudelleen.',
    [TRANSLATION_VALUE_KEYS.go]: 'Siirry',
    [TRANSLATION_VALUE_KEYS.enterPasswordHere]: 'Kirjoita salasana tähän',
    [TRANSLATION_VALUE_KEYS.viewContent]: 'Katso sisältö'
  },
  [TRANSLATION_KEYS.searchResults]: {
    [TRANSLATION_VALUE_KEYS.searchResults]: sharedFiFI.searchResults,
    [TRANSLATION_VALUE_KEYS.noSearchResults]: sharedFiFI.noSearchResults
  },
  [TRANSLATION_KEYS.mobileItemsHeader]: {
    [TRANSLATION_VALUE_KEYS.openNewTab]: 'Avaa uudelle välilehdelle',
    [TRANSLATION_VALUE_KEYS.like]: 'Tykkää',
    [TRANSLATION_VALUE_KEYS.download]: 'Lataa'
  },
  [TRANSLATION_KEYS.mobileMenu]: {
    [TRANSLATION_VALUE_KEYS.contents]: 'Sisältö'
  },
  [TRANSLATION_KEYS.customForm]: {
    [TRANSLATION_VALUE_KEYS.name_label]: sharedFiFI.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_label]: sharedFiFI.lastName,
    [TRANSLATION_VALUE_KEYS.email_label]: 'Email',
    [TRANSLATION_VALUE_KEYS.headline_label]: sharedFiFI.headline,
    [TRANSLATION_VALUE_KEYS.company_label]: sharedFiFI.company,
    [TRANSLATION_VALUE_KEYS.phone_label]: sharedFiFI.phone,
    [TRANSLATION_VALUE_KEYS.note_label]: 'Viestis',
    [TRANSLATION_VALUE_KEYS.name_placeholder]: sharedFiFI.firstName,
    [TRANSLATION_VALUE_KEYS.last_name_placeholder]: sharedFiFI.lastName,
    [TRANSLATION_VALUE_KEYS.email_placeholder]: sharedFiFI.yourEmailHere,
    [TRANSLATION_VALUE_KEYS.headline_placeholder]: sharedFiFI.headline,
    [TRANSLATION_VALUE_KEYS.company_placeholder]: sharedFiFI.company,
    [TRANSLATION_VALUE_KEYS.phone_placeholder]: sharedFiFI.phone,
    [TRANSLATION_VALUE_KEYS.note_placeholder]: 'Kirjoita viestisi tähän...'
  },
  [TRANSLATION_KEYS.itemDescription]: {
    [TRANSLATION_VALUE_KEYS.more]: 'Enemmän',
    [TRANSLATION_VALUE_KEYS.less]: 'Vähemmän'
  },
  [TRANSLATION_KEYS.successMessage]: {
    [TRANSLATION_VALUE_KEYS.thankYou]: 'Kiitos'
  },
  [TRANSLATION_KEYS.twoFAPage]: {
    [TRANSLATION_VALUE_KEYS.twoFAPageTitle]: 'Tämä sivusto vaatii, että vahvistat sähköpostiosoitteesi',
    [TRANSLATION_VALUE_KEYS.twoFAPageDescription]: 'Pyydä vahvistuskoodi syöttämällä sähköpostiosoitteesi',
    [TRANSLATION_VALUE_KEYS.enterEmail]: 'Syötä sähköpostiosoitteesi',
    [TRANSLATION_VALUE_KEYS.continueToPage]: 'Jatka sivulle',
    [TRANSLATION_VALUE_KEYS.sendCode]: 'Lähetä uusi koodi',
    [TRANSLATION_VALUE_KEYS.codeSent]: 'Uusi koodi lähetetty',
    [TRANSLATION_VALUE_KEYS.codeSentConfirm]: 'Vahvistuskoodi on lähetetty sinulle. Tarkista sähköpostistasi viesti lähettäjältä',
    [TRANSLATION_VALUE_KEYS.codeSentConfirmNote]: 'Viesti voi olla mainos-, päivitys- tai roskapostikansiossasi.',
    [TRANSLATION_VALUE_KEYS.emailNotAuthorized]: 'Tällä sähköpostiosoitteella ei ole oikeutta näyttää tätä sivua.',
    [TRANSLATION_VALUE_KEYS.accessPageError]: 'Sivun näyttämisessä on ongelmia',
    [TRANSLATION_VALUE_KEYS.checkAccessNote]: 'Ota yhteys yrityksesi edustajaan, jotta saat käyttöoikeuden',
    [TRANSLATION_VALUE_KEYS.enterConfirmationCode]: 'Syötä vahvistuskoodisi',
    [TRANSLATION_VALUE_KEYS.typeCode]: 'Kirjoita koodi tähän',
    [TRANSLATION_VALUE_KEYS.incorrectCode]: 'Väärä koodi',
    [TRANSLATION_VALUE_KEYS.errorOccurred]: 'Tapahtui virhe',
    [TRANSLATION_VALUE_KEYS.emailField]: sharedFiFI.emailField,
    [TRANSLATION_VALUE_KEYS.requiredSingleField]: sharedFiFI.requiredSingleField,
    [TRANSLATION_VALUE_KEYS.email]: sharedFiFI.email,
    [TRANSLATION_VALUE_KEYS.code]: sharedFiFI.code
  },
  [TRANSLATION_KEYS.passwordPage]: {
    [TRANSLATION_VALUE_KEYS.passwordPageTitle]: 'Sivu on salasanasuojattu',
    [TRANSLATION_VALUE_KEYS.passwordPageDescription]: 'Kirjoita salasana alle',
    [TRANSLATION_VALUE_KEYS.enterPasswordPlaceholder]: 'Kirjoita salasana tähän',
    [TRANSLATION_VALUE_KEYS.verifyPassword]: 'Vahvista salasana',
    [TRANSLATION_VALUE_KEYS.wrongPassword]: 'Virheellinen salasana'
  }
}

export default fiFI
