import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as UrlHelper  from 'shared/helpers/UrlHelper'
import { submitLinkClick } from 'LiveBoard/api/cta'
import { LINK_TYPES } from 'LiveBoard/components/shared/CallToAction/constants'
import { TOKEN_PARAM, QUERY_PARAM } from 'LiveBoard/config/constants'
import omit from 'lodash/omit'
import get from 'lodash/get'

import './style.css.scss'

const defaultState = { outboundUrl: null }
const defaultTargetType = '_blank'

export default function withOutboundLink(WrappedComponent) {
  class OutboundLink extends React.Component {
    constructor(props) {
      super(props)
      this.state = defaultState
    }

    handleOutboundLink = (originalUrl, targetType = defaultTargetType, linkClickData = false) => {
      const outboundUrl = this.getOutboundUrl(originalUrl)

      linkClickData && submitLinkClick(
        this.props.boardId,
        {
          url: originalUrl,
          target_type: targetType == defaultTargetType ? LINK_TYPES.newTab : LINK_TYPES.inline,
          area: linkClickData.area
        }
      )

      window.open(outboundUrl, targetType)
    }

    getOutboundUrl = originalUrl => this.props.allowAppendParams ? this.getAppendedUrl(originalUrl) : originalUrl

    getAppendedUrl = (originalUrl) => {
      const queryString = UrlHelper.removeQueryStringParam(
        UrlHelper.getCurrentQueryString(),
        QUERY_PARAM
      )
      const queryObject = UrlHelper.queryToParamsObject(queryString)
      const queryParams = omit(queryObject, TOKEN_PARAM)
      return UrlHelper.appendQueryParamsToUrl(originalUrl, queryParams)
    }

    setOutboundUrlAttribute = url => {
      this.setState({
        outboundUrl: this.getOutboundUrl(url)
      })
    }

    render() {
      const wrappedComponent = () => {
        return <WrappedComponent
          {...this.props.childProps}
          onOpenOutboundLink={this.handleOutboundLink}
          setOutboundUrlAttribute={this.setOutboundUrlAttribute} />
      }

      return (
      this.state.outboundUrl ? (
          <div
            className="outbound-url"
            data-outbound-url={this.state.outboundUrl} >
            { wrappedComponent() }
          </div>
        ) : (
          wrappedComponent()
        )
      )
    }
  }

  withOutboundLink.propTypes = {
    onOpenOutboundLink: PropTypes.func,
    setOutboundUrlAttribute: PropTypes.func,
    allowAppendParams: PropTypes.bool,
    boardId: PropTypes.number,
    childProps: PropTypes.object
  }

  function mapStateToProps({ board }, props) {
    return {
      childProps: props,
      allowAppendParams: get(board, 'integrations.allow_append_params'),
      boardId: get(board, 'id')
    }
  }

  return connect(mapStateToProps, null)(OutboundLink)
}