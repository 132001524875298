var stateGUID = {};

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

function generateRandomGuid() {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

function Guid(key) {
  stateGUID[key] = generateRandomGuid();

  return stateGUID[key]
}

module.exports = {
  create: Guid,
  get: function(key){
    return stateGUID[key]
  },
  createRandomGuid: generateRandomGuid,
  keys: {
    'timeSpent': 'time-spent'
  }
}