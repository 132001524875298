import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setShouldFetchNextPage } from 'LiveBoard/modules/itemsView'
import PublicEventsDispatcher from 'common/services/PublicEventsDispatcher'

import Folloze from 'folloze-sdk'
import get from 'lodash/get'

class EmbeddedBoardContainer extends React.Component {
  constructor(props) {
    super(props)
    Folloze.initChild(document.getElementById('app'))
    Folloze.child.on(Folloze.MessageType.ScrolledToBottom, this.handleScrolledToBottom)
    Folloze.child.on(Folloze.MessageType.ReportHeight, this.setDeviceHeight)
    this.follozeEventTypes = PublicEventsDispatcher().EventTypes
  }

  componentDidMount() {
    window.addEventListener("scrollToElement", this.handleScrollToElement)
    window.addEventListener(this.follozeEventTypes.pageView, this.handleUrlChange)

    if(this.props.isItemViewerOpen) {
      this.handleItemViewerOpened()
    }
    else {
      Folloze.child.registerToScroll()
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isItemViewerOpen != this.props.isItemViewerOpen) {
      this.props.isItemViewerOpen ? this.handleItemViewerOpened() : this.handleItemViewerClosed()
    }
    else if(prevProps.ctaOpened != this.props.ctaOpened && !this.props.isItemViewerOpen) {
      //no changes to do in the embedded board if the item viewer is open
      this.props.ctaOpened ? this.handleCtaOpened() : this.handleCtaClosed()
    }
    else if(prevProps.categoryId != this.props.categoryId) {
      //identify that items category has changed and a resize should take place
      this.handleResize()
    }
    else if(prevProps.numItems != this.props.numItems) {
      //identify that number of presented items in items category has changed and a resize should take place
      this.handleResize()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scrollToElement", this.handleScrollToElement)
  }

  handleUrlChange = (e) => {
    const url = new URL(e.detail.location)
    Folloze.child.urlChanged(url.pathname.substring(1)) //remove '/' from begging of path
  }

  handleResize = () => {
    !this.props.isItemViewerOpen && Folloze.child.resize()
  }

  handleItemViewerOpened = () => {
    Folloze.child.itemViewerOpened()
    this.spreadToScreen()
  }

  handleItemViewerClosed = () => {
    Folloze.child.itemViewerClosed()
    this.unspreadFromScreen()
  }

  handleCtaOpened = () => {
    Folloze.child.ctaOpened()
    this.spreadToScreen()
  }

  handleCtaClosed = () => {
    Folloze.child.ctaClosed()
    this.unspreadFromScreen()
  }

  spreadToScreen = () => {
    Folloze.child.setAutoResize(false)
    Folloze.child.unregisterFromScroll()
  }

  unspreadFromScreen = () => {
    Folloze.child.setAutoResize(true)
    this.handleResize()
    Folloze.child.registerToScroll()
  }

  handleScrolledToBottom = () => {
    const { hasMorePages, setShouldFetchNextPage } = this.props
    hasMorePages && setShouldFetchNextPage(true)
  }

  handleScrollToElement = (e) => {
    const {x, y} = e.detail
    Folloze.child.scrolledTo({ x, y })
  }

  setDeviceHeight = (height) => {
    window.__deviceHeight__ = height
    window.dispatchEvent(new CustomEvent("setDeviceHeight"))
  }

  render = () => null
}

function mapStateToProps({itemsView, itemViewer, cta}) {
  const { categoryId, categories, query } = itemsView
  const category = categories[categoryId]

  return {
    categoryId,
    numItems: get(category, "itemIds", []).length,
    hasMorePages: !!category && category.totalPages > category.page,
    ctaOpened: cta.isOpen,
    isItemViewerOpen: itemViewer.isActive
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setShouldFetchNextPage }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EmbeddedBoardContainer)
